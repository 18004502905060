import { SessionContext } from "@Context/Session.context";
import { useUser } from '@Hooks/firebase';
import { useToast } from "@Hooks/toast";
import { Module } from '@Types/Module';
import { firestore } from '@Utils/config/firebase';
import { doc, updateDoc } from "firebase/firestore";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import FreeTextSection from "./TCSResults/FreeTextSection";
import TCSSection from "./TCSResults/TCSSection";


const Section = styled.div`
    background: #fff;
    border-radius: 10px;
    padding: 24px;
    max-width: 751px;
`;


export type TCSResultsProps = {
    modules: Module[];
};

export const TCSResults: React.FC<TCSResultsProps> = (props) => {
    const { session_id } = useParams<{ session_id: string }>();
    const {participant, session} = useContext(SessionContext);

    const {meta} = useUser(true);
    const {success, error} = useToast();

    const [loading, setLoading] = useState<boolean>(false);

    const [plan, setPlan] = useState<string>(participant?.plan ?? "");
    const onSave = async () => {
        if (participant && session) {
            setLoading(true);
            try {
                const path = `/formations/${session.formation_id}/sessions/${session_id}/participants/${participant.participant_id}`;
                console.log(path, participant, meta);
                await updateDoc(doc(firestore.db, path), {
                    ...participant,
                    plan
                })
                success("Modifications enregistrées");
            } catch (e) {
                console.error(e);
                error("Erreur lors de l'enregistrement");
            } finally {
                setLoading(false)
            }
        }
    }

    const modulesWithTCS = useMemo(() => props.modules.filter(m => m.activities.some(a => a.type === "tcs")), [props.modules]);
    const moduleWithFreeText = useMemo(() => props.modules.filter(m => m.activities.some(a => a.type === "freetext")), [props.modules]);

    return (
        <>
            {modulesWithTCS.length > 0 && <TCSSection modules={modulesWithTCS} />}
            {moduleWithFreeText.length > 0 && <FreeTextSection modules={moduleWithFreeText} />}
            <Section className="mt-4">
                <div className="he-header--h2">Votre plan d'amélioration</div>
                <div className="he-paragraph--small gray-600 my-3">
                    Nous vous invitons à rédiger votre plan d'amélioration de vos pratiques. <br />
                    Si vous le souhaitez, vous pouvez utiliser le champ de texte ci-dessous.
                </div>
                <InputTextarea
                    placeholder={"Saisir ici votre plan d'amélioration"}
                    style={{ height: 200 }}
                    value={plan}
                    onChange={(e) => setPlan(e.target.value)}
                />
                <div className="w-full flex justify-content-end mt-3">
                    <Button className="he-button--primary--md" onClick={onSave} loading={loading} disabled={loading}>
                        Enregistrer <i className="pi pi-save ml-2" />
                    </Button>
                </div>
            </Section>
        </>
    );
};
