import React from "react";
import {Header} from "@Pages/Home/layouts/Header";
import {ContentPage} from "@Pages/Home/layouts/ContentPage";
import {NavigationProvider} from "@Context/Navigation.context";
import {SessionProvider} from "@Context/Session.context";
import {SessionTrackerProvider} from "@Context/SessionTracker.context";

export type HomeProps = Record<string, never>
export const Home: React.FC<HomeProps> = () => {

	return (<div className="Home w-full h-full flex flex-column">
		<NavigationProvider>
				<SessionProvider>
					<SessionTrackerProvider>
						<Header />
						<ContentPage />
					</SessionTrackerProvider>
				</SessionProvider>
		</NavigationProvider>
	</div>)
}
