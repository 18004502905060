import { useBreakpoint } from '@Utils/responsive.utils';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type Props = {
    scrollTop?: boolean;
    scrollBottom?: boolean;
    threshold?: number;
}
export const ScrollContainer: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const ref = useRef<HTMLDivElement>(null);
    const helper = useRef<HTMLDivElement>(null);

    const [isBottom, setIsBottom] = useState(false);
    const [isTop, setIsTop] = useState(true);
    const responsive = useBreakpoint();

    useEffect(() => {
        onScroll();
        return () => {}
    },
    [ref.current, helper.current]);

    const onScroll = () => {
        if (ref.current) {
            const { scrollTop, scrollHeight, clientHeight } = ref.current;
            if (scrollHeight - (scrollTop + clientHeight) <= 0) {
                setIsBottom(true);
            } else {
                setIsBottom(false);
            }
            if (scrollTop >= (props.threshold ?? 50)) {
                setIsTop(false);
            } else {
                setIsTop(true);
            }
        }
    };

    const onScrollDown = () => {
        ref.current?.scrollBy({
            top: 350,
            behavior: 'smooth',
        });
    };

    const onScrollTop = () => {
        ref.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="w-full h-full overflow-auto" ref={ref} onScroll={onScroll}>

            <div className="helper" ref={ref} style={{display: "none", width: 1, height: 1}}/>
            {!isBottom && props.scrollBottom && (
                <CustomScrollBottom className="he-paragraph--regular" onClick={onScrollDown}>
                    <i className="pi pi-arrow-down" /> <span> Descendre</span>
                </CustomScrollBottom>
            )}
            {!responsive.isDesktop &&
                    !isTop && props.scrollTop && (
                        <CustomScrollTop className="he-paragraph--regular" onClick={onScrollTop}>
                            <i className="pi pi-arrow-up" />
                        </CustomScrollTop>
                    )
            }
            {props.children}
        </div>
    );
};

const CustomScrollBottom = styled.div`
    position: absolute;
    bottom: 16px;
    cursor: pointer;
    z-index: 10;
    color: white;
    display: inline-flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 68px;
    background: #123b5e;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    left: 50%;
    transform: translateX(-50%);

    i {
        animation: bounce 1s infinite;
    }

    @keyframes bounce {
        0% {
            transform: translateY(-2px);
        }
        50% {
            transform: translateY(2px);
        }
        100% {
            transform: translateY(-2px);
        }
    }
`;

const CustomScrollTop = styled.div`
    position: absolute;
    bottom: 24px;
    right: 24px;
    cursor: pointer;
    z-index: 10;
    color: white;
    display: inline-flex;
    width: 36px;
    padding: 12px 12px;
    gap: 4px;
    border-radius: 68px;
    background: var(--primary-900, #123b5e);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);

    i {
        animation: bounce 1s infinite;
    }

    @keyframes bounce {
        0% {
            transform: translateY(2px);
        }
        50% {
            transform: translateY(-2px);
        }
        100% {
            transform: translateY(2px);
        }
    }
`;
