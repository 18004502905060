import React from "react";
import {VirtualMeeting} from "@Types/Session";
import styled from "styled-components";
import {isLess} from "@Utils/date.utils";
import { VirtualClassCard } from "./components/VirtualClassCard";

const VirtualRoomContainer = styled.div`
  display: grid;
  grid-auto-rows: 145px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 145px;
  grid-gap: 14px;
`;
export type VirtualClassesProps = {
	virtual_classes: VirtualMeeting[];
}
export const VirtualClasses: React.FC<VirtualClassesProps> = (props) => {
    return (
		<VirtualRoomContainer>
			{[...props.virtual_classes].sort((a,b) => {
				if (a.date && b.date) {
					return isLess(a.date, b.date) ? -1 : 1;
				} else if (a.date && !b.date) {
					return -1;
				} else if (b.date && !a.date) {
					return 1;
				} else {
					return 0;
				}
			}).map(v => {
				return <VirtualClassCard virtual_class={v} key={v.virtual_class_id}/>
			})}
		</VirtualRoomContainer>
    )
}
