import React, {useMemo} from "react";
import styled from "styled-components";
import {classNames} from "primereact/utils";
import type {useFormik} from "formik";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";

export type CustomInputProps = {
	field?: string;
	small?: string;
	formik?: ReturnType<typeof useFormik<any>>;
	label?: string;
	icon?: string;
	placeholder?: string;
	iconPos?: "left" | "right";
	type?: string;
	value?: string;
	onChange?: (change: string) => void;
	onPressEnter?: () => void;
	showInlineError?: boolean;
	disabled?: boolean;
	validateOnInput?: boolean;
	error?: string | null;
	className?: string | (boolean | string | undefined)[];
}

const CustomInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;
export const CustomInput: React.FC<CustomInputProps> = (props) => {

	const defaultProps = useMemo<CustomInputProps>(() => ({
		type: "text",
		iconPos: "left",
		className: [],
		showInlineError: true,
		validateOnInput: false,
		error: null,
		...props,
	}), [props]);


    return (
		<CustomInputWrapper className={classNames(...(typeof defaultProps.className === "string" ? [defaultProps.className] : (defaultProps.className ?? [])) )}>
			{defaultProps.label && <label className="he-paragraph--regular gray-500 mb-2" htmlFor={defaultProps.field}>{defaultProps.label}</label>}
			{defaultProps.small && <small className="he-paragraph--small text-red-500 mb-2">{defaultProps.small}</small>}
			{
				defaultProps.type === "text" && (
					defaultProps.icon ? <div
						className={classNames(`p-input-icon-${defaultProps.iconPos} w-full`,  (((defaultProps.formik && defaultProps.field) && defaultProps.formik.errors[defaultProps.field]) || defaultProps.error) && "p-inputtext-invalid")}
					>
						<i className={defaultProps.icon} />
						<InputText
							id={defaultProps.field}
							name={defaultProps.field}
							disabled={defaultProps.disabled}
							onKeyDown={(event) => {
								// detect enter key or numeric keypad enter key
								if (event.key === "Enter" || event.key === "NumpadEnter") {
									defaultProps.onPressEnter?.();
								}
							}}
							placeholder={defaultProps.placeholder}
							value={(defaultProps.formik && defaultProps.field ) ? defaultProps.formik.values[defaultProps.field] : defaultProps.value}
							className="w-full"
							onChange={(event) => (defaultProps.formik && defaultProps.field ) ? defaultProps.formik.setFieldValue(defaultProps.field, event.target.value, defaultProps.validateOnInput) : props.onChange?.(event.target.value)}
						/>
					</div> : <div
						className={classNames("w-full", (((defaultProps.formik && defaultProps.field) && defaultProps.formik.errors[defaultProps.field]) || defaultProps.error) && "p-inputtext-invalid")}
					>
						<InputText
							id={defaultProps.field}
							disabled={defaultProps.disabled}
							name={defaultProps.field}
							onKeyDown={(event) => {
								// detect enter key or numeric keypad enter key
								if (event.key === "Enter" || event.key === "NumpadEnter") {
									defaultProps.onPressEnter?.();
								}
							}}
							placeholder={defaultProps.placeholder}
							value={(defaultProps.formik && defaultProps.field )? defaultProps.formik.values[defaultProps.field] : props.value}
							className="w-full"
							onChange={(event) => (defaultProps.formik && defaultProps.field )? defaultProps.formik.setFieldValue(defaultProps.field, event.target.value, defaultProps.validateOnInput) : props.onChange?.(event.target.value) }
						/>
					</div>
				)
			}
			{
				defaultProps.type === "password" &&
                <div className="w-full">
                    <Password
                        id={defaultProps.field}
                        disabled={defaultProps.disabled}
                        name={defaultProps.field}
                        placeholder={defaultProps.placeholder}
                        value={(defaultProps.formik && defaultProps.field ) ? defaultProps.formik.values[defaultProps.field] : props.value}
                        toggleMask
						onKeyDown={(event) => {
							// detect enter key or numeric keypad enter key
							if (event.key === "Enter" || event.key === "NumpadEnter") {
								defaultProps.onPressEnter?.();
							}
						}}
                        feedback={false}
                        className={classNames("w-full", (((defaultProps.formik && defaultProps.field) && defaultProps.formik.errors[defaultProps.field]) || defaultProps.error) && "p-inputtext-invalid")}
                        onChange={(event) => (defaultProps.formik && defaultProps.field ) ? defaultProps.formik.setFieldValue(defaultProps.field, event.target.value, defaultProps.validateOnInput) : props.onChange?.(event.target.value)}
                    />
                </div>
			}
			{
				defaultProps.showInlineError && ((defaultProps.formik && defaultProps.field && typeof defaultProps.formik.errors[defaultProps.field] === "string") || defaultProps.error) &&
				<p className="he-paragraph--small color-red mt-1">{defaultProps.error || (defaultProps.formik && defaultProps.field && defaultProps.formik.errors[defaultProps.field] as string)}</p>
			}
		</CustomInputWrapper>
    )
}
