import React from "react";
import {Answer} from "@Types/Quizz";
import {RadioButton} from "primereact/radiobutton";
import {AnswerWrapper} from "@Pages/Home/Session/pages/Module/pages/Activity/QuizzActivity/components/AnswerWrapper";
import {AnswerItem} from "@Pages/Home/Session/pages/Module/pages/Activity/QuizzActivity/components/AnswerItem";
import {ImageAnswer} from "@Pages/Home/Session/pages/Module/pages/Activity/QuizzActivity/components/ImageAnswer";
import {useBreakpoint} from "@Utils/responsive.utils";
import { InputText } from 'primereact/inputtext';

export type UCQProps = {
	answers: Answer[];
	is_image?: boolean;
	onSelect?: (selection: number[]) => void;
	selection?: number[];
	onUpdateOther?: (text: string, index: number) => void;
	others: { [key: number | string]: string };
	is_done?: boolean;
}

export const UCQ: React.FC<UCQProps> = (props) => {

	const onSelectItem = (index: number) => {
		if (!props.onSelect || props.is_done) return;

		if (props.selection?.includes(index))
			props.onSelect(props.selection.filter(i => i !== index));
		else
			props.onSelect([index]);
	}

	const responsive = useBreakpoint();

	return (
		<AnswerWrapper responsive={responsive} is_image={props.is_image}>
			{props.answers.map((answer, i) => {
				return <AnswerItem is_correct={props.is_done ? answer.is_response : undefined}
				                   className="flex w-full h-full align-items-center cursor-pointer gap-3" key={i}
				                   onClick={() => !answer.other && onSelectItem(i)}>
					{
						props.is_image ?
							<ImageAnswer
								answer={answer}
								index={i}
								element={<RadioButton className="mr-2" checked={props.selection?.includes(i)}/>}
							/>
							:
							<>
								<RadioButton checked={props.selection?.includes(i)} onChange={() => answer.other && onSelectItem(i)}/>
								<div className='flex flex-column gap-2 w-full'>
									<span className="he-paragraph--regular gray-900">{answer.text_or_image}</span>
									{answer.other && <InputText disabled={props.is_done} value={props.others[i]} onChange={e => props.onUpdateOther?.(e.target.value, i)} placeholder={"Saisir votre réponse ici"} className={"w-full"}/>}
								</div>
							</>
					}

				</AnswerItem>
			})}
		</AnswerWrapper>
	)
}
