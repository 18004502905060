import * as React from 'react';
import {useEffect, useState} from "react";
import {maintenanceRef} from "@Utils/config/firebase";
import {Maintenance as MaintenanceType} from "@Types/maintenance";
import styled from "styled-components";
import packageJson from './../../package.json';

import {Sidebar} from "primereact/sidebar";
import {FunctionItem1} from "@Components/DevPanel/FunctionItem";
import {SelectButton} from "primereact/selectbutton";
import {Button} from "primereact/button";
import {env} from "../env";
import {Tag} from "primereact/tag";

interface Props {
}


export const DevPanel: React.FC<Props> = (props) => {


    const [visible, setVisible] = useState<boolean>(false);
    const [maintenance, setMaintenance] = useState<MaintenanceType.Maintenance | null>(null);

    useEffect(() => {
        const unsubscribeMaintenance = maintenanceRef.onSnapshot(env.maintenanceKey, maintenanceDoc => {
            if (maintenanceDoc.exists()) {
                setMaintenance(maintenanceDoc.data());
            }
        });
        return () => {
            unsubscribeMaintenance();
        };
    }, []);

    const handleChangeMaintenance = (maintenance: MaintenanceType.Maintenance) => {
        maintenanceRef.set(env.maintenanceKey, maintenance).then(() => maintenanceRef.get(env.maintenanceKey).then(maintenance => setMaintenance(maintenance)));
    };

    const maintenanceOption = [
        {label: 'Pas de maintenance', value: 'no'},
        {label: 'Maintenance avec Watermark', value: 'watermark'},
        {label: 'Maintenance accès hors admin bloqué', value: 'block'},
    ];

    return (
        <>
            {env.isDev &&
            <>
                <Sidebar
                    position={"left"}
                    visible={visible}
                    onHide={() => setVisible(false)}
                    style={{width: 400}}
                    className={"component-DevFunctionPanel"}
                >
                    <PanelBody>
                        <FunctionBody>
                            <CategoryLabel>Execute Function</CategoryLabel>
                            <FunctionItem1
                                label={"Create Admin account"}
                            />

                        </FunctionBody>
                        <PanelFooter>
                            {maintenance &&
                            <>
                                <CategoryLabel className={"mb-3"}>Maintenance site</CategoryLabel>
                                <InputField className="field">
                                    <SelectButton
                                        value={maintenance?.active}
                                        options={maintenanceOption}
                                        unselectable={false}
                                        onChange={(e) => handleChangeMaintenance({
                                            ...maintenance,
                                            active: e.value as "no" | "block" | "watermark",
                                        })}
                                    />
                                </InputField>
                            </>
                            }
                        </PanelFooter>
                    </PanelBody>
                </Sidebar>
	            {
		            env.debugInfo && <ButtonHolder>
			            <Button
				            onClick={() => setVisible(true)}
				            icon={"pi pi-server"}
				            title={"Dev functions"}
			            />
		            </ButtonHolder>
	            }
	            {env.debugInfo && <VersionHolder>
                    <div className="flex flex-column">
                        <div>Projet Firebase : {env.firebaseEnv.projectId} {env.isFirebaseProductionProjet ?
                            <Tag severity="danger" value="PROD"></Tag> :
                            <Tag severity="info" value="DEV"></Tag>}
                        </div>
                        <div>RunTime : {env.isDev ? <Tag severity="info" value="Développement"></Tag> :
                            <Tag severity="danger" value="PRODUCTION"></Tag>}</div>
                        <div>Version front : {packageJson.version}</div>
                    </div>
                </VersionHolder>}
            </>}
        </>
    )
}

const PanelFooter = styled.footer`

`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;


const CategoryLabel = styled.p`
  margin: 0;
`;

const FunctionBody = styled.main`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  flex: 1;
`;

const PanelBody = styled.main`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  height: 100%;
`;

const ButtonHolder = styled.div`
  position: fixed;
  bottom: 50px;
  left: 50px;
  z-index: 20;
`;
const VersionHolder = styled.div`
  position: absolute;
  top: 0px;
  pointer-events: none;
  right: 0px;
  z-index: 9999;
`;
