import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useFormik} from "formik";
import {Question as QuestionModel, Satisfaction as SatisfactionModel} from "@Types/Satisfaction";
import {Question} from "./components/Question";
import {Button} from "primereact/button";
import {usePromise} from "@Hooks/promise";
import {useApi} from "@Hooks/api";
import {useParams} from "react-router-dom";
import {Skeleton} from "primereact/skeleton";

const ContentWrapper = styled.div`
  position: relative;
  max-width: 1076px;
  padding: 32px 32px;
  margin: auto;
`

const Section = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 24px;
	
	.p-selectbutton {
		.p-button {
			height: 38px;
			padding: 8px 15px;
			border-radius: 0;
			border-left: 0;
			
			&:first-child {
				border-left: 1px solid #ced4da;
				border-radius: 8px 0 0 8px;
			}
			&:last-child {
				border-radius: 0 8px 8px 0;
			}
		}
	}
`

const formationEvaluationOptions = [
	{ label: "1", value: 1 },
	{ label: "2", value: 2 },
	{ label: "3", value: 3 },
	{ label: "4", value: 4 }
];

const formationEvaluation: QuestionModel<number>[] = [{
	question: "Communication des objectifs et du programme avant la formation",
	default: 4,
	options: formationEvaluationOptions,
	type: "choice"
}, {
	question: "Organisation et déroulement de la formation",
	default: 4,
	options: formationEvaluationOptions,
	type: "choice"
}, {
	question: "Adéquation des moyens matériels mis à disposition",
	default: 4,
	options: formationEvaluationOptions,
	type: "choice"
}, {
	question: "Conformité de la formation dispensée au programme",
	default: 4,
	options: formationEvaluationOptions,
	type: "choice"
}, {
	question: "Clarté du contenu", default: 4, options: formationEvaluationOptions, type: "choice"
}, {
	question: "Qualité des supports pédagogiques", default: 4, options: formationEvaluationOptions, type: "choice"
}, {
	question: "Animation de la formation par le ou les intervenants",
	default: 4,
	options: formationEvaluationOptions,
	type: "choice"
}, {
	question: "Progression de la formation (durée, rythme, alternance théorie/pratique)",
	default: 4,
	options: formationEvaluationOptions,
	type: "choice"
},];

const formationEvaluationFree: QuestionModel<string> = {
	question: "Vos commentaires",
	type: "free"
};

const formatEvaluation: QuestionModel<string>[] = [{
	question: "Le format e-learning est-il idéal pour vous ?",
	options: [{label: "Oui", value: "yes"}, {label: "Non", value: "no"}],
	default: "yes",
	type: "choice"
}, {
	question: "Que pensez-vous de la durée de la formation ?",
	options: [{label: "Trop longue", value: "long"}, {label: "Correcte", value: "ok"}, {
		label: "Trop courte", value: "short"
	}],
	default: "ok",
	type: "choice"
}];

const youAndFormationEvaluation: QuestionModel<string>[] = [
    {
        question: 'Participez-vous souvent à des formations DPC ?',
        options: [
            { label: 'Peu', value: 'rarely' },
            { label: 'Parfois', value: 'sometimes' },
            {
                label: 'Souvent',
                value: 'often',
            },
            { label: 'Régulièrement', value: 'regularly' },
        ],
        default: 'regularly',
        type: 'choice',
    },
    {
        question: 'Comment connaissez-vous les formations auxquelles vous participez ?',
        default: 'mail',
        options: [
            { label: 'E-mailing', value: 'mail' },
            {
                label: 'Magazines spécialisés',
                value: 'magazine',
            },
            { label: 'Confrères', value: 'colleague' },
            { label: 'Autre', value: 'other' },
        ],
        mixedValue: 'other',
        type: 'mixed',
    },
    {
        question: 'Quelle thématique souhaiteriez-vous pour une prochaine formation ?',
        type: 'free',
    },
];

const INITIAL: SatisfactionModel =  {
	formation: formationEvaluation.map(q => q.default || 4),
	formationFree: "",
	format: formatEvaluation.map(q => q.default || ""),
	youAndFormation: youAndFormationEvaluation.map(q => ({option: q.default || "", content: ""}))
}

export type SatisfactionProps = Record<string, never>;
export const Satisfaction: React.FC<SatisfactionProps> = () => {

	const api = useApi();
	const params = useParams<{session_id: string}>();

	const [initialSatisfaction, setInitialSatisfaction] = useState<SatisfactionModel>(INITIAL);

	const [getSatisfaction, getLoading] = usePromise(async (session_id: string) => {
		const res = await api.participant_call_getSatisfaction({
			session_id
		});
		if (res.result === "ok") {
			setInitialSatisfaction(res.satisfaction || INITIAL);
		}
	})

	const [updateSatisfaction, updateLoading] = usePromise(async (session_id: string, satisfaction: SatisfactionModel) => {
		if (session_id) {
			await api.participant_call_saveSatisfaction({
				satisfaction,
				session_id
			});
		}
	}, {
		pending: "Sauvegarde en cours ...",
		success: "Evaluation sauvegardée !"
	});

	const formik = useFormik<SatisfactionModel>({
		initialValues: initialSatisfaction,
		enableReinitialize: true,
		onSubmit: satisfaction => {
			console.log(satisfaction);
			if (params.session_id) {
				params.session_id && updateSatisfaction(params.session_id, satisfaction)
			}
		}
	})

	useEffect(() => {
	    if (params.session_id) {
			getSatisfaction(params.session_id)
	    }
		return () => {
			setInitialSatisfaction(INITIAL);
		}
	}, [params.session_id]);


	return (<div className="w-full h-full">
			<ContentWrapper>
				<div className="he-header--h1 gray-800">
					Votre avis nous intéresse !
				</div>
				<div className="he-paragraph--medium mt-2 gray-500">
					Questionnaire d'évaluation de la formation
				</div>
				{
					!getLoading ? <>
                        <Section className="mt-4">
                            <div className="he-header--h3 gray-700">Votre évaluation de la formation</div>
                            <div className="he-paragraph--small gray-400 mt-2 mb-4">
                                Cochez une valeur en fonction de votre appréciation de l’organisation et du contenu de la
                                formation <br/>
                                (1 = insatisfaisant, 2 = peu satisfaisant, 3 = satisfaisant, 4 = très satisfaisant)
                            </div>
                            <div className="mt-3">
								{formik.values.formation.map((answer, i) => {
									return <Question key={i}
									                 question={formationEvaluation[i]}
									                 value={answer}
									                 className="mt-3"
									                 onChange={e => formik.setFieldValue(`formation.${i}`, e, false)}/>
								})}
								<Question question={formationEvaluationFree}
										  mixedValue={formik.values.formationFree}
										  value={""}
										  className="mt-3"
										  placeholder={"Saisissez votre réponse ici"}
										  onMixedChanged={e => formik.setFieldValue(`formationFree`, e, false)}
										  onChange={() => {}}/>

                            </div>
                        </Section>
                        <Section className="mt-4">
                            <div className="he-header--h3 gray-700">Format</div>
                            <div className="mt-3">
								{formik.values.format.map((answer, i) => {
									return <Question key={i}
									                 question={formatEvaluation[i]}
									                 value={answer}
									                 className="mt-3"
									                 onChange={e => formik.setFieldValue(`format.${i}`, e, false)}/>
								})}
                            </div>
                        </Section>
                        <Section className="mt-4">
                            <div className="he-header--h3 gray-700">Vous et les formations</div>
                            <div className="mt-3">
								{formik.values.youAndFormation.map(({option: answer, content: mixedAnswer}, i) => {
									return <Question key={i}
									                 className="mt-3"
									                 question={youAndFormationEvaluation[i]}
									                 value={answer}
									                 mixedValue={mixedAnswer}
									                 placeholder={"Complétez ici votre réponse"}
									                 onMixedChanged={e => formik.setFieldValue(`youAndFormation.${i}`, {option: answer, content: e}, false)}
									                 onChange={e => formik.setFieldValue(`youAndFormation.${i}`, {option: e, content: mixedAnswer}, false)}
									/>
								})}
                            </div>
                        </Section>
					</> : <Skeleton width="1076px" height="900px" className="my-4"/>
				}

				<div className="mt-4 w-full flex justify-content-end">
					<Button
						loading={getLoading || updateLoading}
						disabled={getLoading || updateLoading}
						label="Enregistrer l'évaluation"
						icon="pi pi-save"
						className="he-button--primary--md"
						onClick={formik.submitForm}
					/>
				</div>
			</ContentWrapper>
		</div>)
}
