import React, {useState, useEffect} from "react";
import {Answer} from "@Types/Quizz";
import {getUrlForResource} from "@Utils/resource.utils";
import {Image} from "primereact/image";

export type ImageAnswerProps = {
	answer: Answer;
	index: number;
	element: React.ReactElement;
}
export const ImageAnswer: React.FC<ImageAnswerProps> = props => {

    const [image, setImage] = useState<string>();

    useEffect(() => {
		getUrlForResource(props.answer.text_or_image).then(res => {
			setImage(res?.resource_url);
		})
    }, [props.answer]);

    return (
        <div className="flex flex-column overflow-hidden w-full">
	        {image && <Image src={image} preview  width="auto" height="214" className="flex justify-content-center"/>}
	        <div className="mt-2 he-paragraph--regular">
		        {props.element}
		        {props.index + 1} - Choix de réponse
	        </div>
        </div>
    )
}
