import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useApi} from "@Hooks/api";
import {Signature} from "@Types/Resource";
import {DateTime} from "luxon";

export type SignatureDataProps = Record<string, never>
export const SignatureData: React.FC<SignatureDataProps> = () => {

	const params = useParams<{user_id: string, signature_id: string}>();
	const api = useApi();

	const [signatureData, setSignatureData] = useState<Signature>();


    useEffect(() => {
		if (params.signature_id && params.user_id) {
			api.pdf_call_getSignatureData({
				signature_id: params.signature_id,
				user_id: params.user_id
			}).then(data => {
				if (data.result === "ok") {
					setSignatureData(data.signature);
				}
			})
		}
    }, [params.user_id, params.signature_id]);

    return (
        <div className="SignatureData">
	        {signatureData && <pre><code>
		        <p>ID : {signatureData.signature_id}</p>
                <p>EMPREINTE : {signatureData.hash}</p>
		        <p>USER: {signatureData.user_id}</p>
		        <p>META: {JSON.stringify(signatureData.meta)}</p>
		        <p>DATE : {DateTime.fromISO(signatureData.created_at).toFormat("FFFF")}</p>
	        </code></pre>}
        </div>
    )
}
