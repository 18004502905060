import {createRoot} from 'react-dom/client';
import 'normalize.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import './utils/theme/theme.scss';
import "primereact/resources/primereact.min.css"; //core css
import 'react-toastify/dist/ReactToastify.css';
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import {App} from "./App";
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {enableMapSet} from "immer";
import {Error} from "./Error"
import {FirebaseProvider} from "@Context/FirebaseContext";
import {DevPanel} from "@Components/DevPanel";
import Tracker from '@openreplay/tracker';
import {env} from "./env";
import trackerAssist from '@openreplay/tracker-assist';
import { PrimeReactProvider } from 'primereact/api';

enableMapSet();

const root = createRoot(document.getElementById('root')!);

export const tracker = new Tracker({
    projectKey: "w9CFRhsXk86NGYFGqSPx",
    obscureTextNumbers: false,
    obscureInputEmails: false,
    obscureTextEmails: false,
    obscureInputNumbers: false,
    obscureInputDates: false,
});

if (!env.isDev) {
    tracker.start();
    tracker.use(trackerAssist({
        controlConfirm: {
            text: "Un membre du service client Health Events souhaite accéder à votre session de formation, acceptez-vous ?",
            style: {
            },
            confirmBtn: {
                innerHTML: "Accepter"
            },
            declineBtn: {
                innerHTML: "Refuser"
            }
        },

    }));
}

root.render(
    <Error>
        <PrimeReactProvider>
            <FirebaseProvider tracker={tracker}>
                <DevPanel/>
                <BrowserRouter>
                    <div className="he-bg--primary w-full h-full flex justify-content-center">
                        <ToastContainer />
                        <App />
                    </div>
                </BrowserRouter>
            </FirebaseProvider>
        </PrimeReactProvider>
    </Error>

)
