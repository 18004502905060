import { SessionContext } from '@Context/Session.context';
import { useUser } from '@Hooks/firebase';
import { Activity, ActivityHistory, TCSActivity } from '@Types/Activity';
import { Module } from '@Types/Module';
import { Theme } from '@Types/Theme';
import { getTextColor } from '@Utils/color.utils';
import _, { sortBy } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TcsScale } from '../../components/TcsScale';
import { TCSScoreLine } from './TCSSection/TCSScoreLine';
import { ResultChart } from './components/ResultChart';
import { TextWithLink } from '@Components/TextWithLink';

const colors = ['#EA2828', '#EAA828', '#dedc15', '#A3CC2E', '#2ECC71'];
const DisplayScore = styled.span`
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
`;

const Percent = styled.span`
    font-weight: 700;
    font-size: 10px;
    line-height: 130%;
    color: #667085;
`;

type Props = {
    modules: Module[];
};

export default function TCSSection(props: Props) {
    const { progress } = useUser(true);
    const { session_id } = useParams<{ session_id: string }>();
    const { sessionThemes, session } = useContext(SessionContext);

    const calculateHypothesisScore = (choice: number, answers: number[]) => {
        const max = Math.max(...answers);
        return answers[choice] / max;
    };

    const calculateTCSScore = useCallback((activity: TCSActivity, history?: ActivityHistory) => {
        if (!history?.answers) return 0;
        return (
            (Object.entries(history.answers)
                .map(([hi, ai]) => {
                    return calculateHypothesisScore(ai, activity.hypothesis[+hi].answers);
                })
                .reduce((a, c) => a + c, 0) *
                100) /
            activity.hypothesis.length
        );
    }, []);

    const totalHypothesis = useMemo(() => {
        return props.modules
            .map((m) => {
                const activities = m.activities.filter((a) => a.type === 'tcs');
                return activities.map((a) => {
                    return {
                        ...a,
                        module: m,
                    };
                });
            })
            .flat()
            .map((a) => (a as Activity & TCSActivity).hypothesis)
            .flat();
    }, [props.modules]);

    const correctlyAnsweredHypothesis = useMemo(() => {
        if (!session_id) return [];
        const tcsActivities = props.modules
            .map((m) => {
                const activities = m.activities.filter((a) => a.type === 'tcs');
                return activities.map((a) => {
                    return {
                        ...a,
                        module: m,
                    };
                });
            })
            .flat();

        const correctAnswers = [];

        for (const { module, ...activity } of tcsActivities) {
            const foundActivityProgress = progress?.find(
                (p) =>
                    p.session_id === session_id &&
                    p.module_id === module.module_id &&
                    p.activity_id === activity.activity_id
            );

            const tcs = activity as TCSActivity & Activity;
            if (tcs.type === 'tcs' && tcs.activity_id && foundActivityProgress?.activity) {
                const answer = foundActivityProgress.activity;

                const maxAnswerScoreIndexes = tcs.hypothesis.map((h) => {
                    const max = Math.max(...h.answers);
                    return h.answers.map((a, i) => (a === max ? i : -1)).filter((i) => i !== -1);
                });

                let count = 0;
                for (const maxAnswerScoreIndex of maxAnswerScoreIndexes) {
                    if (answer.answers?.[count] && maxAnswerScoreIndex.includes(answer.answers[count])) {
                        correctAnswers.push(answer.answers[count]);
                    }
                    count++;
                }
            }
        }
        return correctAnswers;
    }, [progress, session_id, props.modules]);

    const scores = useMemo(() => {
        if (!session_id) return [];
        const tcsActivities = props.modules
            .map((m) => {
                const activities = m.activities.filter((a) => a.type === 'tcs');
                return activities.map((a) => {
                    return {
                        ...a,
                        module: m,
                    };
                });
            })
            .flat();

        const tcsScores = [];

        for (const { module, ...activity } of tcsActivities) {
            const foundActivityProgress = progress?.find(
                (p) =>
                    p.session_id === session_id &&
                    p.module_id === module.module_id &&
                    p.activity_id === activity.activity_id
            );
            if (activity.activity_id && foundActivityProgress?.activity) {
                const answer = foundActivityProgress.activity;
                tcsScores.push([
                    module,
                    activity as Activity & TCSActivity,
                    calculateTCSScore(activity as TCSActivity, answer),
                    answer,
                ] as const);
            }
        }
        return tcsScores;
    }, [session_id, props.modules, progress, calculateTCSScore]);

    const total = useMemo(() => {
        return scores.reduce((acc, [_m, _a, s]) => s + acc, 0) / scores.length;
    }, [scores]);

    const getColor = (score: number) => {
        const index = Math.floor((score / 100) * 5);
        return colors[index >= colors.length ? colors.length - 1 : index];
    };

    const scoreByModule = useMemo(() => {
        const grouped = _.groupBy(scores, (x) => x[0].module_id);
        return Object.entries(grouped);
    }, [scores]);

    const totalStr = useMemo(() => new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(total), [total]);

    const scoresByTheme = useMemo<Record<string, number>>(
        () =>
            Object.fromEntries(
                sessionThemes.map((st) => [
                    st.theme_id,
                    st.activity_ids
                        .map((activity_id) => scores.find(([m, a, _s]) => a.activity_id === activity_id))
                        .filter((s) => s !== undefined)
                        .reduce((a, c) => {
                            console.log(a, c[2]);
                            return a + c[2];
                        }, 0) / st.activity_ids.length,
                ])
            ),
        [scores, sessionThemes]
    );

    const getModuleThemeDetail = useCallback(() => {
        return sortBy(
            Object.entries(scoresByTheme)
                .map(([theme_id, score]) => {
                    const theme = sessionThemes.find((t) => t.theme_id === theme_id);
                    if (!theme) return null;
                    return [theme, score];
                })
                .filter(Boolean) as [Theme, number][],
            ([theme]) => theme.label
        ).map(([theme, score]) => {
            const comments = theme.scale.toSorted((a, b) => a.threshold - b.threshold);
            const comment = comments.find((c) => score <= c.threshold);

            return (
                <div key={theme.theme_id} className="flex gap-4 align-items-center">
                    <div className="he-paragraph--regular" style={{ minWidth: 120, maxWidth: 120 }}>
                        <ThemeTag background={theme.color}>{theme.label}</ThemeTag>
                    </div>
                    <TextWithLink content={comment?.text ?? 'Aucun commentaire trouvé'} />
                </div>
            );
        });
    }, [scoresByTheme, sessionThemes]);

    return (
        <Section className="mb-4">
            <div className="he-header--h2 mb-4">Vos scores TCS</div>
            {scoreByModule.map(([module_id, scores]) => {
                return (
                    scores.length > 0 && (
                        <div key={module_id} className="mb-3">
                            <div className="he-paragraph--medium gray-800 mb-3">{scores[0][0].title}</div>
                            {scores.map(
                                ([m, a, s, answer]) =>
                                    session_id && (
                                        <TCSScoreLine
                                            module={m}
                                            activity={a}
                                            score={s}
                                            session_id={session_id}
                                            key={m.module_id + '_' + a.activity_id}
                                            answer={answer}
                                            theme={sessionThemes.find((t) =>
                                                a.activity_id ? t.activity_ids.includes(a.activity_id) : false
                                            )}
                                        />
                                    )
                            )}
                        </div>
                    )
                );
            })}
            {scores.length > 0 ? (
                <div className="flex flex-column align-items-end mt-4">
                    <div className="flex flex-column align-items-center mb-3">
                        <div className="w-full flex mb-2">
                            <div className="he-paragraph--regular gray-600">
                                {total > 50
                                    ? `Félicitations ! Vous avez obtenu un score global de`
                                    : `Vous avez obtenu un score global de`}{' '}
                                <span className="he-paragraph--regular--bold">{totalStr}%</span>
                                <br />
                                <br />
                                Vous trouverez le détail du calcul de ce score en cliquant à gauche de chaque TCS
                                ci-dessus. <br />
                                Sur les <span>{totalHypothesis.length > 0}</span> hypothèses auxquelles vous avez
                                répondu,{' '}
                                {correctlyAnsweredHypothesis.length ? correctlyAnsweredHypothesis.length : 'aucune'} de
                                vos réponses sont identiques aux réponses de la majorité du panel d'experts.
                            </div>
                        </div>
                        <div>
                            <DisplayScore style={{ color: getColor(total) }} className="roboto">
                                {totalStr} points
                            </DisplayScore>{' '}
                            <Percent className="roboto">/100</Percent>
                        </div>
                        <div className="w-full">
                            <TcsScale score={total} colors={colors} />
                        </div>
                        {session?.formation.type !== 'Evaluation des pratiques professionnelles (EPP)' && (
                            <div className="mt-4">
                                <div className="he-header--h3 gray-600 mb-4 text-center">
                                    Répartition par thématique
                                </div>
                                <ResultChart themes={sessionThemes} results={[scoresByTheme]} module={scores[0][0]} />
                                <div className="flex flex-column gap-4">{getModuleThemeDetail()}</div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="he-paragraph--regular gray-400">Aucun résultat de TCS trouvé</div>
            )}
        </Section>
    );
}

const Section = styled.div`
    background: #fff;
    border-radius: 10px;
    padding: 24px;
    max-width: 751px;
`;

const ThemeTag = styled.div<{
    background?: string;
    color?: string;
}>`
    text-align: center;
    padding: 6px 8px;
    border-radius: 6px;
    background: ${(props) => props.background || '#c8c8c8'};
    color: ${(props) => props.color || getTextColor(props.background || '#c8c8c8')};
    font-size: 14px;
    line-height: 18px;
    font-family: 'roboto';
`;
