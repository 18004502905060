import {CustomDialog} from '@Components/CustomDialog';
import {Line} from '@Components/Line';
import {FirebaseContext} from '@Context/FirebaseContext';
import {ModuleContext} from '@Context/Module.context';
import {SessionContext} from '@Context/Session.context';
import {SessionTrackerContext} from '@Context/SessionTracker.context';
import {useApi} from '@Hooks/api';
import {useBread} from '@Hooks/breadcrumbs';
import {useUser} from '@Hooks/firebase';
import {usePromise} from '@Hooks/promise';
import {SignModule} from '@Pages/Home/components/SignModule';
import {ModuleTopBar} from '@Pages/Home/Session/pages/Module/components/ModuleTopBar';
import {ResponsiveModuleSummary} from '@Pages/Home/Session/pages/Module/pages/components/ResponsiveModuleSummary';
import {SessionEndDialog} from '@Pages/Home/Session/pages/Module/pages/components/SessionEndDialog';
import {UnitEndDialog} from '@Pages/Home/Session/pages/Module/pages/components/UnitEndDialog';
import {Activity, FreeTextActivity} from '@Types/Activity';
import {SessionExtended} from '@Types/Session';
import {DurationUnit} from '@Types/Time';
import {Unit} from '@Types/Unit';
import {UserProgressItem} from '@Types/User';
import {iconForActivity} from '@Utils/activity.utils';
import {downloadUint8} from '@Utils/download.utils';
import {getBlobForResource} from '@Utils/resource.utils';
import {ResponsiveProps, useBreakpoint} from '@Utils/responsive.utils';
import _ from 'lodash';
import {Duration} from 'luxon';
import {Button} from 'primereact/button';
import {classNames} from 'primereact/utils';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Module} from '@Types/Module';
import {ModuleCheckDialog} from '@Pages/Home/Session/pages/Module/pages/components/ModuleCheckDialog';
import {canByPass} from "@Utils/participant.utils";
import {sanitizeString} from "@Utils/string.utils";

const ActivityPageLayout = styled.div<ResponsiveProps>`
    display: grid;
    ${(props) =>
            props.responsive.isDesktop
                    ? `
  grid-template-columns: 340px minmax(0, 1fr);
  grid-template-rows: min-content min-content minmax(0, 1fr);
  grid-template-areas:
    "title title"
    "actions actions"
    "summary activity";
`
                    : `
  grid-template-columns: minmax(0,1fr);
  grid-template-rows: min-content min-content min-content;
  grid-template-areas:
    "title"
    "summary"
    "activity"
    "actions"
`}
`;

const SideSummary = styled.aside<ResponsiveProps>`
    width: ${(props) => (props.responsive.isDesktop ? '340px' : '100%')};
    padding: 28px 32px;
    grid-area: summary;
    ${(props) => (props.responsive.isDesktop ? 'border-right: 1px solid #eaecf0;' : 'border-top: 1px solid #eaecf0;')}
`;

const MainWrapper = styled.div`
    grid-area: activity;
    height: 100%;
    overflow: auto;
    background: white;
`;

const MainContent = styled.div<ResponsiveProps>`
    padding: 32px;
    max-width: 940px;
    ${(props) => (props.responsive.isDesktop ? '' : 'width: 100%;')}
`;

export type ActivityLayoutProps = {
    children: (
        activity: Activity,
        index: number,
        progress: UserProgressItem[],
        next: {
            onNext: (cb: () => Promise<void>) => void;
            setNextStatus: (status: boolean) => void;
        }
    ) => React.ReactElement;
};
export const ActivityLayout: React.FC<ActivityLayoutProps> = (props) => {
    const { module: currentModule, modules, participant, unit } = useContext(ModuleContext);

    const {
        session,
        refresh,
        refreshModules: refreshModule,
        loadingModules: loadingModule,
    } = useContext(SessionContext);

    const { moduleTime, modulesProgress, loading: loadingTime, saveTime } = useContext(SessionTrackerContext);

    const responsive = useBreakpoint();

    const params = useParams<{
        session_id: string;
        module_id: string;
        activity_id: string;
    }>();

    const bread = useBread();
    const navigate = useNavigate();
    const [nextStatus, setNextStatus] = useState<boolean>(false);
    const [onNext, setOnNext] = useState<() => Promise<void>>();
    const [congratulationVisible, setCongratulationVisible] = useState<boolean>(false);
    const [unitEndVisible, setUnitEndVisible] = useState<boolean>(false);
    const [signModuleVisible, setSignModuleVisible] = useState<boolean>(false);
    const [remainingTimeMessage, setRemainingTimeMessage] = useState<boolean>(false);
    const [loadingRemainingTime, setLoadingRemainingTime] = useState<boolean>(false);
    const [nextLoading, setNextLoading] = useState<boolean>(false);
    const [nextModule, setNextModule] = useState<Module | null>(null);
    const [loadingReset, setLoadingReset] = useState<boolean>(false);
    const [fullscreen, setFullscreen] = useState<boolean>(false);
    const contentRef = React.useRef<HTMLDivElement>(null);

    const { progress: userProgress, meta } = useUser(true);

    useEffect(() => {
        const activityId = params.activity_id;
        if (currentModule && activityId) {
            const activity = currentModule.activities.find((ac) => ac.activity_id === activityId);
            if (activity) {
                bread.appendToTree({
                    label: activity.title,
                    id: activityId,
                    url: `/session/${params.session_id}/module/${params.module_id}/activity/${params.activity_id}`,
                });
            }
        }
    }, []);



    useEffect(() => {
        return document.addEventListener("fullscreenchange", _ => {
            setFullscreen(!!document.fullscreenElement);
        })
    }, [contentRef]);

    const onFullscreen = () => {
        if (contentRef.current) {
            if (!fullscreen) {
                contentRef.current.requestFullscreen({
                    navigationUI: 'hide',
                })
            } else {
                document.exitFullscreen().catch(e => {
                    console.error(e);
                });
            }
        }

    }
    const score = useMemo(() => {
        if (!currentModule?.validating_quizz || !userProgress) return null;

        const isLastActivity = _.last(currentModule.activities)?.activity_id === params.activity_id;

        const hasCompletedAllActivities = currentModule.activities.every(
            (a) =>
                userProgress.find(
                    (p) =>
                        p.activity_id === a.activity_id &&
                        p.session_id === params.session_id &&
                        p.module_id === params.module_id
                )?.activity?.done
        );

        if (!isLastActivity || !hasCompletedAllActivities) return null;

        const totalScore = currentModule.activities.reduce((acc, a: Activity) => {
            if (!('answers' in a)) return acc;
            if (!["ucq", "mcq", "freetext"].includes(a.type)) return acc;

            const response = userProgress.find(
                (ac) =>
                    ac.activity_id === a.activity_id &&
                    ac.session_id === params.session_id &&
                    ac.module_id === params.module_id
            );

            if (["ucq", "mcq"].includes(a.type)) {

                if (!response || !response.activity.answers) return acc;

                if (!Array.isArray(response.activity.answers)) return acc;

                const totalGoodReponse = a.answers.filter((a) => a.is_response).length;

                const score =
                    response.activity.answers.reduce((sc, ans) => sc + (a.answers[ans].is_response ? 1 : 0), 0) /
                    totalGoodReponse;

                return acc + score;
            } else if (a.type === "freetext") {
                const activity = a as Activity & FreeTextActivity;
                if (!response || !response.activity.answer) return acc;

                const totalGoodAnswers = activity.keywords.filter(k => {
                    return sanitizeString(response.activity.answer!.toLowerCase()).includes(sanitizeString(k.toLowerCase()))
                });
                const score = totalGoodAnswers.length / activity.keywords.length;
                return acc + score;
            }

            return acc;
        }, 0);

        const totalQuestions = currentModule.activities.filter(
            (a) => (a.type === 'mcq' || a.type === 'ucq') && 'answers' in a
        ).length;

        return totalScore / totalQuestions;
    }, [currentModule, userProgress, params.activity_id, params.session_id, params.module_id]);
    const indexOfCurrentActivity = useMemo(() => {
        if (currentModule) return currentModule.activities.findIndex((a) => a.activity_id === params.activity_id);
        return -1;
    }, [params.activity_id, currentModule]);

    const fbCtx = useContext(FirebaseContext);
    const remainingTime = useMemo(() => {
        setLoadingRemainingTime(true);
        if (currentModule?.minimal_duration == null && currentModule?.minimal_duration_unit == undefined) {
            fbCtx?.logEvent('noMinimalDuration', {
                currentModule,
                moduleTime,
            });

            setLoadingRemainingTime(false);
            return true;
        }

        const moduleProgress = modulesProgress[currentModule.module_id];

        if (moduleTime == null && moduleProgress === true) {
            fbCtx?.logEvent('noModuleTime,withModuleProgress', {
                currentModule,
                moduleTime,
            });
            setLoadingRemainingTime(false);
            return true;
        }

        if (
            moduleTime == null ||
            currentModule?.minimal_duration == null ||
            currentModule?.minimal_duration_unit == null
        ) {
            fbCtx?.logEvent('noModuleTimeOrDuration', {
                currentModule,
                moduleTime,
            });
            setLoadingRemainingTime(false);
            return false;
        }

        const durationToMillis = (qty: number, unit: DurationUnit) => {
            switch (unit) {
                case 'h':
                    return qty * 60 * 60 * 1000;
                case 's':
                    return qty * 7 * 24 * 60 * 60 * 1000;
                case 'm':
                    return qty * 60 * 1000;
                case 'j':
                    return qty * 24 * 60 * 60 * 1000;
                default:
                    return 0;
            }
        };

        const minimumTimeInSeconds = durationToMillis(
            currentModule.minimal_duration,
            currentModule.minimal_duration_unit
        );

        setLoadingRemainingTime(false);
        return minimumTimeInSeconds - moduleTime;
    }, [moduleTime, currentModule, modulesProgress]);


    const goToNextModule = (session: SessionExtended<false>, nextModule: Module) => {
        navigate(`/session/${params.session_id}/module/${nextModule.module_id}`);
        bread.updateTree((prev) => [
            {
                id: params.session_id,
                url: `/session/${params.session_id}`,
                label: session.formation.title,
            },
            {
                id: nextModule.module_id,
                url: `/session/${params.session_id}/module/${nextModule.module_id}`,
                label: nextModule.title,
            },
        ]);
    }

    const onDefaultNext = useCallback(() => {

        saveTime();
        const defaultBehavior = () => {
            setNextStatus(false);

            const activityId = params.activity_id;

            if (!currentModule || !activityId || !session) return;

            const currentModuleIndex = modules.findIndex((m) => currentModule.module_id === m.module_id);

            if (
                currentModuleIndex !== -1 &&
                currentModuleIndex + 1 <= modules.length - 1 &&
                indexOfCurrentActivity === currentModule?.activities.length - 1
            ) {
                const module = modules[currentModuleIndex + 1];
                if (module) {
                    setNextModule(module);
                }
                return;
            } else if (
                // IF is last module of unit and last activity of module
                currentModuleIndex !== -1 &&
                currentModuleIndex === modules.length - 1 &&
                indexOfCurrentActivity === currentModule?.activities.length - 1
            ) {

                const config = participant?.unitsMeta?.find((u) => u.unit_id === unit?.unit_id);
                const unitConfig = session.unitsConfig.find((u) => u.unit_id === unit?.unit_id);

                // if already signed or signature not required
                if (
                    config?.signature_id ||
                    (unitConfig && !unitConfig.required_signature)
                ) {

                    const unitIndex = session.formation.units.findIndex((u) => u.unit_id === unit?.unit_id);
                    const isLast = session.formation.units.length - 1 === unitIndex;

                    if (isLast && !unitConfig?.required_signature) {
                        setNextLoading(true);
                        api.participant_call_giveAccess({
                            session_id: session.session_id,
                        }).then(() => {
                            setCongratulationVisible(true);
                        });
                        return;
                    }


                    refresh();
                    navigate(`/session/${params.session_id}`);
                    bread.updateTree((prev) => [
                        {
                            id: params.session_id,
                            url: `/session/${params.session_id}`,
                            label: session.formation.title,
                        },
                    ]);
                } else {
                    setUnitEndVisible(true);
                }

                return;
            }

            const index = currentModule.activities.findIndex((ac) => ac.activity_id === activityId);

            if (currentModule.activities.length <= index + 1) return;

            const nextActivity = currentModule.activities[index + 1];

            bread.removeById(activityId);

            navigate(`/session/${params.session_id}/module/${params.module_id}/activity/${nextActivity.activity_id}`);

            bread.appendToTree({
                label: nextActivity.title,
                id: nextActivity.activity_id,
                url: `/session/${params.session_id}/module/${params.module_id}/activity/${nextActivity.activity_id}`,
            });
        };

        if (onNext !== undefined) {
            setNextLoading(true);
            onNext()
                .then(defaultBehavior)
                .finally(() => {
                    setNextLoading(false);
                });
        } else defaultBehavior();
    }, [onNext, params.activity_id, currentModule, indexOfCurrentActivity, remainingTime]);

    const onPrevious = () => {
        const activityId = params.activity_id;
        if (!currentModule || !activityId) return;

        const index = currentModule.activities.findIndex((ac) => ac.activity_id === activityId);

        if (index - 1 < 0) return;

        const nextActivity = currentModule.activities[index - 1];

        bread.removeById(activityId);

        navigate(`/session/${params.session_id}/module/${params.module_id}/activity/${nextActivity.activity_id}`);

        bread.appendToTree({
            label: nextActivity.title,
            id: nextActivity.activity_id,
            url: `/session/${params.session_id}/module/${params.module_id}/activity/${nextActivity.activity_id}`,
        });
    };

    const progress = useMemo(() => {
        if (!currentModule || !params.session_id) return 0;

        const moduleProgress = userProgress?.filter(
            (p) => p.session_id === params.session_id && p.module_id === currentModule.module_id && p.activity.done
        );

        if (!moduleProgress) return 0;

        return (100 * moduleProgress.length) / currentModule.activities.length;
    }, [currentModule, userProgress, params]);

    useEffect(() => {
        refreshModule(session);
    }, [session]);

    const onQuit = () => {
        if (session) {
            bread.updateTree((prev) => [
                {
                    id: params.session_id,
                    url: `/session/${params.session_id}`,
                    label: session.formation.title,
                },
                {
                    id: params.module_id,
                    url: `/session/${params.session_id}/module/${params.module_id}`,
                },
            ]);
            refresh();
            navigate(`/session/${params.session_id}/module/${params.module_id}`);
        }
    };

    const doneActivities = useMemo(() => {
        if (!userProgress || !params.session_id || !params.module_id) return [];
        return userProgress
            .filter((p) => p.session_id === params.session_id && p.module_id === params.module_id && p.activity.done)
            .map((p) => p.activity_id);
    }, [userProgress]);

    const [downloadFile, loading] = usePromise(async () => {
        if (!session) return;
        const [res, blob] = await getBlobForResource(session.formation.educational_support_url);

        if (!res?.resource_url || !blob) return;
        const a = document.createElement('a');

        // download file from url using fetch
        const file = new File([blob], res.resource_name, { type: 'application/data' });
        const url = window.URL.createObjectURL(file);
        a.href = url;
        a.download = res.resource_name;
        a.click();
    });

    const onChangeActivity = (activity: Activity) => {
        const activityId = params.activity_id;

        if (!activityId) return;

        bread.removeById(activityId);

        navigate(`/session/${params.session_id}/module/${params.module_id}/activity/${activity.activity_id}`);

        bread.appendToTree({
            label: activity.title,
            id: activity.activity_id,
            url: `/session/${params.session_id}/module/${params.module_id}/activity/${activity.activity_id}`,
        });
    };

    const api = useApi();

    const [onDownloadDPC, dpcLoading] = usePromise(async () => {
        if (session && !participant?.manual_attestation_lock) {
            const res = await api.session_call_getAttestation({
                queries: [
                    {
                        session_id: session.session_id,
                        user_ids: [meta.user_id],
                    },
                ],
            });

            if (res.result !== 'ok') throw new Error(res.result);

            if (res.mode === 'single') {
                await downloadUint8(
                    res.file,
                    `Attestation suivi formation - ${session.formation.andpc} - Session ${session.session_custom_id} - ${meta.lastname} ${meta.firstname}.pdf`,
                    'application/pdf'
                );
            }
        }
    });

    const onUnitSigned = (unit: Unit, session: SessionExtended<false>) => {
        setSignModuleVisible(false);

        const isLastUnit = _.last(session.formation.units)?.unit_id === unit.unit_id;

        const unitindex = session.formation.units.findIndex((u) => u.unit_id === unit.unit_id);

        if (isLastUnit) {
            setCongratulationVisible(true);
            return;
        }

        const nextUnit = session.formation.units[unitindex + 1];
        const config = session.unitsConfig.find((u) => u.unit_id === nextUnit.unit_id);

        if (!config) return;

        if (config.start_date) {
            bread.updateTree((prev) => [
                {
                    id: params.session_id,
                    url: `/session/${params.session_id}`,
                    label: session.formation.title,
                },
            ]);
            refresh();
            navigate(`/session/${params.session_id}`);
        } else {
            const nextModule = modules.find((m) => m.module_id === nextUnit.modules_ids[0]);
            if (!nextModule) return;
            navigate(`/session/${params.session_id}/module/${nextModule.module_id}`);
            bread.updateTree((prev) => [
                {
                    id: params.session_id,
                    url: `/session/${params.session_id}`,
                    label: session.formation.title,
                },
                {
                    id: nextModule.module_id,
                    url: `/session/${params.session_id}/module/${nextModule.module_id}`,
                    label: nextModule.title,
                },
            ]);
        }
    };

    const onRemainingTime = () => {
        setRemainingTimeMessage(true);
    };

    const hasFailed = useMemo(
        () =>
            score != null &&
            currentModule &&
            currentModule.threshold != null &&
            score * 100 < currentModule.threshold,
        [score, currentModule]
    );
    const [showConfirmDownload, setShowConfirmDownload] = useState<boolean>(false)

    const onRestartModule = async () => {
        setLoadingReset(true)
        if (!currentModule || !session) return;

        const res = await api.session_call_resetModule({
            queries: [
                {
                    session_id: session.session_id,
                    module_id: currentModule.module_id,
                },
            ],
        });

        if (res.result !== 'ok') throw new Error(res.result);

        bread.updateTree((prev) => [
            {
                id: params.session_id,
                url: `/session/${params.session_id}`,
                label: session.formation.title,
            },
            {
                id: params.module_id,
                url: `/session/${params.session_id}/module/${params.module_id}`,
                label: currentModule.title,
            },
            {
                id: params.activity_id,
                url: `/session/${params.session_id}/module/${params.module_id}/activity/${res.lastActivity}`,
                label: currentModule.activities.find((a) => a.activity_id === res.lastActivity)?.title,
            }
        ]);

        navigate("/session/" + params.session_id + "/module/" + params.module_id + "/activity/" + res.lastActivity);
        fbCtx?.refreshProgress();

        setLoadingReset(false);

    }

    const bypass = participant && session && canByPass(session, participant);

    return currentModule && params.activity_id && indexOfCurrentActivity !== -1 ? (
        <ActivityPageLayout responsive={responsive} className="w-full h-full"  ref={contentRef}>
            {currentModule.validating_quizz && hasFailed && (
                <CustomDialog visible onHide={() => {}}>
                    <div className="he-header--h3 gray-900 w-full text-center">ATTENTION</div>
                    <div className="he-paragraph--regular gray-400 mt-3 text-center">
                        Votre score est insuffisant pour valider le QCM.
                    </div>
                    <div className="he-paragraph--regular gray-400 mt-2 text-center">
                        Vous avez obtenu un score de <b>{(score ?? 0) * 100}%</b>, or le seuil de validation est de <b>{currentModule.threshold}%.</b>
                    </div>
                    <div className="flex justify-content-center mt-4">
                        <Button className="he-button--primary--md" onClick={onRestartModule} loading={loadingReset} disabled={loadingReset}>
                            Recommencer le QCM
                        </Button>
                    </div>
                </CustomDialog>
            )}
            {
                nextModule && session && <CustomDialog closable={false} visible onHide={() => {}}>
                    <ModuleCheckDialog
                        module={currentModule}
                        session={session}
                        onChecked={() => goToNextModule(session, nextModule)}
                    />
                </CustomDialog>
            }
            {session && participant && (
                <CustomDialog visible={congratulationVisible} onHide={() => setCongratulationVisible(false)}>
                    <SessionEndDialog session={session} dpcLoading={dpcLoading} onDownloadDPC={onDownloadDPC} participant={participant} />
                </CustomDialog>
            )}
            {session && (
                <CustomDialog visible={unitEndVisible} onHide={() => setUnitEndVisible(false)}>
                    <UnitEndDialog session={session} onSign={() => setSignModuleVisible(true)} />
                </CustomDialog>
            )}
            {typeof remainingTime === 'number' && remainingTime > 0 && (
                <CustomDialog visible={remainingTimeMessage} onHide={() => setRemainingTimeMessage(false)} width={600}>
                    <div className="text-center he-header--h3">
                        <p>
                            Vous ne pouvez pas accéder au module suivant car le temps minimum requis pour le module en
                            cours n'est pas encore atteint.
                        </p>
                        <p className="mt-2">
                            Il sera disponible dans {Duration.fromMillis(remainingTime).toFormat('hh:mm:ss')}
                        </p>
                    </div>
                    <div className="flex justify-content-center mt-3">
                        <Button className="he-button--secondary-nfb--xs" onClick={() => setRemainingTimeMessage(false)}>
                            Fermer
                        </Button>
                    </div>
                </CustomDialog>
            )}
            {session && unit && (
                <CustomDialog
                    visible={signModuleVisible}
                    onHide={() => setSignModuleVisible(false)}
                    width={responsive.isDesktop || responsive.small ? 950 : responsive.width}
                >
                    <SignModule
                        session={session}
                        onCancel={() => setSignModuleVisible(false)}
                        unit={unit}
                        onSigned={() => onUnitSigned(unit, session)}
                    />
                </CustomDialog>
            )}
            {
                showConfirmDownload && <CustomDialog visible onHide={() => setShowConfirmDownload(false)} width={400}>
                    <div className="he-header--h2 mb-3 text-center">Téléchargement du support pédagogique</div>
                    <div className="he-paragraph--regular-16 gray-500 mb-4 text-center">
                        Ce support pédagogique a pour seul objectif de faciliter la compréhension et l’apprentissage des vidéos suivantes,
                        sa lecture ne sera pas prise en compte dans le suivi de cette formation. <br /><br /> Pour suivre la formation, cliquez sur "Formation"
                    </div>
                    <div className="flex flex-column gap-2 w-full">
                        <Button
                            className="he-button--primary--md"
                            onClick={downloadFile}
                            loading={loading}
                            disabled={loading}
                        >
                            <i className="pi pi-download" />
                            Télécharger le support
                        </Button>
                        <Button
                            className="he-button--secondary-variant--md"
                            onClick={() => setShowConfirmDownload(false)}
                        >
                            Formation
                        </Button>
                    </div>

                </CustomDialog>
            }
            <ModuleTopBar
                module={currentModule}
                progress={progress}
                fullscreen={fullscreen}
                onFullscreen={onFullscreen}
                remainingTime={remainingTime}
                style={{ borderBottom: '1px solid #EAECF0', gridArea: 'title' }}
            />
            <div
                className={classNames(
                    responsive.isDesktop ? 'px-6 ' : 'px-3',
                    'flex justify-content-start align-items-center py-2 bg-white'
                )}
                style={{
                    [responsive.isDesktop ? 'borderBottom' : 'borderTop']: '1px solid #EAECF0',
                    gridArea: 'actions',
                }}
            >
                {responsive.isDesktop && (
                    <div className="flex gap-3 mr-auto align-items-center">
                        <div className="he-paragraph--regular gray-500 cursor-pointer">
                            <span className="cursor-pointer" onClick={onQuit}>
                                <i className="pi pi-sign-out mr-2" />
                                Quitter le module
                            </span>
                        </div>
                    </div>
                )}
                <div className={`flex ${!responsive.isDesktop ? 'justify-content-around gap-4 w-full px-6' : 'gap-2'}`}>
                    <Button
                        label="Précédent"
                        disabled={indexOfCurrentActivity <= 0}
                        className="he-button--primary-nf--xs w-6"
                        onClick={onPrevious}
                        icon="pi pi-arrow-circle-left"
                    />

                    {indexOfCurrentActivity >= currentModule.activities.length - 1 && (
                        <Button
                            label={
                                (remainingTime === true || (typeof remainingTime === 'number' && remainingTime <= 0) || bypass)
                                    ? 'Continuer'
                                    : remainingTime === false
                                        ? 'Continuer'
                                        : `Temps restant ${
                                            typeof remainingTime === 'number'
                                                ? Duration.fromMillis(remainingTime).toFormat('hh:mm:ss')
                                                : '-'
                                        }`
                            }
                            disabled={
                                bypass ? false : (
                                    !nextStatus ||
                                    nextLoading ||
                                    loadingTime ||
                                    loadingRemainingTime ||
                                    (currentModule.validating_quizz && hasFailed) ||
                                    (currentModule.minimal_duration != null && remainingTime === false)
                                )
                            }
                            loading={nextLoading || loadingTime || loadingModule || loadingRemainingTime || loading}
                            icon={
                                remainingTime === true || (typeof remainingTime === 'number' && remainingTime <= 0) || bypass
                                    ? 'pi pi-arrow-circle-right'
                                    : remainingTime === false
                                        ? 'pi pi-times'
                                        : 'pi pi-clock'
                            }
                            className="he-button--primary--xs w-6"
                            onClick={
                                remainingTime === true || (typeof remainingTime === 'number' && remainingTime <= 0) || bypass
                                    ? onDefaultNext
                                    : onRemainingTime
                            }
                            iconPos="right"
                        />
                    )}

                    {indexOfCurrentActivity < currentModule.activities.length - 1 && (
                        <Button
                            label="Suivant"
                            loading={nextLoading || loadingModule || loading}
                            icon="pi pi-arrow-circle-right"
                            className="he-button--primary--xs w-6"
                            disabled={!nextStatus || nextLoading}
                            onClick={onDefaultNext}
                            iconPos="right"
                        />
                    )}
                </div>
            </div>
            {responsive.isDesktop ? (
                <SideSummary responsive={responsive} className="h-full bg-white flex flex-column flex-grow-0">
                    <div className="he-header--h2 gray-900 flex-grow-0">Sommaire</div>
                    <div className="flex-grow-1 overflow-auto mb-3">
                        {currentModule.activities.map((activity, i) => {
                            return (
                                activity.activity_id && (
                                    <div
                                        className={classNames(
                                            'ml-3 mt-3 he-paragraph--regular',
                                            doneActivities.includes(activity.activity_id)
                                                ? 'secondary-100 cursor-pointer'
                                                : params.activity_id === activity.activity_id
                                                    ? 'primary-100 cursor-default'
                                                    : 'gray-400 cursor-default'
                                        )}
                                        onClick={
                                            doneActivities.includes(activity.activity_id)
                                                ? () => {
                                                    onChangeActivity(activity);
                                                }
                                                : undefined
                                        }
                                        key={activity.activity_id}
                                    >
                                        <i className={classNames(iconForActivity(activity.type), 'mr-2')} />
                                        {activity.title}
                                    </div>
                                )
                            );
                        })}
                    </div>
                    <div className="flex-grow-0">
                        <Button
                            className="w-full he-button--secondary-nf--xs--rounded"
                            onClick={() => setShowConfirmDownload(true)}
                            loading={loading}
                            disabled={!session?.formation.educational_support_url}
                        >
                            <i className="pi pi-download" />
                            {!session?.formation.educational_support_url ? "Aucun support pédagogique" : "Télécharger le support pédagogique"}
                        </Button>
                        <Line height={1} style={{ background: '#d8ebfb' }} className="my-3" />
                        <div className="w-full flex justify-content-between mt-3">
                            <a
                                className="w-max text-center he-paragraph--small--bold cursor-pointer no-underline"
                                style={{ color: 'rgba(11,85,153,0.58)' }}
                                href={'mailto:contact@healthevents.fr'}
                            >
                                <i className="pi pi-envelope mr-2" />
                                Contacter l'animateur
                            </a>
                            <a
                                className="w-max text-center he-paragraph--small--bold cursor-pointer no-underline"
                                style={{ color: 'rgba(11,85,153,0.58)' }}
                                href={'tel:0176431249'}
                            >
                                <i className="pi pi-phone mr-2" />
                                01 76 43 12 49
                            </a>
                        </div>
                    </div>
                </SideSummary>
            ) : (
                <ResponsiveModuleSummary
                    doneActivities={doneActivities}
                    onChangeActivity={onChangeActivity}
                    loading={loading}
                    downloadFile={downloadFile}
                    onQuit={onQuit}
                />
            )}
            <MainWrapper>
                <MainContent responsive={responsive}>
                    {(loadingReset || !userProgress) ?
                        <i className="pi pi-spin pi-spinner" />
                        : props.children(currentModule.activities[indexOfCurrentActivity], indexOfCurrentActivity, userProgress, {
                            onNext: (cb) => setOnNext(() => cb),
                            setNextStatus: setNextStatus,
                        })}
                </MainContent>
            </MainWrapper>
        </ActivityPageLayout>
    ) : (
        <div className="w-full h-full flex justify-content-center align-items-center">
            <i className="pi pi-spin pi-spinner gray-300" style={{ fontSize: '3em' }} />
        </div>
    );
};
