import { useCallback } from 'react';
import styled from 'styled-components';

type Props = {
    content: string;
};
export const TextWithLink = (props: Props) => {
    const replaceLinks = useCallback((content: string): React.ReactElement => {
        const regex = /(?<url>https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*))/gm;

        const links: string[] = [];

        let m;

        while ((m = regex.exec(content)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            if (m[0])
                links.push(m[0]);
        }


        let newContent = <></>;
        let last = 0;
        for (const link of links) {

            const indexOfLink = content.indexOf(link);
            const beforeLink = content.substring(last, indexOfLink);
            newContent = (
                <>
                    {newContent}
                    {beforeLink}
                    <a href={link} target="_blank" rel="noreferrer">
                        {link}
                    </a>
                </>
            );
            last = indexOfLink + link.length;
        }

        const afterLink = content.substring(last);
        newContent = (
            <>
                {newContent}
                {afterLink}
            </>
        );

        return newContent;
    }, []);

    return <Wrapper>{replaceLinks(props.content)}</Wrapper>;
};

const Wrapper = styled.div`
    line-height: 20px;
    font-size: 14px;
    font-family: 'roboto';
    font-weight: 400;
    white-space: pre-line;
`;
