import React from "react";
import {useFormik} from "formik";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {CustomInput} from "@Components/CustomInput";
import {loginSchema} from "@Schemas/auth.schema";
import {auth} from "@Utils/config/firebase";
import { useToast } from "@Hooks/toast";

import logo from "@Assets/images/logo-blue.svg";
import {useBreakpoint} from "@Utils/responsive.utils";
import {classNames} from "primereact/utils";

interface InitialValues {
	email: string;
	password: string;
}

const initialValues: InitialValues = {
	email: "",
	password: "",
};

export type LoginFormProps = Record<string, never>
export const LoginForm: React.FC<LoginFormProps> = () => {

	const navigate = useNavigate();
	const toast = useToast();
	const {phone, isTablet} = useBreakpoint();
	const handleSubmit = async (values: InitialValues) => {
		toast.promise(auth.signInWithEmailAndPassword(values.email, values.password), {
			pending: "Connexion en cours...",
			success: "Connexion réussie !"
		}).catch(err => {
			switch (err.code) {
				case "auth/user-not-found":
				case "auth/wrong-password":
				case "auth/invalid-email":
					toast.error("Adresse e-mail ou mot de passe incorrect");
					break;

				case "auth/too-many-requests":
					toast.error("Vous avez effectué trop de tentatives, merci de réessayer plus tard.");
					break;

				default:
					toast.error(`Une erreur est survenue [${err.code}]`);
			}
		});
	}

	const formik = useFormik<InitialValues>({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: handleSubmit
	});

	const onForgotten = () => navigate('/forgotten-password')


	return (
		<div className={classNames(`Login flex h-full w-full flex-column align-items-center overflow-auto p-2`, (phone || isTablet) ? "justify-content-between bg-white" : "justify-content-center")}>
			<div className="my-6">
				<img src={logo} height={phone ? 35 : 50}/>
			</div>
			<LoginFormWrapper className="flex flex-column justify-content-center px-4 bg-white">
				<div className="he-header--h1 text-center mb-6 primary-900">
					Connexion
				</div>
				<CustomInput
					formik={formik}
					field="email"
					label="Adresse e-mail"
					placeholder="Adresse e-mail"
					icon="pi pi-at"
					iconPos="right"
					className="mb-3"
				/>
				<CustomInput
					formik={formik}
					field="password"
					label="Mot de passe"
					placeholder="Mot de passe"
					type="password"
					className="mb-3"
					onPressEnter={formik.submitForm}
				/>
				<div className="mb-5 text-right">
					<span className="he-paragraph--regular--underlined primary-100 cursor-pointer" onClick={onForgotten}>Mot de passe oublié ?</span>
				</div>
				<Button className="he-button--primary--lg" onClick={() => {
					console.error(formik.errors);
					formik.submitForm()
				}}>
					<div className="flex align-items-center">
						<i className="pi pi-sign-in mr-2"/> <div> Connexion </div>
					</div>
				</Button>
			</LoginFormWrapper>
			<div className="my-2 p-3 bg-yellow-10" style={{width: "100%", maxWidth: 450}}>
				<div className="he-paragraph--regular-16 gray-500 flex flex-column gap-2 align-items-center">
					<span className="yellow-100 uppercase he-paragraph--regular-16--bold">Besoin d’aide ?</span>
					<span className="yellow-100">Nous sommes disponibles <span className={"font-bold"}>7J/7</span> de <span className={"font-bold"}>9h à 21h</span> au&nbsp;</span>
					<a href="tel:0176431249" className="yellow-100 no-underline" style={{fontSize: "16px", fontWeight: "bold"}}>
						<i className={"pi pi-phone mr-2"} style={{fontSize: 14}}/>01 76 43 12 49
					</a>
				</div>
			</div>
		</div>
	)
}


const LoginFormWrapper = styled.div`
  max-width: 450px;
  width: 100%;
  height: auto;
  padding: 28px;
`;
