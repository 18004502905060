import {OverlayPanel} from "primereact/overlaypanel";
import {forwardRef} from "react";
import style from "./CustomDropdown.module.scss";
import {classNames} from "primereact/utils";
interface CustomDropdownProps {
	items: {
		icon: string;
		label: string;
		action: () => void;
	}[];
}
export const CustomDropdown = forwardRef<OverlayPanel, CustomDropdownProps>((props: CustomDropdownProps, ref) => {

	return <OverlayPanel ref={ref} dismissable className={style.customDropdown}>
		<div className="w-full h-full flex flex-column justify-content-between align-items-start">
			{
				props.items.map(i => (
					<div className="he-paragraph--medium text-white cursor-pointer w-full p-2" onClick={i.action} key={i.label}>
						<i className={classNames(i.icon, 'mr-2')}/> {i.label}
					</div>
				))
			}
		</div>
	</OverlayPanel>
})
