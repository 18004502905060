import React from "react";

export type LineProps = {
	className?: string;
	height: number;
	style?: React.CSSProperties;
}
export const Line: React.FC<LineProps> = props => {

	return (
		<div style={{
			display: "block",
			width: "100%",
			height: props.height,
			...(props.style ? props.style : {})
		}} className={props.className}>

		</div>
	)
}
