import React, {useMemo} from "react";
import "./CustomTabView.scss";
import {classNames} from "primereact/utils";
import {Badge} from "primereact/badge";

export type CustomTabViewProps = {
	className?: string;
	selected: number;
	onChange: (selected: number) => void;
	items: {
		label: string;
		badge?: number;
	}[];
}
export const CustomTabView: React.FC<CustomTabViewProps> = (props) => {

	const render = useMemo(() => {
		return props.items.map((item, i) => {
			return <div
				className={classNames(
					"CustomTabView-item he-paragraph--regular cursor-pointer",
					props.selected === i && "CustomTabView-item-selected"
				)}
				onClick={() => props.onChange(i)}
				key={i}>
				{item.label}
				{item.badge && <Badge value={item.badge} className="ml-2" />}
			</div>
		})
	}, [props.selected, props.items])

	return (
		<div className={classNames("CustomTabView w-full flex", props.className)}>
			{render}
		</div>
	)
}
