import { BreadItemModel } from "@Components/CustomBreadCrumb";
import { ReadOnlyEditor } from "@Components/ReadOnlyEditor";
import { ModuleContext } from "@Context/Module.context";
import { SessionContext } from "@Context/Session.context";
import { useBread } from "@Hooks/breadcrumbs";
import { useUser } from "@Hooks/firebase";
import { ModuleSkeleton } from "@Pages/Home/Session/pages/Module/components/ModuleSkeleton";
import { ModuleTopBar } from "@Pages/Home/Session/pages/Module/components/ModuleTopBar";
import { iconForActivity } from "@Utils/activity.utils";
import { isSessionFinished } from "@Utils/progress.utils";
import { getUrlForResource } from "@Utils/resource.utils";
import { ResponsiveProps, useBreakpoint } from "@Utils/responsive.utils";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";


const ContentWrapper = styled.div<ResponsiveProps>`
  position: relative;
  max-width: 1076px;
  padding: ${props => (props.responsive.isDesktop || props.responsive.isTablet) ? "0 32px" : "0 16px"};
`

const InnerWrapper = styled.div`
  max-width: 710px;
`

const CoverImageWrapper = styled.div<ResponsiveProps>`
  height: ${props => (props.responsive.isDesktop || props.responsive.isTablet) ? "200px" : "150px"};
  overflow: hidden;
`;

const SummaryCard = styled.div`
  border-left: 4px solid #3C90E3;
  border-radius: 0px 10px 10px 0px;
`;


export type ModuleProps = Record<string, never>
export const Module: React.FC<ModuleProps> = () => {

	const params = useParams<{
		session_id: string;
		module_id: string;
	}>();

	const navigate = useNavigate();
	const bread = useBread();

	const {module, modules, participant} = useContext(ModuleContext);
	const {session, refreshModules: loadModule, loadingModules: loading} = useContext(SessionContext);
	const {progress: userProgress} = useUser(true);
	const [image, setImage] = useState<string>();


	useEffect(() => {
		if (session?.formation?.cover_image_big_url || module?.cover_image_url || session?.formation?.cover_image_url ) {
			getUrlForResource(session?.formation?.cover_image_big_url || session?.formation?.cover_image_url || module?.cover_image_url).then(res => {
				setImage(res?.resource_url);
			})
		}
	}, [module?.cover_image_url, session?.formation?.cover_image_big_url, session?.formation?.cover_image_url]);


	// variables

	const finishedPreviousModules = useMemo<boolean>(() => {

		const moduleIndex = modules.findIndex(m => m.module_id === params.module_id);

		if (moduleIndex === 0) return true;

		if (!params.session_id || !userProgress || !module) return false;

		const sessionProgress = userProgress.filter(p => p.session_id === params.session_id);

		if (!session) return false;

		return modules.filter((m, i) => i < moduleIndex).every(m => {
			const activitiesDone = sessionProgress.filter(sp => sp.activity.done && sp.module_id === m.module_id);
			return activitiesDone.length === m.activities.length
		});

	}, [userProgress, params, module, modules]);

	const progress = useMemo(() => {
		if (!module || !params.session_id) return 0;

		const moduleProgress = userProgress?.filter(p => p.session_id === params.session_id && p.module_id === module.module_id && p.activity.done);

		if (!moduleProgress) return 0;

		return 100 * moduleProgress.length / module.activities.length;

	}, [module, userProgress, params])

	// Lifecycle
	useEffect(() => {
		loadModule(session).then(res => {
			if (!res) return;
			bread.updateTree((prev: BreadItemModel[]) => {

				const module = res.find(m => m.module_id === params.module_id);

				if (!module) return prev;

				if (prev[prev.length - 1].id === params.module_id) return prev;

				return [
					...prev,
					{
						url: `/session/${params.session_id}/module/${params.module_id}`,
						label: module.title,
						id: params.module_id
					}
				];
			})
		});
	}, []);


	// functions
	function onBack() {
		navigate(`/session/${params.session_id}`);
	}

	function onStart() {
		if (module && module.activities.length > 0)
			navigate(`/session/${params.session_id}/module/${params.module_id}/activity/${module.activities[0].activity_id}`);
	}

	const responsive = useBreakpoint();

	return (
		!loading && module ? <div className="w-full h-full">
			<CoverImageWrapper responsive={responsive} className="bg-gray-400 w-full"  style={{backgroundImage: `url(${image})`, backgroundSize: "cover", backgroundPosition: "center"}}>
			</CoverImageWrapper>
			<ModuleTopBar module={module} progress={progress}/>
			<ContentWrapper responsive={responsive} className="m-auto mt-5 pb-5">
				<div className="he-paragraph--regular gray-500">
					<span className=" cursor-pointer" onClick={onBack}>
						<i className="pi pi-arrow-left mr-2"/>
						Retour à la liste des modules
					</span>
				</div>
				<InnerWrapper className="mt-5">
					<div className="he-header--h1 gray-900">
						{module.title}
					</div>
					{module.description && <div className="quill-custom mt-5">
                        <ReadOnlyEditor content={module.description || null}/>
                    </div>}
					<SummaryCard className="px-5 py-3 bg-white w-full mt-5">
						<div className="he-header--h2 gray-900">Sommaire du module</div>
						{module.description && <div className="he-paragraph--regular gray-500 mt-3">Préambule</div>}
						{module.activities.map((activity, i) => {
							return <div className="he-paragraph--regular gray-500 mt-3 ml-3" key={i}>
								<i className={classNames(iconForActivity(activity.type), 'mr-2')}/>
								{activity.title}
							</div>;
						})}
					</SummaryCard>
				</InnerWrapper>
				<Button disabled={!finishedPreviousModules || (participant && isSessionFinished(participant))}
				        label="Lancer le module" icon="pi pi-play" className="he-button--primary--md mt-5"
				        onClick={onStart}/>
			</ContentWrapper>
		</div> : <ModuleSkeleton/>
	)
}
