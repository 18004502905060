import { SessionContext } from "@Context/Session.context";
import { useBread } from "@Hooks/breadcrumbs";
import { useUser } from "@Hooks/firebase";
import { SessionCard } from "@Pages/Home/components/SessionCard";
import { SessionParticipant } from "@Types/Session";
import { isSessionFinished } from "@Utils/progress.utils";
import { ResponsiveProps, useBreakpoint } from "@Utils/responsive.utils";
import React, { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ContentWrapper = styled.div<ResponsiveProps>`
  padding: ${props => props.responsive.phone ? "12px" : props.responsive.isTablet ? "24px" : "64px"};
  width: ${props => props.responsive.isDesktop ? "1140px" : "100%"};
`;

const PageContainer = styled.div`
  width: 100%;
`

const CardContainer = styled.div<ResponsiveProps>`
  display: grid;
  grid-template-columns: repeat(${props => (props.responsive.phone || props.responsive.tablet) ? 1 : props.responsive.small ? 2 : 3}, minmax(0, min-content));
  gap: 21px;
  grid-template-rows: 510px;
  grid-auto-rows: 510px;
  margin: ${props => props.responsive.isDesktop ? "0" : "auto"};
`;

const StaticCard = styled.div`
  border: 2px dashed #D0D5DD;
  border-radius: 16px;
  width: 320px;
`

export type LandingProps = {
	finished?: boolean;
}
export const Landing: React.FC<LandingProps> = ({finished}) => {

	const user = useUser(true);
	const navigate = useNavigate();
	const {sessions, loading, refresh} = useContext(SessionContext);
	const {updateTree} = useBread();

	useEffect(() => {
		refresh();
		updateTree(() => []);
	}, []);

	const onGotoFinished = () => {
		navigate('/home/finished');
	}

	const onGotoRunning = () => {
		navigate('/home/running');
	}

	const responsive = useBreakpoint();

	const [runningSessions, finishedSessions] = useMemo(() => {
		const running = [], finished = [];

		for (const s of sessions) {
			const isFinished = isSessionFinished(s.participants.find(p => (p as SessionParticipant).participant_id === user.firebase.uid) as SessionParticipant)
			if (isFinished)
				finished.push(s);
			else
				running.push(s);
		}

		return [running, finished];
	}, [sessions]);

	return (
		<PageContainer className="Landing h-full overflow-auto">
			{!finished &&
                <ContentWrapper
                    responsive={responsive}
                    key={"running"}
                    className="flex flex-column m-auto"
                >
                    <div className="he-header--h1 gray-900 mb-1">
                        Vos formations {loading && <i className="pi pi-spin pi-spinner ml-2"/>}
                    </div>
                    <div className="he-paragraph--regular gray-400 mb-4">
                        Vous avez {runningSessions.length} formation{runningSessions.length > 1 && "s"} en cours ou à
                        venir
                    </div>
                    <CardContainer responsive={responsive}>
						{
							runningSessions.map(s => {
									const participant = s.participants.find(p => (p as SessionParticipant).participant_id === user.firebase.uid);

									return participant &&
                                        <SessionCard session={s} participant={participant as SessionParticipant} key={s.session_id}/>
								}
							)
						}
	                    {responsive.small || responsive.isDesktop && <StaticCard
                            className="bg-gray-200 flex flex-column justify-content-center align-items-center p-5 cursor-pointer select-none"
                            onClick={onGotoFinished}>
                            <div className="mb-3 gray-400">
                                <i className="pi pi-arrow-circle-right" style={{fontSize: "32px"}}/>
                            </div>
                            <div className="he-header--h2 gray-400 text-center">
                                Voir toutes mes formations terminées
                            </div>
                        </StaticCard>}
                    </CardContainer>
                </ContentWrapper>
			}
			{finished &&
                <ContentWrapper
                    responsive={responsive}
                    key={"passed"}
                    className="flex flex-column m-auto"
                >
                    <div className="he-header--h1 gray-900 mb-1">
                        Formations terminées
                    </div>
                    <div className="he-paragraph--regular gray-400 mb-4">
                        Vous avez {finishedSessions.length} formation{finishedSessions.length > 1 && "s"} terminée{finishedSessions.length > 1 && "s"}
                    </div>
	                <CardContainer responsive={responsive}>
		                {responsive.small || responsive.isDesktop && <StaticCard
                            className="bg-gray-200 flex flex-column justify-content-center align-items-center p-5 cursor-pointer select-none"
                            onClick={onGotoRunning}>
                            <div className="mb-3 gray-400">
                                <i className="pi pi-arrow-circle-left" style={{fontSize: "32px"}}/>
                            </div>
                            <div className="he-header--h2 gray-400 text-center">
                                Voir toutes mes formations en cours
                            </div>
                        </StaticCard> }
						{
							finishedSessions.map(s => {
                                const participant = s.participants.find(p => (p as SessionParticipant).participant_id === user.firebase.uid);
								return participant && <SessionCard  participant={participant as SessionParticipant} session={s} isFinished key={s.session_id}/>
							})
						}
                    </CardContainer>
                </ContentWrapper>
			}
		</PageContainer>
	)
}
