import * as React from 'react';
import {Button} from "primereact/button";
import styled from "styled-components";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";


interface Props {
	label: string;
}

export const FunctionItem1: React.FC<Props> = (props) => {

	/* STATES */


	/* EFFECTS */

	/* EVENT HANDLERS */

	// const api = useApi()

	const callFunction = () => {
		// api.dev_call_createAdmin({email: "bruce@wayne.fr"}).then((res) => console.log(res))
	}

	/* RENDERING */

	return (
		<ItemHolder className={"component-FunctionItem"}>
			<Button
				className={"flex-1 p-button-sm"}
				label={props.label}
				onClick={() => {
					confirmDialog({
						message: 'Are you sure you want to proceed?',
						header: 'Confirmation',
						icon: 'pi pi-exclamation-triangle',
						accept: callFunction,
						reject: () => null
					})
				}}
			/>
			<ConfirmDialog/>
		</ItemHolder>
	);

};


const ItemHolder = styled.article`
  display: flex;
  align-items: center;
`;
