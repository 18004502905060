import { useUser } from '@Hooks/firebase';
import { Module } from '@Types/Module';
import { mapDurationUnit } from '@Utils/date.utils';
import { useBreakpoint } from '@Utils/responsive.utils';
import { ProgressBar } from 'primereact/progressbar';
import { classNames } from 'primereact/utils';
import { forwardRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const CardWrapper = styled.div`
    border: 1px solid #eaecf0;
    border-radius: 16px;
    overflow: hidden;
`;

// const ImgWrapper = styled.div<ResponsiveProps>`
//   width: ${props => props.responsive.isDesktop || props.responsive.small ? "200px" : "100px"};
//   overflow: hidden;
// `;

export type ModuleCardProps = {
    module: Module;
    modulesValid: { [module_id: string]: boolean };
    modules: Module[];
};
export const ModuleCard = forwardRef<HTMLDivElement, ModuleCardProps>(({ modulesValid, module, modules }, ref) => {
    const navigate = useNavigate();
    const params = useParams<{ session_id: string }>();
    const { progress: userProgress } = useUser(true);
    // const [image, setImage] = useState<string>();

    const finishedPreviousModules = useMemo<boolean>(() => {
        console.log('finishedPreviousModules', { userProgress, params, module, modules });
        const moduleIndex = modules.findIndex((m) => m.module_id === module.module_id);

        if (moduleIndex === 0) return true;

        if (!params.session_id || !userProgress || !module) return false;

        const sessionProgress = userProgress.filter((p) => p.session_id === params.session_id);

        return modules
            .filter((m, i) => i < moduleIndex)
            .every((m) => {

                const progress = modulesValid[m.module_id];
                const activitiesDone = sessionProgress.filter((sp) => sp.activity.done && sp.module_id === m.module_id);
                return activitiesDone.length === m.activities.length && progress === true;
            });
    }, [userProgress, params, module, modules]);

    const progress = useMemo(() => {
        if (!params.session_id || !userProgress) return 0;

        const sessionProgress = userProgress.filter(
            (p) => p.session_id === params.session_id && p.module_id === module.module_id && p.activity.done
        );

        return (100 * sessionProgress.length) / module.activities.length;
    }, [userProgress]);

    const responsive = useBreakpoint();

    return (
        <CardWrapper
            ref={ref}
            className={'flex w-full h-full'}
            style={{
                opacity: !finishedPreviousModules ? '50%' : '100%',
                cursor: !finishedPreviousModules ? 'default' : 'pointer',
                backgroundColor: modulesValid[module.module_id] ? '#EEFBF4' : 'white',
            }}
            onClick={() =>
                finishedPreviousModules &&
                navigate(`/session/${params.session_id}/module/${module.module_id}`, {
                    state: module,
                })
            }
        >
            <div className="w-full flex flex-column" style={{ padding: 18 }}>
                <div className="flex justify-content-between">
                    <div
                        className={classNames(
                            responsive.phone || responsive.phone ? 'he-paragraph--medium' : 'he-header--h3',
                            'gray-900'
                        )}
                    >
                        {module.title}
                    </div>
                    <div className="flex-0 ml-2">
                        {finishedPreviousModules &&
                        (
                            !modulesValid[module.module_id]
                            )
                            && <Badge>EN COURS</Badge>
                            }
                    </div>
                </div>

                <div className="he-paragraph--regular gray-400 my-2">
                    <i className="pi pi-clock mr-2" /> Durée :{' '}
                    {module.estimated_duration + ' ' + mapDurationUnit(module.estimated_duration_unit)}
                </div>
                <div className="he-paragraph--regular gray-400 mt-auto mb-2">Progression</div>
                <div className="w-full mt-1">
                    <ProgressBar value={progress} className="w-full" style={{ height: 6 }} showValue={false} />
                </div>
            </div>
        </CardWrapper>
    );
});

const Badge = styled.div`
    display: flex;
    padding: 4px 12px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    background: var(--primary-10, #EBF4FC);
    color: #3C90E3;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    width: max-content;
    line-height: 130%;
`
