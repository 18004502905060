import React from "react";
import styled from "styled-components";
import {ResponsiveProps, useBreakpoint} from "@Utils/responsive.utils";
import {classNames} from "primereact/utils";

const CardWrapper = styled.div`
  border: 1px solid #EAECF0;
  border-radius: 16px;
  overflow: hidden;
`;

const ImgWrapper = styled.div<ResponsiveProps>`
  width: ${props => props.responsive.isDesktop || props.responsive.small ? "200px" : "100px"};
  overflow: hidden;
`;

export type CustomCardProps = {
	title: string;
	content: string | React.ReactElement;
	image: string;
	onClick?: () => void;
}
export const CustomCard: React.FC<CustomCardProps> = ({title, image, content, onClick}) => {

	const responsive = useBreakpoint();

    return (
	    <CardWrapper className="flex w-full h-full bg-white cursor-pointer" onClick={onClick}>
		    <ImgWrapper responsive={responsive} className="bg-gray-300" style={{backgroundImage: `url(${image})`, backgroundSize: "cover"}}/>
		    <div className="w-full flex flex-column" style={{padding: 14}}>
			    <div className={classNames((responsive.phone || responsive.phone) ? "he-paragraph--medium" : "he-header--h3","gray-900")}>{title}</div>
			    <div className="he-paragraph--regular gray-400 mt-2">{content}</div>
		    </div>
	    </CardWrapper>
    )
}
