import {DateTime, Duration, Interval} from "luxon";
import {DurationUnit} from "@Types/Time";


export const isLess = (first: string | DateTime, second: string | DateTime): boolean => {
	const firstConv = typeof first === "string" ? DateTime.fromISO(first) : first;
	const secondConv = typeof second === "string" ? DateTime.fromISO(second) : second;
	return firstConv.diff(secondConv, 'seconds').get('seconds') < 0;
}

export const isGreater = (first: string | DateTime, second: string | DateTime): boolean => {
	return !isLess(first, second);
}

export const formatDate = (date?: string | null, format = "dd/MM/yyyy", zone = "Europe/Paris", locale = "fr") => {
	return date ? DateTime.fromISO(date).setLocale(locale).setZone(zone).toFormat(format) : "-";
}
export const isBetween = (date: string | DateTime, first: string | DateTime, second: string | DateTime): boolean => {

	const dateConv = typeof date === "string" ? DateTime.fromISO(date) : date;
	const firstConv = typeof first === "string" ? DateTime.fromISO(first) : first;
	const secondConv = typeof second === "string" ? DateTime.fromISO(second) : second;

	return Interval.fromDateTimes(firstConv, secondConv).contains(dateConv);
}

export const durationFromEstimated = (duration?: number | null, unit?: null | DurationUnit) => {

	if (!unit || !duration) return undefined;

	switch (unit) {
		case "j":
			return Duration.fromObject({
				day: duration
			});
		case "m":
			return Duration.fromObject({
				minutes: duration
			});
		case "s":
			return Duration.fromObject({
				weeks: duration
			});
		case "h":
			return Duration.fromObject({
				hours: duration
			});
	}

}

export const mapDurationUnit = (unit: DurationUnit) => {
	switch (unit) {
		case "s":
			return "s";
		case "m":
			return "min";
		case "j":
			return "j";
		case "h":
			return "h"
	}
}
