import {SessionContext} from '@Context/Session.context';
import {useApi} from '@Hooks/api';
import {useBread} from '@Hooks/breadcrumbs';
import {useUser} from '@Hooks/firebase';
import {usePromise} from '@Hooks/promise';
import {useToast} from '@Hooks/toast';
import {SessionExtended, VirtualMeeting} from '@Types/Session';
import {isLess} from '@Utils/date.utils';
import {DateTime} from 'luxon';
import {classNames} from 'primereact/utils';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Button} from "primereact/button";
import {useBreakpoint} from "@Utils/responsive.utils";
import {CustomDialog} from "@Components/CustomDialog";
import {getBlobForResource} from "@Utils/resource.utils";

const PaddedDiv = styled.div`
    padding: 21px 50px;
`;
const WherebyEmbed = styled.iframe`
    width: 100%;
    height: 100%;
`;

const MessageBefore = styled.div`
    border: 1px solid #cee3f8;
    border-radius: 8px;
    background: #f1f7fd;
    padding: 14px;
`;

const MessageAfter = styled.div`
    background: #fcfcfd;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    padding: 14px;
`;

type VirtualClassProps = {};
export const VirtualClass: React.FC<VirtualClassProps> = (props) => {
    const params = useParams<{
        session_id?: string;
        virtualclass_id?: string;
    }>();

    const [showConfirmDownload, setShowConfirmDownload] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const [isFullscreen, setIsFullscreen] = useState<boolean>();

    useEffect(() => {
        return document.addEventListener('fullscreenchange', (_) => {
            setIsFullscreen(!!document.fullscreenElement);
        });
    }, [ref]);

    const user = useUser(true);

    const [virtualClass, setVirtualClass] = useState<VirtualMeeting>();
    const { session, sessions } = useContext(SessionContext);

    const responsive = useBreakpoint();

    const bread = useBread();
    const api = useApi();
    const navigate = useNavigate();
    const { error } = useToast();

    const onFullScreen = () => {
        if (ref.current) {
            ref.current.requestFullscreen();
        }
    };

    const onCloseFullscreen = () => {
        document.exitFullscreen();
    };

    const [loadVirtualClass, loadingVirtualClass] = usePromise(async (session: SessionExtended<false>) => {
        if (params.virtualclass_id) {
            console.time('Virtual class');
            const res = await api.virtualClass_call_get({
                virtual_class_id: params.virtualclass_id,
            });

            if (res.result !== 'ok') throw new Error(res.result);

            setVirtualClass(res.virtual_class);
            console.log('session', session);
            bread.updateTree((prev) => {
                return [
                    {
                        url: `/session/${params.session_id}`,
                        label: session.formation.title,
                        id: params.session_id,
                    },
                    {
                        url: `/session/${params.session_id}/virtualroom/${params.virtualclass_id}`,
                        label: `Classe virtuelle du ${DateTime.fromISO(res.virtual_class.date!).toFormat('DDD')}`,
                        id: params.virtualclass_id,
                    },
                ];
            });
        }
    });

    const roomUrl = useMemo(() => {
        if (virtualClass?.user_url && user.meta) {
            const url = new URL(virtualClass.user_url);

            url.searchParams.set('lang', 'fr');
            url.searchParams.set('displayName', `${user.meta.firstname} ${user.meta.lastname}`);
            url.searchParams.set('leaveButton', 'on');
            url.searchParams.set('topToolbar', 'on');
            url.searchParams.set('precallReview', 'on');

            return url.toString() + '&minimal&chat&settingsButton&floatSelf&fullscreen';
        }
        return undefined;
    }, [virtualClass, user.meta]);

    useEffect(() => {
        if (sessions.length > 0 && !session) {
            navigate('/');
            error("Impossible d'accéder à cette classe virtuelle");
        };

        if (session && params.session_id && params.virtualclass_id) {
            loadVirtualClass(session);
        }
    }, [params.virtualclass_id, session, params.session_id, sessions]);

    const [downloadFile, loading] = usePromise(async () => {

        if (!session) {
            setShowConfirmDownload(false)
            return;
        }

        try {
            const [res, blob] = await getBlobForResource(session.formation.educational_support_url);

            if (!res?.resource_url || !blob) return;
            const a = document.createElement('a');

            // download file from url using fetch
            const file = new File([blob], res.resource_name, { type: "application/data" });
            const url = window.URL.createObjectURL(file);
            a.href = url;
            a.download = res.resource_name;
            a.click();
            setShowConfirmDownload(false);
        } catch (e) {
            console.error(e);
            error("Une erreur est survenue lors du téléchargement du support pédagogique");
        }
    });

    return (
        <div className="VirtualClass w-full h-full flex flex-column overflow-hidden">
            {
                showConfirmDownload && <CustomDialog visible onHide={() => setShowConfirmDownload(false)} width={400}>
                    <div className="he-header--h2 mb-3 text-center">Téléchargement du support pédagogique</div>
                    <div className="he-paragraph--regular-16 gray-500 mb-4 text-center">
                        Ce support pédagogique a pour seul objectif de faciliter la compréhension et l’apprentissage des vidéos suivantes,
                        sa lecture ne sera pas prise en compte dans le suivi de cette formation. <br /><br /> Pour suivre la formation, cliquez sur "Formation"
                    </div>
                    <div className="flex flex-column gap-2 w-full">
                        <Button
                            className="he-button--primary--md"
                            onClick={downloadFile}
                            loading={loading}
                            disabled={loading}
                        >
                            <i className="pi pi-download" />
                            Télécharger le support
                        </Button>
                        <Button
                            className="he-button--secondary-variant--md"
                            onClick={() => setShowConfirmDownload(false)}
                        >
                            Formation
                        </Button>
                    </div>

                </CustomDialog>
            }
            <PaddedDiv
                className="flex gap-4 align-items-center"
                style={{
                    borderBottom: '1px solid #EAECF0',
                }}
            >
                <div className="he-paragraph--medium gray-500 cursor-pointer" onClick={() => navigate(`/session/${params.session_id}`)}>
                    <i className="pi pi-sign-out mr-2" />
                    Quitter la classe virtuelle
                </div>
                <Button
                    className={classNames(
                        responsive.isDesktop || responsive.small
                            ? 'he-button--secondary-nf--xs'
                            : 'he-button--secondary--xs'
                    )}
                    onClick={() => setShowConfirmDownload(true)}
                    loading={loading}
                    disabled={!session?.formation.educational_support_url}
                >
                    <i className="pi pi-download" />
                    {!session?.formation.educational_support_url ? "Aucun support pédagogique" : "Télécharger le support pédagogique"}
                </Button>
            </PaddedDiv>
            <PaddedDiv className="he-header--h1 flex justify-content-start align-items-center">
                <span>Classe virtuelle</span>
            </PaddedDiv>
            {!loadingVirtualClass && virtualClass?.date && isLess(DateTime.now(), virtualClass.date) && (
                <PaddedDiv>
                    <MessageBefore className="he-paragraph--regular primary-100 bg-primary-7">
                        <i className="pi pi-calendar" /> Classe virtuelle prévue le{' '}
                        {DateTime.fromISO(virtualClass.date).toFormat('D à HH:mm')}. Revenez plus tard pour assister à
                        la classe virtuelle.
                    </MessageBefore>
                </PaddedDiv>
            )}
            {!loadingVirtualClass &&
                virtualClass?.date &&
                isLess(
                    DateTime.fromISO(virtualClass.date).plus({ minute: virtualClass.duration! }),
                    DateTime.now()
                ) && (
                    <PaddedDiv>
                        <MessageAfter className="he-paragraph--regular gray-500 bg-gray-25">
                            <i className="pi pi-calendar-times" /> Classe virtuelle terminée. Le replay est{' '}
                            {virtualClass.replay_url ? 'disponible' : 'indisponible'}.
                        </MessageAfter>
                    </PaddedDiv>
                )}
            <div className="relative w-full flex justify-content-center align-items-center h-full" ref={ref}>
                {
                    <div className="absolute z-5" style={{ top: 55, right: 8 }}>
                        <div
                            className="flex justify-content-center align-items-center cursor-pointer"
                            style={{ background: '#081A29', borderRadius: 10, width: 40, height: 40 }}
                            onClick={isFullscreen ? onCloseFullscreen : onFullScreen}
                        >
                            <i
                                className={classNames(
                                    isFullscreen ? 'pi pi-window-minimize' : 'pi pi-window-maximize',
                                    'color-white'
                                )}
                                style={{ fontSize: '18px' }}
                            />
                        </div>
                    </div>
                }
                {loading && <i className="pi pi-spin pi-spinner gray-900" style={{ fontSize: '4rem' }} />}
                {!loading &&
                    virtualClass?.date &&
                    roomUrl &&
                    isLess(virtualClass.date, DateTime.now()) &&
                    isLess(
                        DateTime.now(),
                        DateTime.fromISO(virtualClass.date).plus({ minute: virtualClass.duration })
                    ) && (
                        <WherebyEmbed
                            className="border-none"
                            allowFullScreen
                            allow="camera; microphone; fullscreen; speaker; autoplay"
                            src={roomUrl}
                        />
                    )}
            </div>
        </div>
    );
};
