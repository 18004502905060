import React, {useEffect, useState} from "react";
import {CustomDialog} from "@Components/CustomDialog";
import {Galleria} from "primereact/galleria";
import {CustomImage} from "@Components/CustomImage";
import {getUrlForResource} from "@Utils/resource.utils";
import {RadioButton} from "primereact/radiobutton";
import styled from "styled-components";
import {useBreakpoint} from "@Utils/responsive.utils";
import {Resource} from "@Types/Resource";
import {Activity, ActivityHistory, TCSActivity, TCSAnswer} from "@Types/Activity";
import {Image} from "primereact/image";

export type TcsDialogProps = {
    onHide: () => void;
    activity: Activity & TCSActivity;
    answers: TCSAnswer;
    activityHistory: ActivityHistory;
}
export const TcsDialog: React.FC<TcsDialogProps> = (props) => {


    const [images, setImages] = useState<(null | Resource | undefined)[]>([]);

    const responsive = useBreakpoint();

    useEffect(() => {
        if (props.activity.cover_image_url) {
            Promise.all(
                props.activity.cover_image_url.map(async (i?: string | null) => {
                    if (i) {
                        return getUrlForResource(i);
                    }
                    return null;
                })
            ).then((imgs) => {
                setImages(imgs.filter((e) => !!e));
            });
        }
    }, [props.activity]);

    const itemTemplate = (item: Resource) => {
        return (
            <Image
                preview
                src={item?.resource_url}
                alt={item?.resource_name}
                width="auto"
                height={'200px'}
                className="pb-5"
            />
        );
    };

    const thumbnailTemplate = (item: Resource) => {
        return <img src={item.resource_url} alt={item.resource_name} style={{ width: 'auto', height: '70px' }} />;
    };


    const responsiveGalleriaOptions = [
        {
            breakpoint: '768px',
            numVisible: 2,
        },
        {
            breakpoint: '450px',
            numVisible: 1,
        },
    ];
    return (
        <CustomDialog
            visible
            onHide={props.onHide}
            width={responsive.isDesktop ? '60vw' : '90vw'}
            closable
        >
            <div className="w-full flex flex-column gap-3">
                <div className="w-full bg-white flex align-items-center justify-content-end">
                    <i onClick={props.onHide} className="pi pi-times cursor-pointer"/>
                </div>
                <div className="pr-2 w-full overflow-auto" style={{minHeight: "max-content", maxHeight: 930}}>
                    <DescriptionContainer className="mb-4 z-2 shadow-1">
                        <div className="he-paragraph--medium gray-800">Cas clinique</div>
                        <div className="he-paragraph--regular-16 gray-600 mt-3">{props.activity.description}</div>
                    </DescriptionContainer>
                    {images.length > 0 && (
                        <div className="bg-gray-600 pt-5 mt-3">
                            <Galleria
                                circular
                                responsiveOptions={responsiveGalleriaOptions}
                                value={images}
                                numVisible={5}
                                item={itemTemplate}
                                style={{ height: '100%' }}
                                thumbnail={thumbnailTemplate}
                            />
                        </div>
                    )}
                    {(responsive.tablet || responsive.phone) && (
                        <div className="mt-3">
                            {props.activity.hypothesis.map((h, hi) => {
                                return (
                                    <HypothesisContainerResponsive className="w-full mt-3" key={hi}>
                                        <div className="w-full he-paragraph--small gray-400">
                                            {props.activity.labels?.hypothesis || 'Si vous pensiez à...'}
                                        </div>
                                        <div
                                            className="he-paragraph--regular flex align-items-center gray-700 py-3 gap-3"
                                            style={{ wordBreak: 'break-word' }}
                                        >
                                            {h.initial}
                                            {h.initial_image && <CustomImage urlPromise={getUrlForResource(h.initial_image).then(r => r?.resource_url)} width="80"/>}
                                        </div>

                                        <div className="he-paragraph--small gray-400">
                                            {props.activity.labels?.new_information || "Et que vous trouvez que/qu'..."}
                                        </div>
                                        <div
                                            className="he-paragraph--regular flex align-items-center  gray-700 py-3 h-full flex align-items-center gap-3"
                                            style={{ wordBreak: 'break-word' }}
                                        >
                                            {h.information}
                                            {h.information_image && <CustomImage urlPromise={getUrlForResource(h.information_image).then(r => r?.resource_url)} width="80"/>}
                                        </div>
                                        <div className="he-paragraph--small gray-400">
                                            {'L’impact de cette information sur votre hypothèse est...'}
                                        </div>
                                        {h.answers.map((a, ai) => {
                                            const label =
                                                props.activity.labels && props.activity.labels[ai as 1 | 2 | 3 | 4 | 0];
                                            const selected = props.answers[hi] !== undefined && ai === props.answers[hi];
                                            const done = Boolean(props.activityHistory.done);
                                            return (
                                                <TCSButton
                                                    selected={selected}
                                                    done={done}
                                                    key={ai}
                                                    className="h-full flex py-3 align-items-center justify-content-start mt-2"
                                                >
                                                    <RadioButton checked={selected} disabled={props.activityHistory.done} />
                                                    <div
                                                        className={
                                                            selected
                                                                ? 'primary-100 he-paragraph--regular--bold'
                                                                : 'gray-900 he-paragraph--regular'
                                                        }
                                                    >
                                                        {label || defaults[ai]}
                                                    </div>
                                                    {done && (
                                                        <div className="ml-auto he-paragraph--regular gray-400">
                                                            ({props.activity.hypothesis[hi].answers[ai]})
                                                        </div>
                                                    )}
                                                </TCSButton>
                                            );
                                        })}
                                    </HypothesisContainerResponsive>
                                );
                            })}
                        </div>
                    )}
                    {(responsive.isDesktop || responsive.small) && (
                        <QuizzContainer className="mt-3">
                            <HeaderContainer>
                                <div style={{ gridArea: 'hypothesis' }} className="w-full he-paragraph--small primary-700">
                                    {props.activity.labels?.hypothesis || 'Si vous pensiez à...'}
                                </div>
                                <div style={{ gridArea: 'information' }} className="he-paragraph--small primary-700">
                                    {props.activity.labels?.new_information || "Et que vous trouvez que/qu'..."}
                                </div>
                                <div style={{ gridArea: 'info' }} className="he-paragraph--small primary-700">
                                    {'L’impact de cette information sur votre hypothèse est...'}
                                </div>
                                <div style={{ gridArea: 'line', borderBottom: '1px solid #EAECF0' }}></div>
                                {defaults.map((text, i) => {
                                    const label = props.activity.labels && props.activity.labels[i as 1 | 2 | 3 | 4 | 0];
                                    return (
                                        <div
                                            key={i}
                                            style={{ gridArea: `l${i}`, wordBreak: 'break-word' }}
                                            className="he-paragraph--xs gray-500 text-center"
                                        >
                                            {label || text}
                                        </div>
                                    );
                                })}
                            </HeaderContainer>
                            {props.activity.hypothesis.map((h, hi) => {
                                return (
                                    <HypothesisContainer className="bg-gray-50 mt-2" key={hi}>
                                        <div className="he-paragraph--regular flex flex-column justify-content-center gap-2 gray-700 py-3 pl-3 align-items-center" style={{wordBreak: "break-word"}}>
                                            {h.initial}
                                            {h.initial_image && <CustomImage urlPromise={getUrlForResource(h.initial_image).then(r => r?.resource_url)} width="80"/>}
                                        </div>
                                        <div className="he-paragraph--regular flex flex-column justify-content-center gap-2 gray-700 py-3 h-full align-items-center" style={{wordBreak: "break-word"}}>
                                            {h.information}
                                            {h.information_image && <CustomImage urlPromise={getUrlForResource(h.information_image).then(r => r?.resource_url)} width="80"/>}
                                        </div>
                                        {h.answers.map((a, ai) => {
                                            return (
                                                <div
                                                    key={ai}
                                                    className="h-full flex flex-column py-3 align-items-center justify-content-center"
                                                >
                                                    <RadioButton
                                                        checked={props.answers[hi] !== undefined && ai === props.answers[hi]}
                                                        disabled={props.activityHistory.done}
                                                    />
                                                    {props.activityHistory.done && (
                                                        <div className="mt-2 he-paragraph--small gray-600">
                                                            ({props.activity.hypothesis[hi].answers[ai]})
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </HypothesisContainer>
                                );
                            })}
                        </QuizzContainer>
                    )}
                </div>

            </div>
        </CustomDialog>
    )
}

const DescriptionContainer = styled.div`
    background: #f9fafb;
    border-radius: 8px;
    padding: 20px;
`;

const QuizzContainer = styled.div`
    border: 1px solid #eaecf0;
    border-radius: 8px;
    padding: 20px;
`;

const HeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 180px 180px repeat(5, minmax(0, 1fr));
    grid-template-rows: auto 1px auto;
    column-gap: 8px;
    row-gap: 8px;
    grid-template-areas:
        'hypothesis information info info info info info'
        'line line line line line line line'
        '. . l0 l1 l2 l3 l4';
`;

const HypothesisContainer = styled.div`
    display: grid;
    grid-template-columns: 180px 180px repeat(5, minmax(0, 1fr));
    grid-template-rows: minmax(30px, auto);
    column-gap: 8px;
    grid-auto-rows: minmax(30px, auto);
`;

const HypothesisContainerResponsive = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    padding: 16px;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    width: 100%;
`;

const defaults = ['Fortement négatif', 'Négatif', 'Neutre', 'Positif', 'Fortement positif'];

const TCSButton = styled.div<{ selected: boolean; done: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 12px;
    ${(props) =>
    props.selected
        ? props.done
            ? `
  background: white;
  border: 1px solid #3C90E3;
	`
            : `
  background: #F1F7FD;
  border: 1px solid #3C90E3;
	`
        : `
  background: #FFFFFF;
  border: 1px solid #EAECF0;
	`}
    border-radius: 12px;
`;
