import {ReadOnlyEditor} from '@Components/ReadOnlyEditor';
import {MCQ} from '@Pages/Home/Session/pages/Module/pages/Activity/QuizzActivity/MCQ';
import {UCQ} from '@Pages/Home/Session/pages/Module/pages/Activity/QuizzActivity/UCQ';
import {
    Activity,
    ActivityHistory,
    FreeTextActivity as FreeTextActivityModel,
    QuizzActivity as QuizzActivityModel,
    QuizzAnswer
} from '@Types/Activity';
import {Resource} from '@Types/Resource';
import {getUrlForResource} from '@Utils/resource.utils';
import {useBreakpoint} from '@Utils/responsive.utils';
import {Galleria} from 'primereact/galleria';
import {Image} from 'primereact/image';
import {classNames} from 'primereact/utils';
import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import ReactPlayer from "react-player";
import {DisplayAnswer} from "@Pages/Home/Session/pages/Module/pages/Activity/FreeTextActivity/DisplayAnswer";

const ExplanationContainer = styled.div`
    background: #f1f5f9;
    border-left: 4px solid #2ecc71;
`;

export type QuizzActivityProps = {
    activity: Activity & (QuizzActivityModel | FreeTextActivityModel);
    history: ActivityHistory;
};
export const ActivityPreview: React.FC<QuizzActivityProps> = (props) => {

    const itemTemplate = (item: Resource) => {
        return (
            <Image
                preview
                src={item?.resource_url}
                alt={item?.resource_name}
                width="auto"
                height={'200px'}
                className="pb-5"
            />
        );
    };

    const thumbnailTemplate = (item: Resource) => {
        return <img src={item.resource_url} alt={item.resource_name} style={{ width: 'auto', height: '70px' }} />;
    };

    const [images, setImages] = useState<(null | Resource | undefined)[]>([]);
    const [videos, setVideos] = useState<(null | Resource | undefined)[]>([]);


    useEffect(() => {
        Promise.all(
            props.activity.cover_image_url.map(async (i?: string | null) => {
                if (i) {
                    return getUrlForResource(i);
                }
                return null;
            })
        ).then((imgs) => {
            setImages(imgs.filter((e) => !!e));
        });

        Promise.all(props.activity.cover_video_url.map(async (i?: string | null) => {
            if (i) {
                return getUrlForResource(i);
            }
            return null;
        })).then(vids => {
            console.log('new request')
            setVideos(vids.filter(Boolean));
        })
    }, [props.activity]);

    const responsive = useBreakpoint();

    const responsiveGalleriaOptions = [
        {
            breakpoint: '768px',
            numVisible: 2,
        },
        {
            breakpoint: '450px',
            numVisible: 1,
        },
    ];

    const resources = useMemo(() => {
        return videos.map(v => {
            if (v != null) {
                return <ReactPlayer
                    progressInterval={30_000}
                    config={{
                        file: {
                            attributes: {
                                controlsList: "nodownload"
                            }
                        }
                    }}
                    url={v.resource_url}
                    height={"150px"}
                    width={"auto"}
                    controls
                />
            }
            return null;
        })
    }, [videos])

    return (
        <div className="w-full h-full">
            <div className={classNames(responsive.isDesktop ? 'he-header--h1' : 'he-header--h1-small', 'gray-900')}>
                {props.activity.title}
            </div>
            <div className={classNames(responsive.isDesktop ? 'quill-custom' : 'quill-custom-mobile', 'mt-4 p-1')}>
                <ReadOnlyEditor content={props.activity.question || ''} />
            </div>
            {images.length > 0 && (
                <div className="bg-gray-600 pt-5 mt-3">
                    <Galleria
                        circular
                        responsiveOptions={responsiveGalleriaOptions}
                        value={images}
                        numVisible={5}
                        item={itemTemplate}
                        style={{ height: '100%' }}
                        thumbnail={thumbnailTemplate}
                    />
                </div>
            )}
            {
                resources.length > 0 && <div>
                    <div className="he-paragraph--medium gray-900 my-4">Ressources vidéos</div>
                    <div className="flex gap-4 flex-wrap">
                        {resources}
                    </div>
                </div>
            }
            <div className="mt-2">
                {props.activity.type === 'mcq' && (
                    <MCQ
                        is_done={props.history.done}
                        answers={(props.activity as QuizzActivityModel).answers}
                        selection={props.history.answers as QuizzAnswer}
                        onSelect={() => {}}
                        onUpdateOther={() => {}}
                        others={props.history.others ?? {}}
                        is_image={(props.activity as QuizzActivityModel).is_response_img}
                    />
                )}
                {props.activity.type === 'ucq' && (
                    <UCQ
                        is_done={props.history.done}
                        answers={(props.activity as QuizzActivityModel).answers}
                        selection={props.history.answers as QuizzAnswer}
                        onSelect={() => {}}
                        onUpdateOther={() => {}}
                        others={props.history.others ?? {}}
                        is_image={(props.activity as QuizzActivityModel).is_response_img}
                    />
                )}
                {
                    props.activity.type === 'freetext' && (
                        <DisplayAnswer answer={props.history}
                                       activity={(props.activity as Activity & FreeTextActivityModel)}/>
                    )
                }
            </div>

            {props.history.done && props.activity.type !== "freetext" && (props.activity as QuizzActivityModel).answer_explanation && (
                <ExplanationContainer className="mt-2 py-2 px-3">
                    <div className="he-paragraph--medium gray-900">Correction</div>
                    <div className="he-paragraph--regular gray-400 mt-2">{(props.activity as QuizzActivityModel).answer_explanation}</div>
                </ExplanationContainer>
            )}
        </div>
    );
};
