import {
    collection,
    setDoc,
    updateDoc,
    deleteDoc,
    doc,
    getDoc,
    onSnapshot,
    Query,
    getFirestore,
    Firestore,
    query,
    QueryConstraint,
    where,
    getDocs,
    WhereFilterOp,
    DocumentData,
	collectionGroup,
    Unsubscribe,
    QuerySnapshot,
    DocumentSnapshot,
    connectFirestoreEmulator
} from "firebase/firestore";
import {FirebaseApp} from "@firebase/app";




type Where<T> = {
    get(): Promise<T[]>;
    where: (field: string, condition: WhereFilterOp, match: string) => Where<T>;
    onSnapshot: (onNext: (snapshot: QuerySnapshot<T>) => void) => Unsubscribe
}





export class FirebaseFirestore {

	public db: Firestore

	constructor(app: FirebaseApp, emulator?: {
		host: string,
		port: number
	}) {
		this.db = getFirestore(app)
		if (emulator) {
			connectFirestoreEmulator(this.db, emulator.host, emulator.port);
		}
	}

	collection<T extends Record<string, any>>(ref: string) {
		return new FirebaseCollection<T>(this.db, ref);
	}

	collectionGroup<T extends Record<string, any>>(ref: string, contraints: {
		where: [keyof T extends string ? keyof T : never, WhereFilterOp, string]
	}) {
		const q = query(collectionGroup(this.db, ref), where(...contraints.where))
		return getDocs(q) as Promise<QuerySnapshot<T>>;
	}

}

export class FirebaseCollection<T extends Record<string, any> = DocumentData> {

	private readonly ref: string;

	constructor(private db: Firestore, ref: string) {
		this.ref = ref
	}

	/***
	 * @param docUid - document id.
	 * @param data - object<T> set.
	 ***/
	public set(docUid: string, data: T) {
		return setDoc(doc(this.db, this.ref, docUid), data)
	}

	/***
	 * @param field - The path to compare.
	 * @param condition - The operation string (e.g “", "=", "==", ">“, “>=“).
	 * @param match - The value for comparison.
	 * @Returns The created Query.
	 ***/
	where(field: string, condition: WhereFilterOp, match: string|number, old: QueryConstraint[] = []): Where<T> {
		const constraints: QueryConstraint[] = [...old, where(field, condition, match)]
		return {
			get: () => this._get(constraints),
			where: (field: string, condition: WhereFilterOp, match: string) => this.where(field, condition, match, constraints),
			onSnapshot: (onNext: (snapshot: QuerySnapshot<T>) => void) => this._onSnapshot(constraints, onNext),
		}
	}

	private _get(customWhere: QueryConstraint[]): Promise<T[]> {
		const q = query(collection(this.db, this.ref), ...customWhere)
		return getDocs(q).then(querySnapshot => {
			return querySnapshot.docs.map((doc) => {
				return doc.data() as T
			})
		})
	}

	/***
	 * Return Promise with list of document<T>
	 ***/
	public getAll(): Promise<T[]> {
		return getDocs(collection(this.db, this.ref)).then(snap => {
				return snap.docs.map((d) => {
					return d.data() as T
				})
			}
		)
	}

	/***
     Params: Document ID of collection.
     Returns: A Promise with document data - type <T>.
	 ***/
	public get(docUid: string): Promise<T> {
		return getDoc(doc(this.db, this.ref, docUid)).then(res => res.data() as T)
	}

	/***
	 * @returns Unsubscribe callback.
	 ***/
	private _onSnapshot(customWhere: QueryConstraint[], onNext: (snapshot: QuerySnapshot<T>) => void): Unsubscribe {
		const q = query<T>(collection(this.db, this.ref) as unknown as Query<T>, ...customWhere)
		const unsub = onSnapshot<T>(q, onNext)
		return unsub
	}

	/***
	 * @param docUid - document id listen.
	 * Return Unsubscribe
	 ***/
	public onSnapshot(docUid: string, onNext: (snapshot: DocumentSnapshot<T>) => void): Unsubscribe {
		const unsub = onSnapshot<T>(doc(collection(this.db, this.ref), docUid) as any, onNext as any)
		return unsub
	}

	/***
	 * @param docUid - document id.
	 ***/
	public delete(docUid: string) {
		return deleteDoc(doc(this.db, this.ref, docUid))
	}

	/***
	 * @param docUid - document id.
	 * @param data - object<T> update.
	 ***/
	public update(docUid: string, data: T) {
		return updateDoc(doc(this.db, this.ref, docUid), data)
	}

}
