import {appFirebase, firestore} from "@Utils/config/firebase";
import {Resource} from "@Types/Resource";
import { getBlob, getStorage, ref } from "firebase/storage";

export const getUrlForResource = async (resource?: null | string) => {
	if (!resource) return undefined;
	return firestore.collection<Resource>('resources').get(resource)
}

export const getBlobForResource = async (resource?: null | string) => {
	if (!resource) return [null, null];
	const file = await firestore.collection<Resource>('resources').get(resource);
	const blob = await getBlob(ref(getStorage(appFirebase), `resources/${resource}`));
	return [file, blob] as const;
}