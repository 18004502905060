import React from "react";
import {Skeleton} from "primereact/skeleton";
import styled from "styled-components";

const ContentWrapper = styled.div`
	width: 1140px;
`


export type ModuleSkeletonProps = Record<string, never>
export const ModuleSkeleton: React.FC<ModuleSkeletonProps> = () => {

    return (
        <div className="ModuleSkeleton w-full h-full">
	        <Skeleton height="350px" width="100%" />
	        <div className="w-full p-3 bg-white">
		        <Skeleton height="33px" width="230px"/>
	        </div>
			<ContentWrapper>
				<div className={"mx-auto"} style={{width: 710}}>
					<Skeleton width="187px" height="18px" className="mt-5"/>
					<Skeleton width="710px" height="42px" className="mt-5"/>
					<Skeleton width="710px" height="97px" className="mt-4"/>
					<Skeleton width="710px" height="325px" className="mt-5"/>
				</div>
			</ContentWrapper>
        </div>
    )
}
