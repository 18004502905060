import { CustomTabView } from '@Components/CustomTabView';
import { SessionContext } from '@Context/Session.context';
import { useApi } from '@Hooks/api';
import { useBread } from '@Hooks/breadcrumbs';
import { usePromise } from '@Hooks/promise';
import { useToast } from '@Hooks/toast';
import { Modules } from '@Pages/Home/Session/Modules';
import { VirtualClasses } from '@Pages/Home/Session/VirtualClasses';
import { TCSResults } from '@Pages/Home/Session/pages/TCSResults';
import { SessionInnerCard } from '@Pages/Home/components/SessionInnerCard';
import { SessionSkeleton } from '@Pages/Home/components/SessionSkeleton';
import { EndpointReturn } from '@Types/../Helpers';
import { Formation } from '@Types/Formation';
import { Module } from '@Types/Module';
import { SessionExtended, SessionParticipant, VirtualMeeting } from '@Types/Session';
import { getUrlForResource } from '@Utils/resource.utils';
import { ResponsiveProps, useBreakpoint } from '@Utils/responsive.utils';
import { DateTime } from 'luxon';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import styled from 'styled-components';
import { CustomPanel } from './layouts/components/CustomPanel';
import { ReadOnlyEditor } from '@Components/ReadOnlyEditor';
import { ScrollContainer } from './layouts/components/ScrollContainer';
import { Button } from 'primereact/button';
import { FCResults } from '@Pages/Home/Session/pages/FCResults';

const ContentWrapper = styled.div<ResponsiveProps>`
    position: relative;
    max-width: 1076px;
    padding: ${(props) => (props.responsive.isDesktop || props.responsive.isTablet ? '0 32px' : '8px')};
`;

const CoverImageWrapper = styled.div<ResponsiveProps>`
    height: ${(props) => (props.responsive.isDesktop ? '200px' : props.responsive.small ? '150px' : '100px')};
    overflow: hidden;
`;

export const Session: React.FC<{}> = () => {
    const params = useParams<{ session_id: string }>();
    const [image, setImage] = useState<string>();
    const [session, setSession] = useState<SessionExtended<false>>();
    const [formation, setFormation] = useState<Formation>();
    const [participants, setParticipants] = useState<SessionParticipant<false>[]>([]);
    const [virtualClasses, setVirtualClasses] = useState<VirtualMeeting[]>([]);
    const [modules, setModules] = useState<Module[]>([]);

    const { refresh } = useContext(SessionContext);
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const navigate = useNavigate();

    const api = useApi();
    const { error } = useToast();

    const { updateTree } = useBread();

    const [loadSession, loading] = usePromise(async () => {
        if (params.session_id) {
            let moduleRes: EndpointReturn<'formation', 'modules'> extends Promise<infer A> ? A : never;
            let _formation: Formation;
            let _participants: SessionParticipant<false>[] = [];
            let _session: SessionExtended<false>;

            const sessionRes = await api.session_call_get({
                session_id: params.session_id,
            });

            if (sessionRes.result !== 'ok') {
                navigate('/home');
                error(sessionRes.result);
                throw new Error(sessionRes.result);
            }

            _formation = sessionRes.formation;
            _participants = sessionRes.participants;
            _session = {
                ...sessionRes.session,
                participants: sessionRes.participants,
                formation: sessionRes.formation,
            };

            moduleRes = await api.formation_call_modules({
                formation_id: sessionRes.formation.formation_id,
            });

            if (_formation) {
                const resource = await getUrlForResource(_formation.cover_image_big_url || _formation.cover_image_url);
                setImage(resource?.resource_url);
                updateTree(() => [
                    {
                        url: `/session/${params.session_id}`,
                        label: _formation.title,
                        id: params.session_id,
                    },
                ]);
            }

            if (moduleRes.result !== 'ok') throw new Error(moduleRes.result);

            setSession(_session);
            setParticipants(_participants);
            setFormation(_formation);

            setModules(moduleRes.modules);

            const virtualRes = await api.virtualClass_call_all({
                session_id: params.session_id,
            });

            if (virtualRes.result !== 'ok') throw new Error(virtualRes.result);
            setVirtualClasses(virtualRes.virtual_classes);
        }
    });

    useEffect(() => {
        loadSession();
        refresh();
    }, [params]);

    const onGoHome = () => {
        navigate('/home');
    };

    const onGoToForum = () => {
        updateTree(() => [
            {
                label: session?.formation.title,
                url: `/session/${params.session_id}`,
            },
            {
                id: 'forum',
                label: 'Forum',
            },
        ]);
        navigate(`/home/forum/${session?.formation.formation_id}`);
    };

    // get url query params
    const { search } = useLocation();

    const query = useMemo(() => new URLSearchParams(search), [search]);

    useEffect(() => {
        if (query.has('u')) {
            setSelectedTab(+query.get('u')!);
        }
    }, [query]);

    const responsive = useBreakpoint();

    const hasVirtualClasses = virtualClasses.length > 0;

    const remainingVirtualClasses = virtualClasses.filter(
        (vc) =>
            vc.date &&
            DateTime.fromISO(vc.date).setZone('Europe/Paris').plus({ minutes: vc.duration }).toMillis() > Date.now()
    );

    return !loading && formation && session && participants ? (
        <ScrollContainer scrollBottom>
            <div className="Session w-full h-full">
                <CoverImageWrapper
                    responsive={responsive}
                    className="bg-gray-400 w-full"
                    style={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                ></CoverImageWrapper>
                <ContentWrapper responsive={responsive} className="mx-auto pb-4">
                    <div className="w-full flex flex-column" style={responsive.isDesktop ? { maxWidth: '640px' } : {}}>
                        <div
                            className={classNames(
                                responsive.isDesktop || responsive.small ? 'mt-4 mb-3' : 'my-3',
                                'he-paragraph--small gray-500 cursor-pointer w-max ml-1'
                            )}
                            onClick={onGoHome}
                        >
                            <i className="pi pi-arrow-left mr-2" />
                            Retour à la liste des formations
                        </div>
                        <SessionInnerCard
                            session={session}
                            participants={participants}
                            formation={formation}
                            modules={modules}
                        />
                        {responsive.isDesktop && (
                            <>
                                <div className="he-header--h2 gray-900 mb-1">{formation.title}</div>
                                <div className="he-paragraph--regular gray-400 mt-2">
                                    <span>
                                        La formation se termine le {DateTime.fromISO(session.end_date).toFormat('DDD')}
                                    </span>
                                    <div className="mt-2 flex flex-column">
                                        {session.unitsConfig.map((u, i) => {
                                            return i < session?.unitsConfig.length - 1 ? (
                                                <span className="mb-2" key={i}>
                                                    &bull; La partie {i + 1} se termine le{' '}
                                                    {u.end_date ? DateTime.fromISO(u.end_date).toFormat('DDD') : ' - '}
                                                </span>
                                            ) : (
                                                <span className="mb-2" key={i}>
                                                    &bull; La partie {i + 1} commence le{' '}
                                                    {u.start_date
                                                        ? DateTime.fromISO(u.start_date).toFormat('DDD')
                                                        : ' - '}{' '}
                                                    et se termine le{' '}
                                                    {u.end_date ? DateTime.fromISO(u.end_date).toFormat('DDD') : ' - '}
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div>
                            </>
                        )}

                        {formation.description?.trim() && formation.description !== '<p><br></p>' && (
                            <CustomPanel label="Voir la description" className="my-3">
                                <div className="quill-custom mt-2">
                                    <ReadOnlyEditor content={formation.description || null} />
                                </div>
                            </CustomPanel>
                        )}
                        {/*{responsive.small && <div className="py-5">&nbsp;</div>}*/}
                        {/*{formation.description?.trim() && <div className="quill-custom mt-6">*/}
                        {/*    <ReadOnlyEditor content={formation.description || null}/>*/}
                        {/*</div>}*/}
                    </div>
                    <div>
                        <div className="w-full flex gap-3 mt-2">
                            <CustomTabView
                                selected={selectedTab}
                                onChange={setSelectedTab}
                                items={[
                                    ...session.formation.units.map((u, i) => ({
                                        label: `Partie ${i + 1}`,
                                    })),
                                    ...(virtualClasses.length > 0
                                        ? [
                                              {
                                                  label: 'Classes virtuelles',
                                                  badge:
                                                      remainingVirtualClasses.length > 0
                                                          ? remainingVirtualClasses.length
                                                          : undefined,
                                              },
                                          ]
                                        : []),
                                    ...(['Formation continue (FC)', 'Parcours intégré'].includes(formation?.type)
                                        ? [
                                              {
                                                  label: 'Résultats QCM',
                                              },
                                          ]
                                        : []),
                                    ...(modules.some((m) =>
                                        m.activities.find((a) => a.type === 'tcs' || a.type == 'freetext')
                                    )
                                        ? [
                                              {
                                                  label: "Résultats et plan d'amélioration",
                                              },
                                          ]
                                        : []),
                                ]}
                            />
                            <Button className="he-button--secondary-nf--xs" onClick={onGoToForum}>
                                {responsive.isDesktop || responsive.isTablet ? 'Forum' : ''}
                                <i className="pi pi-comments" />
                            </Button>
                        </div>
                        <div className="w-full mt-4">
                            {selectedTab < session.formation.units.length && (
                                <Modules
                                    showSatisfaction={selectedTab === session.formation.units.length - 1}
                                    modules={modules.filter((m) =>
                                        session.formation.units[selectedTab]?.modules_ids.includes(m.module_id)
                                    )}
                                    unitIndex={selectedTab}
                                    unit={session.formation.units[selectedTab]}
                                />
                            )}
                            {virtualClasses.length > 0 && selectedTab === session.formation.units.length && (
                                <VirtualClasses virtual_classes={virtualClasses} />
                            )}
                            {selectedTab ===
                                (hasVirtualClasses
                                    ? session.formation.units.length + 1
                                    : session.formation.units.length) &&
                                (['Formation continue (FC)', 'Parcours intégré'].includes(session.formation.type) ? (
                                    <FCResults modules={modules} />
                                ) : (
                                    <TCSResults modules={modules} />
                                ))}
                            {session.formation.type !== 'Evaluation des pratiques professionnelles (EPP)' &&
                                selectedTab ===
                                    (hasVirtualClasses
                                        ? session.formation.units.length + 2
                                        : session.formation.units.length + 1) && <TCSResults modules={modules} />}
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </ScrollContainer>
    ) : (
        <SessionSkeleton />
    );
};
