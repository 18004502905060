import {useEffect, useState} from "react";
import {Message, MessageExtended} from "@Types/Message";
import {
    collection,
    doc,
    getCountFromServer,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    where
} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {ResponsiveProps, useBreakpoint} from "@Utils/responsive.utils";
import {MessageList} from "@Pages/Home/Forum/MessageList";
import {MessageInputBox} from "@Pages/Home/Forum/MessageInputBox";
import _ from "lodash";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {useBread} from "@Hooks/breadcrumbs";

export const Forum = () => {

    const [messages, setMessages] = useState<MessageExtended[]>([]);
    const params = useParams<{formation_id?: string}>();
    const [order, _setOrder] = useState<"asc" | "desc" | "likes">("desc");
    const [limitResults, setLimitResults] = useState<number>(50);
    const [total, setTotal] = useState<number>(0);
    const [banList, setBanList] = useState<string[]>([]);


    const responsive = useBreakpoint();
    useEffect(() => {
        if (!params.formation_id) return;

        const q = query(
            collection(firestore.db, `formations/${params.formation_id}/messages`),
            where("formation_id", "==", params.formation_id),
            order !== "likes" ? orderBy('created_at', order) : orderBy('likes', 'desc'),
            limit(limitResults)
        );

        return onSnapshot(q, (snapshot) => {
            const docs = snapshot.docs.map(doc => doc.data() as Message);
            const queryTotal = query(collection(firestore.db, `formations/${params.formation_id}/messages`), where("formation_id", "==", params.formation_id))

            const messageWithReplies = docs.filter(message => message.replyTo !== null);
            const chunked = _.chunk(messageWithReplies, 10);
            Promise.all(chunked.map(chunk => {
                const sq = query(collection(firestore.db, `formations/${params.formation_id}/messages`),
                    where("formation_id", "==", params.formation_id),
                    where("message_id", "in", chunk.map(m => m.replyTo!))
                );
                return getDocs(sq);
            }))
                .then(async snapshotsArr => {
                    const total = getCountFromServer(queryTotal);
                    const res = await total;
                    setTotal(res.data().count);
                    return snapshotsArr;
                })
                .then(snapshotsArr => {
                const results = snapshotsArr.map(s => s.docs).flat().map(d => d.data() as Message);
                const extended = docs.map(message => {
                    const reply = results.find(result => result.message_id === message.replyTo);
                    return {
                        ...message,
                        reply: reply || null
                    }
                });
                setMessages(extended);
            })
        });
    }, [params, limitResults, order]);

    useEffect(() => {
        onSnapshot(doc(firestore.db, "settings", "forum"), snapshot => {
            const data = snapshot.data();
            if (data) {
                setBanList(data.ban_words);
            }
        });
    }, []);

    const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);

    const options = [
        {
            label: "Plus récent",
            value: "desc"
        },
        {
            label: "Plus ancien",
            value: "asc"
        },
        {
            label: "Popularité",
            value: "likes"
        }
    ];

    const bread = useBread();
    const navigate = useNavigate();

    const onGoBack = () => {
        let last = bread.navigation_tree[bread.navigation_tree.length - 2];
        console.log(bread);
        bread.updateTree(old => {
            const newTree = [...old];
            newTree.pop();
            return newTree;
        });
        console.log(last);

        navigate(last?.url || "/home");

    }

    return (
        params.formation_id ?
            <div className="w-full h-full bg-primary-7 p-2 gap-2">
                <Container responsive={responsive} className="align-items-start">
                    <Button className="he-button--secondary-variant-nfb--xs" onClick={onGoBack}>
                        <i className={"pi pi-angle-left mr-2"}/> Retour
                    </Button>
                </Container>
                <Container responsive={responsive} className="border-round-md shadow-1 bg-white" style={{height: "calc(100% - 36px)"}}>
                    <div className="flex gap-2 align-items-center px-3 py-2 justify-content-end">
                        <span className="he-paragraph--small gray-500">
                            Trier par
                        </span>
                        <Dropdown options={options} value={order} onChange={e => _setOrder(e.value)}/>
                    </div>
                    <MessageList
                        total={total}
                        order={order}
                        formation_id={params.formation_id}
                        messages={messages}
                        onLoadMore={() => setLimitResults(prev => Math.min(prev + 50, total))}
                        onReply={message => setSelectedMessage(message)}
                    />
                    <MessageInputBox
                        messages={messages}
                        formation_id={params.formation_id}
                        selectedMessage={selectedMessage}
                        setSelectedMessage={setSelectedMessage}
                        banList={banList}
                    />
                </Container>
            </div>
            :
            <Navigate to={"/home"}/>
    );
};


const Container = styled.div<ResponsiveProps>`
    width: 100%;
    max-width: ${
        props => {
            if (props.responsive.isDesktop) return "900px";
            if (props.responsive.isTablet) return "768px";
            return "100%";
        }
    };
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    .p-dropdown {
        border: 1px solid #e0e0e0;
        height: 26px;
        border-radius: 4px;
        &-label {
            padding: 8px;
            display: flex;
            align-items: center;
            height: 100%;
            font-size: 14px;
        }
        &-trigger {
            width: 1.5rem;
            
            svg.p-icon {
                width: 12px;
                height: 12px;
            }
        }
    }
`
