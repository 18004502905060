import { CustomInput } from '@Components/CustomInput';
import { Button } from 'primereact/button';
import { CustomDialog } from '@Components/CustomDialog';
import { useState } from 'react';
import { useApi } from '@Hooks/api';
import { useToast } from '@Hooks/toast';
import { auth } from '@Utils/config/firebase';
import {reauthenticateWithCredential, EmailAuthProvider} from "firebase/auth";

type UpdatePasswordDialogProps = {
    onHide: () => void;
}

export const UpdatePasswordDialog: React.FC<UpdatePasswordDialogProps> = (props) => {


    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>("");
    const [errors, setErrors] = useState<Record<string, string | undefined>>({});

    const api = useApi();
    const [loading, setLoading] = useState<boolean>(false);

    const {success} = useToast();
    const onUpdatePassword = async () => {

        setLoading(true);
        setErrors({});
        if (oldPassword.length === 0) {
            setErrors({
                oldPassword: "Veuillez renseigner votre ancien mot de passe"
            })
            setLoading(false);
            return;
        }
        if (newPassword.length === 0) {
            setErrors({
                newPassword: "Veuillez renseigner votre nouveau mot de passe"
            });
            setLoading(false);
            return;
        }
        if (newPasswordConfirmation.length === 0) {
            setErrors({
                newPasswordConfirmation: "Veuillez confirmer votre nouveau mot de passe"
            });
            setLoading(false);
            return;
        }
        if (newPassword !== newPasswordConfirmation) {
            setErrors({
                newPasswordConfirmation: "Les mots de passe ne correspondent pas"
            })
            setLoading(false);
            return;
        }

        const fbAuth = auth.auth;

        try {
            await reauthenticateWithCredential(fbAuth.currentUser!, EmailAuthProvider.credential(fbAuth.currentUser!.email!, oldPassword));
            try {
                const res = await api.user_call_updatePassword({
                    new_password: newPassword,
                });

                await auth.signInWithEmailAndPassword(fbAuth.currentUser!.email!, newPassword);
                if (res.result === "ok") {
                    success("Votre mot de passe a bien été mis à jour");
                    setLoading(false);
                    props.onHide();
                }
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        } catch (e) {
            setErrors({
                oldPassword: "Votre ancien mot de passe est incorrect"
            });
        } finally {
            setLoading(false);
        }

    }

    return (
        <CustomDialog visible onHide={props.onHide} width={"400px"}>
            <div className="he-header--h3">
                Mise à jour de votre mot de passe
            </div>
            <div className={"w-full h-full flex flex-column gap-2 my-4"}>
                <CustomInput
                    label={"Ancien mot de passe"}
                    type={"password"}
                    placeholder={"Ancien mot de passe"}
                    error={errors.oldPassword}
                    value={oldPassword}
                    onChange={(value) => setOldPassword(value)}
                />
                <CustomInput
                    label={"Nouveau mot de passe"}
                    type={"password"}
                    placeholder={"Nouveau mot de passe"}
                    error={errors.newPassword}
                    value={newPassword}
                    onChange={(value) => setNewPassword(value)}
                />
                <CustomInput
                    label={"Confirmation du nouveau mot de passe"}
                    type={"password"}
                    placeholder={"Confirmation du nouveau mot de passe"}
                    error={errors.newPasswordConfirmation}
                    value={newPasswordConfirmation}
                    onChange={(value) => setNewPasswordConfirmation(value)}
                />
            </div>
            <div className="flex justify-content-between gap-2">
                <Button className="he-button--secondary-variant--xs w-5" onClick={props.onHide}>
                    Annuler
                </Button>
                <Button className="he-button--primary--xs w-7"
                        onClick={onUpdatePassword}
                        disabled={loading || oldPassword.length === 0 || newPassword.length === 0 || newPasswordConfirmation.length === 0}
                        loading={loading}>
                    Mettre à jour <i className="pi pi-save"/>
                </Button>
            </div>
        </CustomDialog>
    );
};
