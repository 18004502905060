import React, {useMemo, useState} from "react";
import {Document, Page, pdfjs} from 'react-pdf/dist/esm/entry.webpack5';
import styled from "styled-components";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {ResponsiveProps, useBreakpoint} from "@Utils/responsive.utils";

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

export const PdfViewer = React.memo<{ pdf?: Uint8Array, loading: boolean }>((props) => {

	const [zoomCoef, setZoomCoef] = useState<number>(0.7);
	const [renderedScale, setRenderedScale] = useState<number | null>(null);
	const handle = useFullScreenHandle();

	const isLoading = useMemo(() => renderedScale !== zoomCoef, [renderedScale, zoomCoef]);

	const onZoomIn = () => {
		setZoomCoef(coef => Math.min(2, coef + 0.1));
	}

	const onZoomOut = () => {
		setZoomCoef(coef => Math.max(0.5, coef - 0.1));
	}

	const onFullScreen = async () => {
		await handle.enter();
		setZoomCoef(1.5)
	}

	const responsive = useBreakpoint();

	const onExitFullscreen = async () => {
		await handle.exit();
		setZoomCoef(0.9)
	}

	return <Wrapper responsive={responsive} className="flex flex-column justify-content-center align-items-center"
	                style={{border: "1px solid #333"}}>
		{(props.loading || !props.pdf) ? <div>
			Chargement du document en cours
			<i className="pi pi-spin pi-spinner ml-2"/>
		</div> : <FullScreen handle={handle} className="w-full h-full relative">
			<PdfViewerHeader>
				<div className="flex align-items-center h-full px-2">
					<div className="p-1 cursor-pointer select-none flex align-items-center"
					     onClick={handle.active ? onExitFullscreen : onFullScreen}>
						<span className="material-symbols-outlined text-white">
							{!handle.active ? "fullscreen" : "fullscreen_exit"}
						</span>
					</div>
					<div className="ml-auto he-paragraph--medium gray-300 mr-4">
						{Math.round(100 * zoomCoef)} %
					</div>
					<div className="p-1 cursor-pointer select-none flex align-items-center mr-2" onClick={onZoomIn}>
					<span className="material-symbols-outlined text-white">
						zoom_in
					</span>
					</div>
					<div className="p-1 cursor-pointer select-none flex align-items-center" onClick={onZoomOut}>
					<span className="material-symbols-outlined text-white">
						zoom_out
					</span>
					</div>
				</div>
			</PdfViewerHeader>
			<PdfViewerContainer height={handle.active ? "calc(100% - 36px)" : "400px"}>
				<Document
					file={{data: props.pdf}}
					loading="Chargement en cours"
					className="relative"
				>
					<Watermark>PREVISUALISATION</Watermark>
					{isLoading && renderedScale ? (
						<Page
							key={"@" + renderedScale}
							pageNumber={1}
							scale={renderedScale}
							className="p-4 flex justify-content-center"
						/>
					) : null}
					<Page key={"@" + zoomCoef}
					      pageNumber={1}
					      scale={zoomCoef}
					      onRenderSuccess={() => {
						      setRenderedScale(zoomCoef);
					      }}
					      className="p-4 flex justify-content-center"
					/>
				</Document>
			</PdfViewerContainer>
		</FullScreen>}
	</Wrapper>
}, (prev, next) => {
	return (prev.pdf?.length === next.pdf?.length) || (prev.loading !== next.loading);
})

const Wrapper = styled.div<ResponsiveProps>`
  width: ${props => (props.responsive.isDesktop || props.responsive.small) ? "470px" : "100%"};
	min-width: ${props => (props.responsive.isDesktop || props.responsive.small) ? "470px" : "100%"};
  height: ${props => (props.responsive.isDesktop || props.responsive.small) ? "300px" : "250px"};

  iframe {
    border: none;
  }
`
const PdfViewerContainer = styled.div<{ height: string }>`
  height: calc(100% - 36px);
  width: 100%;
  overflow: auto;
  background: #ccc;
  
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  
`;
const PdfViewerHeader = styled.div`
  overflow: auto;
  width: 100%;
  height: 36px;
  background: #333333;
`;

const Watermark = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  font-size: 3em;
  color: rgba(68, 68, 68, 0.22);
  pointer-events: none;
  z-index: 1000;
`
