import React, { useMemo } from 'react';
import styled from 'styled-components';
import { VirtualMeeting } from '@Types/Session';
import { Avatar } from 'primereact/avatar';
import { isBetween, isGreater } from '@Utils/date.utils';
import { DateTime, Duration } from 'luxon';
import { Tag } from 'primereact/tag';
import { useNavigate, useParams } from 'react-router-dom';

const CardWrapper = styled.div`
    border: 1px solid #eaecf0;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
`;

export type VirtualClassCardProps = {
    virtual_class: VirtualMeeting;
};
export const VirtualClassCard: React.FC<VirtualClassCardProps> = (props) => {
    const navigate = useNavigate();
    const params = useParams<{ session_id: string }>();

    const onCardClick = () => {
        navigate(`/session/${params.session_id}/virtualroom/${props.virtual_class.virtual_class_id}`, {
            state: props.virtual_class,
        });
    };

    const state = useMemo(() => {
        return {
            isBetween:
                props.virtual_class.date &&
                isBetween(
                    DateTime.now(),
                    props.virtual_class.date,
                    DateTime.fromISO(props.virtual_class.date).plus({ minutes: props.virtual_class.duration })
                ),
            isGreater:
                props.virtual_class.date &&
                !props.virtual_class.replay_url &&
                isGreater(
                    DateTime.now(),
                    DateTime.fromISO(props.virtual_class.date).plus({
                        minutes: (props.virtual_class.duration ?? 0) + 1,
                    })
                ),
            hasReplay: props.virtual_class.replay_url,
        };
    }, [props.virtual_class]);

    return (
        <CardWrapper className="w-full h-full bg-white p-4 flex flex-column" onClick={onCardClick}>
            <div className="w-full flex align-items-center">
                <Avatar icon="pi pi-desktop" size="normal" shape="circle" className="mr-3" />
                {state.isBetween && <Tag value="EN DIRECT" severity="danger" />}
                {state.isGreater && <Tag value="TERMINEE" severity="warning" />}
                {state.hasReplay && <Tag value="REDIFFUSION DISPONIBLE" severity="success" />}
            </div>
            {props.virtual_class.date ? (
                <div className="mt-auto">
                    <div className="he-header--h3 gray-900">
                        {DateTime.fromISO(props.virtual_class.date).toFormat('DDD')}
                    </div>
                    <div className="he-paragraph--regular gray-400 mt-1">
                        <i className="pi pi-clock mr-2" />
                        {DateTime.fromISO(props.virtual_class.date).toFormat('HH:mm')}
                        &nbsp;• Durée :{' '}
                        {Duration.fromObject({ minutes: props.virtual_class.duration }).toFormat('hh:mm:ss')}
                    </div>
                </div>
            ) : (
                <div className="mt-3 he-header--h3 gray-900">Aucune date programmée</div>
            )}
        </CardWrapper>
    );
};
