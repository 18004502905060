import {MessageExtended} from "@Types/Message";
import {classNames} from "primereact/utils";
import {useUser} from "@Hooks/firebase";
import {DateTime} from "luxon";
import {Button} from "primereact/button";
import {Line} from "@Components/Line";
import {firestore} from "@Utils/config/firebase";
import React, {Fragment, useRef} from "react";
import {arrayRemove, arrayUnion} from "firebase/firestore";
import {Message} from "./components/Message";
import {useBreakpoint} from "@Utils/responsive.utils";

type Props = {
    total: number;
    messages: MessageExtended[];
    formation_id: string;
    onLoadMore: () => void;
    onReply: (message: MessageExtended) => void;
    order: "asc" | "desc" | "likes";
};
export const MessageList: React.FC<Props> = (props) => {

    const {meta} = useUser(true);

    const responsive = useBreakpoint();

    const refs = useRef<{
        [key: string]: HTMLDivElement | null
    }>({});

    const formatSeparatorDate = (date: string) => {
        const dateTime = DateTime.fromISO(date).setLocale("fr");
        if (dateTime.hasSame(DateTime.now(), "day")) {
            return "Aujourd'hui";
        }
        if (dateTime.hasSame(DateTime.now().minus({day: 1}), "day")) {
            return "Hier";
        }
        if (dateTime.hasSame(DateTime.now(), "week")) {
            return dateTime.toFormat("cccc");
        }
        return dateTime.toFormat("cccc dd LLLL yyyy");
    }
    const onLike = (message: MessageExtended) => {
        firestore.collection(`formations/${props.formation_id}/messages`).update(message.message_id, {
            likes: message.likes.includes(meta.user_id) ? arrayRemove(meta.user_id) : arrayUnion(meta.user_id)
        });
    }

    const onDelete = (message: MessageExtended) => {
        firestore.collection(`formations/${props.formation_id}/messages`).delete(message.message_id);
    }

    return (
        <div className={classNames(props.order === "desc" ? "flex-column-reverse" : "flex-column", "flex w-full flex-1 overflow-auto p-3 relative")}>
            {props.messages.length === 0 && <div className="he-paragraph--regular gray-400 text-center w-full">Aucun message</div>}
            {props.messages.map((m, i, arr) => {

                const isMine = m.user_id === meta.user_id;
                const previous = arr[i-1], next = arr[i+1];
                const nextDaySame = next && DateTime.fromISO(m.created_at).hasSame(DateTime.fromISO(next.created_at), "day");
                const previousDaySame = previous && DateTime.fromISO(m.created_at).hasSame(DateTime.fromISO(previous.created_at), "day");

                let showTime = props.order === "likes";
                let showName = true;

                if (props.order === "asc") {
                    // Plus ancien
                    if (next)
                        showTime = next.user_id !== m.user_id || !DateTime.fromISO(m.created_at).hasSame(DateTime.fromISO(next.created_at), "minute");
                    if (previous)
                        showName = m.username !== previous.username || !previousDaySame;
                } else if (props.order === "desc") {
                    // Plus récent
                    if (previous)
                        showTime = previous.user_id !== m.user_id || !DateTime.fromISO(m.created_at).hasSame(DateTime.fromISO(previous.created_at), "minute");
                    if (next)
                        showName = m.username !== next.username || !nextDaySame;
                }

                const isMentionned = m.mentions?.some(m => m.user_id === meta.user_id);
                const isReply = m.reply?.user_id === meta.user_id && m.user_id !== meta.user_id;

                return <Fragment key={m.message_id}>
                    { props.order === "asc" && i > 0 && (!previousDaySame || i === arr.length - 1 ) && <div className="flex align-items-center gap-3">
                        <Line height={1} style={{flex: 1, width: "auto"}} className="bg-gray-200" />
                        <div className="he-paragraph--regular gray-400 w-max flex-shrink-1">{formatSeparatorDate(previous.created_at)}</div>
                        <Line height={1} style={{flex: 1, width: "auto"}} className="bg-gray-200" />
                    </div>}
                    <Message
                        messages={props.messages}
                        ref={r => {
                            refs.current[m.message_id] = r;
                        }}
                        onReplyClick={() => m.replyTo && refs.current[m.replyTo]?.scrollIntoView({behavior: "smooth"})}
                        m={m}
                        i={i}
                        showName={showName}
                        showTime={showTime}
                        isMentionned={isMentionned}
                        isReply={isReply}
                        responsive={responsive}
                        meta={meta}
                        isMine={isMine}
                        onLike={onLike}
                        onDelete={onDelete}
                        onReply={props.onReply}
                    />
                    { props.order === "desc" && i > 0 && (!nextDaySame || i === arr.length - 1 ) && <div className="flex align-items-center gap-3">
                        <Line height={1} style={{flex: 1, width: "auto"}} className="bg-gray-200" />
                        <div className="he-paragraph--regular gray-400 w-max flex-shrink-1">{formatSeparatorDate(m.created_at)}</div>
                        <Line height={1} style={{flex: 1, width: "auto"}} className="bg-gray-200" />
                    </div>}
                </Fragment>
            })}
            { props.messages.length < props.total && <Button className="he-button--secondary-variant-nf--xs mb-4" onClick={props.onLoadMore}>
                Voir plus de messages <i className="pi pi-clock"/>
            </Button> }
        </div>
    );
};

