export const downloadUint8 = function (data: Uint8Array, fileName: string, mimeType: string) {

	const blob = new Blob([new Uint8Array(Object.values(data))], {
		type: mimeType
	});

	const url = URL.createObjectURL(blob);

	const a = document.createElement("a");
	a.download = fileName;
	a.href = url;
	document.body.appendChild(a);
	a.click();
	a.remove();

	setTimeout(() => {
		return URL.revokeObjectURL(url);
	}, 1000);
}
