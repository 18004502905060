import * as React from 'react';
import { useMemo } from 'react';
import { Theme } from '@Types/Theme';
import { sortBy, uniq } from 'lodash';
import { Chart } from 'primereact/chart';
import { Module } from '@Types/Module';

interface Props {
    module?: Module;
    themes: Theme[];
    results: {
        [key: string]: number;
    }[];
}
export const ResultChart: React.FC<Props> = (props) => {
    /* STATES */
    const chartData = useMemo(() => {
        const themes = props.results
            .map((result) => Object.keys(result))
            .flat()
            .map((k) => props.themes.find((t) => t.theme_id === k))
            .filter(Boolean) as Theme[];
        const sorted = uniq(sortBy(themes, 'label'));

        return {
            datasets: props.results.map((result, i) => {
                const res = {
                    data: sorted.map((t) => result[t.theme_id] ?? 0),
                    backgroundColor: sorted.map((t) => t.color + '99'),
                    label: props.module ? `Module : ${props.module.title}` : 'Résultat',
                };
                return res;
            }),
            labels: sorted.map((t) => t.label),
        };
    }, [props.module, props.results, props.themes]);
    /* EFFECTS */

    /* EVENT HANDLERS */

    /* RENDERING */

    const plugin = {
        id: 'custom_canvas_background_color',
        beforeDraw: (chart: any) => {
            const { ctx } = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        },
    };

    return (
        <div className="mx-auto" style={{ maxWidth: 450 }}>
            <Chart
                type="polarArea"
                data={chartData}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        position: 'bottom',
                    },
                    scale: {
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 100,
                            z: 2,
                        },
                        grid: {
                            z: 1,
                        },
                    },
                }}
                plugins={[plugin]}
            />
        </div>
    );
};
