import React, { useState } from 'react';
import { Activity, ActivityHistory, TCSActivity } from '@Types/Activity';
import { Line } from '@Components/Line';
import { classNames } from 'primereact/utils';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Module } from '@Types/Module';
import { Button } from 'primereact/button';
import { TcsDialog } from '@Pages/Home/Session/pages/TCSResults/TCSSection/components/TcsDialog';
import { Theme } from '@Types/Theme';
import { getTextColor } from '@Utils/color.utils';

const colors = ['#EA2828', '#EAA828', '#dedc15', '#A3CC2E', '#2ECC71'];

export type TCSScoreLineProps = {
    module: Module;
    activity: Activity & TCSActivity;
    score: number;
    answer: ActivityHistory;
    session_id: string;
    theme?: Theme;
};
export const TCSScoreLine: React.FC<TCSScoreLineProps> = (props) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const [showActivity, setShowActivity] = useState<boolean>(false);

    const scoreDetail = props.activity.hypothesis.map((h, i) => {
        const answer = props.answer.answers?.[i];
        const max = Math.max(...h.answers);
        const score = answer !== undefined ? h.answers[answer] / max : 0;

        return {
            index: i,
            score: score,
            answer: answer,
            hypothesis: h,
        };
    });

    const getColor = (score: number) => {
        const index = Math.floor((score / 100) * 5);
        return colors[index >= colors.length ? colors.length - 1 : index];
    };

    return (
        <div>
            {showActivity && props.answer.answers && (
                <TcsDialog
                    onHide={() => setShowActivity(false)}
                    activity={props.activity}
                    answers={props.answer.answers}
                    activityHistory={props.answer}
                />
            )}
            <div className="mt-3 flex justify-content-start gap-3 align-items-center">
                <i
                    className={classNames(expanded ? 'pi pi-angle-down' : 'pi pi-angle-right', 'p-1 cursor-pointer')}
                    onClick={() => setExpanded((p) => !p)}
                />
                <div className="he-paragraph--regular gray-800 flex gap-3 align-items-center">
                    {props.activity.title}
                    {props.answer && (
                        <Button className="he-button--primary-nfb--xs" onClick={() => setShowActivity(true)}>
                            Voir mes réponses
                        </Button>
                    )}
                    {props.theme && (
                        <div className="he-paragraph--regular">
                            <ThemeTag background={props.theme.color}>{props.theme.label}</ThemeTag>
                        </div>
                    )}
                </div>
                <div className="he-paragraph--small gray-500 ml-auto">
                    <span className="he-paragraph--medium" style={{ color: getColor(props.score) }}>
                        {new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(props.score)}
                    </span>{' '}
                    / 100
                </div>
            </div>
            <ExpandedContainer
                layout
                transition={{
                    type: 'spring',
                    stiffness: 500,
                    damping: 30,
                }}
                className={classNames('overflow-hidden', expanded ? 'expanded' : 'closed')}
            >
                {scoreDetail.map((s) => {
                    const totalHypothesis = s.hypothesis.answers.reduce((a, b) => a + b, 0);
                    return (
                        <div key={s.index} className="flex flex-column w-full gap-2 p-2">
                            <div className="flex w-full justify-content-between align-items-center">
                                <div className="he-paragraph--regular gray-600">Hypothèse {s.index + 1}</div>
                                <div className="he-paragraph--small gray-500">
                                    {s.answer != undefined ? (
                                        <>
                                            Vous avez répondu comme <strong>{s.hypothesis.answers[s.answer]}</strong>{' '}
                                            expert{s.hypothesis.answers[s.answer] > 1 ? 's' : ''} sur{' '}
                                            <strong>{totalHypothesis}</strong>
                                        </>
                                    ) : (
                                        <>Vous n'avez pas répondu</>
                                    )}
                                </div>
                            </div>
                            <Line height={1} className="mb-2 bg-gray-200" />
                        </div>
                    );
                })}
            </ExpandedContainer>
            <Line height={1} className="mt-2 bg-gray-200" />
        </div>
    );
};

const ExpandedContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;

    &.expanded {
        padding: 8px;
        max-height: 1000px;
    }

    &.closed {
        max-height: 0;
    }
`;

const ThemeTag = styled.div<{
    background?: string;
    color?: string;
}>`
    text-align: center;
    padding: 6px 8px;
    border-radius: 6px;
    background: ${(props) => props.background || '#c8c8c8'};
    color: ${(props) => props.color || getTextColor(props.background || '#c8c8c8')};
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    font-family: 'roboto';
`;
