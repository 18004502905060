import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {Activity, ActivityHistory, FreeTextActivity as FreeTextActivityModel} from "@Types/Activity";
import {UserProgressItem} from "@Types/User";
import {Resource} from "@Types/Resource";
import {useParams} from "react-router-dom";
import {useApi} from "@Hooks/api";
import {usePromise} from '@Hooks/promise';
import {Image} from 'primereact/image';
import {getUrlForResource} from "@Utils/resource.utils";
import {useBreakpoint} from "@Utils/responsive.utils";
import ReactPlayer from "react-player";
import {classNames} from "primereact/utils";
import {ReadOnlyEditor} from "@Components/ReadOnlyEditor";
import {Galleria} from "primereact/galleria";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {DisplayAnswer} from "@Pages/Home/Session/pages/Module/pages/Activity/FreeTextActivity/DisplayAnswer";

interface Props {
    activity: Activity & FreeTextActivityModel;
    index: number;
    progress: UserProgressItem[];
    next: any;
}
export const FreeTextActivity: React.FC<Props> = (props) => {

    const [answer, setAnswer] = useState<string>("");
    const [images, setImages] = useState<(null | Resource | undefined)[]>([]);
    const [videos, setVideos] = useState<Array<null | Resource | undefined>>([]);

    const { session_id, module_id, activity_id } = useParams<{
        session_id: string;
        module_id: string;
        activity_id: string;
    }>();
    const api = useApi();

    const ac = useMemo<ActivityHistory | undefined>(() => {
        if (!(session_id && module_id && activity_id)) return undefined;
        return props.progress?.find(
            (p) => p.session_id === session_id && p.module_id === module_id && p.activity_id === activity_id
        )?.activity;
    }, [props.progress, session_id, module_id, activity_id]);
    useEffect(() => {
        if (activity_id && session_id && module_id) {
            if (ac?.done && ac.answer) {
                setAnswer(ac.answer ?? "Aucune réponse enregistrée");
                props.next.setNextStatus(true);
            } else {
                props.next.setNextStatus(false);
            }
        }
    }, [ac]);

    useEffect(() => {
        if (activity_id && module_id && session_id) {
            api.session_call_startActivity({
                activity_id,
                module_id,
                session_id,
            })
                .then(() => {
                    props.next.onNext(undefined);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
        return () => {
            setAnswer("");
        };
    }, [module_id, session_id, activity_id]);

    const [onValidate, loading] = usePromise(
        async (answer: string ,activity_id: string, session_id: string, module_id: string) => {
            if (!(session_id && module_id && activity_id)) return;

            const res = await api.session_call_checkAnswer({
                activity_id,
                module_id,
                session_id,
                answer
            });

            if (res.result !== 'ok') throw new Error(res.result);
        }
    );

    const itemTemplate = (item: Resource) => {
        return (
            <Image
                preview
                src={item?.resource_url}
                alt={item?.resource_name}
                width="auto"
                height={'200px'}
                className="pb-5"
            />
        );
    };

    const thumbnailTemplate = (item: Resource) => {
        return <img src={item.resource_url} alt={item.resource_name} style={{ width: 'auto', height: '70px' }} />;
    };
    useEffect(() => {
        Promise.all(
            props.activity.cover_image_url.map(async (i?: string | null) => {
                if (i) {
                    return getUrlForResource(i);
                }
                return null;
            })
        ).then((imgs) => {
            setImages(imgs.filter((e) => !!e));
        });
        Promise.all(props.activity.cover_video_url.map(async (i?: string | null) => {
            if (i) {
                return getUrlForResource(i);
            }
            return null;
        })).then(vids => {
            setVideos(vids.filter(Boolean));
        })
    }, [props.activity]);

    const responsive = useBreakpoint();

    const responsiveGalleriaOptions = [
        {
            breakpoint: '768px',
            numVisible: 2,
        },
        {
            breakpoint: '450px',
            numVisible: 1,
        },
    ];
    const resources = useMemo(() => {
        return videos.map(v => {
            if (v != null) {
                return <ReactPlayer
                    progressInterval={30_000}
                    config={{
                        file: {
                            attributes: {
                                controlsList: "nodownload"
                            }
                        }
                    }}
                    url={v.resource_url}
                    height={"150px"}
                    width={"auto"}
                    controls
                />
            }
            return null;
        })
    }, [videos])

    return (
        <div className="w-full h-full">
            <div className={classNames(responsive.isDesktop ? 'he-header--h1' : 'he-header--h1-small', 'gray-900')}>
                {props.activity.title}
            </div>
            <div className={classNames(responsive.isDesktop ? 'quill-custom' : 'quill-custom-mobile', 'mt-4')}>
                <ReadOnlyEditor content={props.activity.question || ''} />
            </div>
            {images.length > 0 && (
                <div className="bg-gray-600 pt-5 mt-3">
                    <Galleria
                        circular
                        responsiveOptions={responsiveGalleriaOptions}
                        value={images}
                        numVisible={5}
                        item={itemTemplate}
                        style={{ height: '100%' }}
                        thumbnail={thumbnailTemplate}
                    />
                </div>
            )}
            {
                resources.length > 0 && <div>
                    <div className="he-paragraph--medium gray-900 my-4">Ressources vidéos</div>
                    <div className="flex gap-4 flex-wrap">
                        {resources}
                    </div>
                </div>
            }
            <div className="mt-5">
                {
                    !ac?.done ?
                        <div>
                            <div className="he-paragraph--medium gray-900 mb-2">Votre réponse</div>
                            <InputTextarea
                                value={answer}
                                onChange={(e) => setAnswer(e.target.value)}
                                className="he-input--textarea"
                                placeholder="Saisissez votre réponse ici"
                                style={{ height: 200 }}
                            />
                        </div>
                        :
                        <DisplayAnswer activity={props.activity} answer={ac}/>
                }
            </div>

            {!ac?.done && activity_id && session_id && module_id && (
                <Button
                    className="he-button--success--md mt-2"
                    loading={
                        loading ||
                        (answer.length !== 0  &&
                            !props.progress?.find(
                                (p) =>
                                    p.session_id === session_id &&
                                    p.module_id === module_id &&
                                    p.activity_id === activity_id
                            )?.activity)
                    }
                    disabled={
                        answer.length === 0 ||
                        !props.progress?.find(
                            (p) =>
                                p.session_id === session_id &&
                                p.module_id === module_id &&
                                p.activity_id === activity_id
                        )?.activity
                    }
                    onClick={() => onValidate(answer, activity_id, session_id, module_id)}
                >
                    <i className="pi pi-check" />
                    Valider la réponse
                </Button>
            )}
        </div>
    );

}
