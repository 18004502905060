import {initializeApp} from "firebase/app";
import {FirebaseAuth} from "./firebase/auth";
import {FirebaseStorage} from "./firebase/storage";
import {FirebaseFirestore} from "./firebase/collection";
import {FirebaseFunctions} from "./firebase/functions";
import {User} from "@Types/User";
import {Maintenance} from "@Types/maintenance";
import {env} from "../../env";
import {RealtimeDatabase} from "@Utils/config/firebase/realtimedb";

export const appFirebase = initializeApp(env.firebaseEnv);

export const auth = new FirebaseAuth(appFirebase, env.isDev ? {url: "http://localhost:9099"} : undefined);

export const functions = new FirebaseFunctions(appFirebase, env.functionRegion, (env.useFunctionEmulator || env.isDev) ? {
	host: 'localhost',
	port: 5001
} : undefined).getFunctions();

export const storage = new FirebaseStorage(appFirebase, env.isDev ? {
	host: 'localhost',
	port: 9199
} : undefined);

export const firestore = new FirebaseFirestore(appFirebase, env.isDev ? {
	host: 'localhost',
	port: 8080
} : undefined);

export const rtdb = new RealtimeDatabase(appFirebase, env.isDev ? {
	host: "localhost",
	port: 9000
} : undefined);


export const usersRef = firestore.collection<User>("users");
export const maintenanceRef = firestore.collection<Maintenance.Maintenance>("maintenances");
export const templatesRef = firestore.collection<{content: string}>("templates");

