import {FirebaseApp} from "@firebase/app";
import {connectFunctionsEmulator,getFunctions as getFunctionsFirebase ,Functions} from "firebase/functions";

export class FirebaseFunctions {
    private functions: Functions

    constructor(app: FirebaseApp, regionOrCustomDomain: string, emulator?: {
        host: string,
        port: number
    }) {
        this.functions = getFunctionsFirebase(app,regionOrCustomDomain);
        if (emulator) {
            connectFunctionsEmulator(this.functions, emulator.host, emulator.port);
        }
    }

    public getFunctions() {
        return this.functions
    }

}
