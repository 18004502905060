
export const firebaseConfigProd = {
	apiKey: "AIzaSyCE_32diccdUjXsmnkcXSJXZI2IqkNHERk",
	authDomain: "health-events-dev.firebaseapp.com",
	projectId: "health-events-dev",
	storageBucket: "health-events-dev.appspot.com",
	messagingSenderId: "204933489081",
	appId: "1:204933489081:web:9e7ae6088e6ea14d8ad668"
};

const firebaseEnv = firebaseConfigProd;

const isProd = true;

const useFunctionEmulator = false;

const host = isProd ? "https://lms.healthevents.fr" : "localhost:3000";

export const env = {
	firebaseEnv,
	host,
	isFirebaseProductionProjet: isProd,
	useFunctionEmulator,
	debugInfo: false,
	maintenanceKey: "maintenance",
	isDev: !isProd,
	functionRegion: "europe-west3",
};
