import React, {useEffect} from "react";
import {Activity, CourseActivity as CourseActivityModel} from "@Types/Activity";
import {useParams} from "react-router-dom";
import {useApi} from "@Hooks/api";
import {getUrlForResource} from "@Utils/resource.utils";
import {ReadOnlyEditor} from "@Components/ReadOnlyEditor";

export type CourseActivityProps = {
	activity: Activity & CourseActivityModel,
	index: number,
	next: any
}
export const CourseActivity: React.FC<CourseActivityProps> = (props) => {

	const {
		session_id,
		module_id,
		activity_id
	} = useParams<{ session_id: string, module_id: string, activity_id: string }>();
	const api = useApi();

	useEffect(() => {
		if (activity_id && session_id && module_id) {
			api
				.session_call_startActivity({
					activity_id,
					module_id,
					session_id,
				})
				.then((res) => {
					if (res.result !== "ok") throw new Error(res.result);
					props.next.onNext(async () => {
						await api.session_call_validateActivity({
							activity_id,
							module_id,
							session_id,
						});
					});
				})
				.finally(() => {
					props.next.setNextStatus(true);
				});
		}

	}, [session_id, activity_id, module_id]);

	const onDownload = async () => {
		const res = await getUrlForResource(props.activity.resource_pdf_url);
		const a = document.createElement('a');
		if (res?.resource_url) {
			a.href = res.resource_url;
			a.download = res.resource_name;
			a.target = "_blank"
			a.click();

		}
	}

	return (
		<div className="h-full w-full">
			<div className="he-header--h1 gray-900">
				{props.activity.title}
			</div>
			{props.activity.resource_pdf_url &&
                <div className="he-paragraph--regular primary-100 mt-4 cursor-pointer" onClick={onDownload}>
                    <i className="pi pi-download mr-2"/>
                    Télécharger le support de cours
                </div>}
			<div className="quill-custom mt-4">
				<ReadOnlyEditor content={props.activity.content || null}/>
			</div>
		</div>
	)
}
