import { SessionExtended } from '@Types/Session';
import { Module } from '@Types/Module';
import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react';
import { useApi } from '@Hooks/api';
import { FirebaseContext } from '@Context/FirebaseContext';
import {SessionContext} from "@Context/Session.context";
import {canByPass} from "@Utils/participant.utils";

type ModuleEndDialogProps = {
    session: SessionExtended<false>;
    module: Module;
    onChecked: (isModuleValid: boolean) => void;
}
export const ModuleCheckDialog: React.FC<ModuleEndDialogProps> = (props) => {

    const onRefresh = () => {
        window.location.reload();
    }

    const [checking, setChecking] = useState(true);

    const [isValid, setIsValid] = useState<boolean>(false);

    const api = useApi();

    const fbCtx = useContext(FirebaseContext);
    const {participant} = useContext(SessionContext);

    async function check(session_id: string, module: Module) {
        setChecking(true);

        // BYPASS
        if (participant && canByPass(props.session, participant)) {
            setIsValid(true);
            setChecking(false);
            props.onChecked(true);
            return;
        }

        const res = await api.session_call_checkModule({
            session_id: props.session.session_id,
            module_id: props.module.module_id
        });

        if (res.result === "ok") {
            setIsValid(true);
            props.onChecked(true);
        } else {
            fbCtx?.logEvent("FailedModuleCheck", {
                session_id,
                module_id: module.module_id,
                errors: res.steps
            })
            console.error(res.steps);
            setIsValid(false);
        }

        setChecking(false);
    }
    useEffect(() => {
        if (props.session && props.module) {
            check(props.session.session_id, props.module)
        }
    }, []);

    return (
        checking ?
        <div className='w-full h-full py-4 flex flex-column gap-4 justify-content-center align-items-center'>
            <div className='he-header--h1 text-center'>
                Vérification du module en cours
            </div>
            <div className={"w-min"}>
                <i className='pi pi-spin pi-spinner' style={{ fontSize: '3rem' }} />
            </div>
            <div className='he-paragraph--regular'>
                Veuillez patienter...
            </div>
        </div> :
            !isValid ? <div className={"w-full h-full gap-4 flex flex-column justify-content-center align-items-center"}>
                <div className='he-header--h2'>
                    Une erreur a été rencontrée
                </div>
                <div className='he-paragraph--regular-16 text-center'>
                    Un problème est survenu lors de la vérification du module, <br /> veuillez rafraîchir la page.
                </div>
                <div className='flex gap-4'>
                    <Button className="he-button--primary--md" onClick={onRefresh}>
                        Rafraîchir la page <i className={"pi pi-refresh"}/>
                    </Button>
                </div>
            </div> :  <div className={"w-full h-full gap-4 flex flex-column justify-content-center align-items-center"}>
                <div className='he-header--h2'>
                    Module validé !
                </div>
                <div className='he-paragraph--regular-16 text-center'>
                    Vous allez être redirigé vers le module suivant
                </div>
            </div>
    );
};
