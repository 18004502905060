import {BreadItemModel} from "@Components/CustomBreadCrumb";
import {useNavigate} from "react-router-dom";
import {classNames} from "primereact/utils";
import {useBread} from "@Hooks/breadcrumbs";

export type BreadItemProps = {
	item: BreadItemModel,
	shouldReduce?: boolean,
	isLast?: boolean,
};
export const BreadItem: React.FC<BreadItemProps> = (props) => {

	const navigate = useNavigate();
	const bread = useBread();
	const handleNavigation = () => {
		if (props.item.url && !props.isLast) {
			navigate(props.item.url);
			bread.updateTree(prev => {
				const indexItem = prev.findIndex(i => i.id === props.item.id);

				if (indexItem === -1) return prev;

				return prev.filter((_,i) => i <= indexItem);
			})
		}
	}

    return (
        <div className={classNames("BreadItem select-none ", props.isLast ? "he-paragraph--medium cursor-default text-white" : "he-paragraph--regular cursor-pointer gray-200" )} onClick={handleNavigation} >
	        {props.item.icon && <i className={classNames('mr-2', props.item.icon)}/> }
	        {props.item.label && <span>{(props.item.label.length > 20 && props.shouldReduce) ? props.item.label.substring(0,20) + "..." : props.item.label}</span>}
        </div>
    )
}
