import { useEffect, useState } from "react";

export const useIsPageVisible = () => {
    const [isPageInFocus, setIsPageInFocus] = useState<boolean>(true);

    useEffect(() => {
        const onChange = () => {
            if (document.visibilityState === "visible") {
                setIsPageInFocus(true);
            } else {
                setIsPageInFocus(false);
            }
        };

        document.addEventListener("visibilitychange", onChange);

        return () => {
            document.removeEventListener("visibilitychange", onChange);
        }
    }, [])

    return isPageInFocus;
}
