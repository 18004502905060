export const darkenHexa = (hex: string, percent: number) => {

    const factor = 1 - percent / 100;
    const num = parseInt(hex.replace("#", ""), 16);
    const r = num >> 16;
    const g = (num >> 8) & 255;
    const b = num & 255;
    const newR = Math.round(r * factor);
    const newG = Math.round(g * factor);
    const newB = Math.round(b * factor);
    return `#${(newR << 16 | newG << 8 | newB).toString(16)}99`;
}

function hexToRgb(hex: string) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function relativeLuminance(rgb: { r: number, g: number, b: number }) {
    let sRGB = [rgb.r / 255, rgb.g / 255, rgb.b / 255];
    for (let i = 0; i < sRGB.length; i++) {
        sRGB[i] = (sRGB[i] <= 0.03928) ? sRGB[i] / 12.92 : Math.pow(((sRGB[i] + 0.055) / 1.055), 2.4);
    }
    return 0.2126 * sRGB[0] + 0.7152 * sRGB[1] + 0.0722 * sRGB[2];
}

export function getTextColor(hexColor: string) {
    let rgb = hexToRgb(hexColor);
    if (!rgb) return 'black';
    let luminance = relativeLuminance(rgb);
    return (luminance > 0.5) ? 'black' : 'white';
}
