import _ from "lodash";

export const stringDebug = (str: string, lineSize: number = 16) => {
    const encoder = new TextEncoder();
    const decoder = new TextDecoder();

    const hexa = [...encoder.encode(str)];

    const res = _.chunk(hexa, lineSize).map(line => {
        return _.chunk(line, 2).map(byteArr => byteArr.map(byte => byte.toString(16).padStart(2, '0')).join('')).join(' ') + " | " + decoder.decode(Uint8Array.from(line));
    }).join('\n')

    return res;
}


export const sanitizeString = (str: string) => {
    return str.replaceAll(/\s/g, ' ').normalize().trim();
}
