import React, { useState } from 'react';
import styled from 'styled-components';
import { CustomInput } from '@Components/CustomInput';
import { useFormik } from 'formik';
import { useUser } from '@Hooks/firebase';
import { ResponsiveProps, useBreakpoint } from '@Utils/responsive.utils';
import { UpdatePasswordDialog } from '@Pages/Home/Profile/UpdatePasswordDialog';

const Container = styled.div<ResponsiveProps>`
  padding: ${props => props.responsive.isDesktop || props.responsive.small ? "64px 0 64px 150px" : "8px"};
  width: ${props => props.responsive.isDesktop || props.responsive.small ? "750px" : "100%"};
  background: ${props => props.responsive.isDesktop || props.responsive.small ? "transparent" : "white"} ;
  height: 100%;
`

const ProfileContainer = styled.div<ResponsiveProps>`
  display: grid;
  grid-template-columns: ${props => (props.responsive.phone || props.responsive.tablet) ? "1fr" : "1fr 1fr"};
  grid-gap: 14px;
  grid-template-rows: ${props => (props.responsive.phone || props.responsive.tablet) ? "repeat(7, minmax(0, auto))" : "repeat(5, minmax(0, auto))"};
  padding: 28px;
  background: #FFFFFF;
  border-radius: 10px;
`

const Half = styled.div`
  grid-column: span 1;
`

const Full = styled.div<ResponsiveProps>`
  grid-column: span ${props => props.responsive.phone ? 1 : 2};
`

export type ProfileProps = Record<string, never>
export const Profile: React.FC<ProfileProps> = () => {

	const user = useUser(true);

	const userFormik = useFormik({
		initialValues: user.meta,
		enableReinitialize: true,
		onSubmit: () => {
		}
	});

	// const api = useApi();
	//
	// const [onUpdatePassword] = usePromise(async () => {
	// 	await api.user_call_reset({
	// 		email: user.meta.email
	// 	});
	// }, {
	// 	pending: "Envoi du mail de réinitialisation en cours ...",
	// 	success: "Un mail de réinitialisation de mot de passe vous a été envoyé"
	// })

	const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] = useState<boolean>(false);

	const responsive = useBreakpoint();

	return (
		<Container responsive={responsive}>
			{
				showUpdatePasswordDialog &&
				<UpdatePasswordDialog
					onHide={() => setShowUpdatePasswordDialog(false)}
				/>
			}
			<div className="he-header--h1 gray-900">
				Mon profil
			</div>
			<div className="he-paragraph--regular gray-400 mt-2">
				Retrouvez toutes les informations de votre profil
			</div>
			<ProfileContainer responsive={responsive} className="mt-4">
				<Half>
					<CustomInput label="Nom" field={"lastname"} formik={userFormik} disabled/>
				</Half>
				<Half>
					<CustomInput disabled label="Prénom" field={"firstname"} formik={userFormik}/>
				</Half>
				<Full responsive={responsive}>
					<CustomInput disabled label="Adresse mail" field={"email"} formik={userFormik}/>
				</Full>
				<Half>
					<CustomInput disabled label="numéro RPPS / ADELI" field={"RPPS"} formik={userFormik}/>
				</Half>
				<Half>
					<CustomInput disabled label="Numéro de téléphone" field={"phone"} formik={userFormik}/>
				</Half>
				<Half>
					<CustomInput disabled label="Profession" field={"job"} formik={userFormik}/>
				</Half>
				<Half>
					<CustomInput disabled label="Mode d'exercice" field={"exercise_mode"} formik={userFormik}/>
				</Half>
				<Full responsive={responsive}>
					<CustomInput disabled label="Adresse postale" field={"address"} formik={userFormik}/>
				</Full>
				<Full responsive={responsive}>
					<div className="cursor-pointer he-paragraph--regular no-underline secondary-100 mt-2" onClick={() => setShowUpdatePasswordDialog(true)}>
						<i className="pi pi-refresh mr-2"/>
						Modifier mon mot de passe
					</div>
				</Full>
				<Full responsive={responsive}>
					<div className="he-paragraph--small gray-400">
						<i className="pi pi-info-circle"/> Si ces informations sont erronées, contactez le support
					</div>
					<a href="mailto:contact@healthevents.fr"
					   className="block he-paragraph--regular no-underline secondary-100 mt-2">
						<i className="pi pi-envelope mr-2"/>
						Contacter le support
					</a>
				</Full>
			</ProfileContainer>
		</Container>
	)
}
