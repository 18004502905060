import React, {useContext, useMemo, useRef, useState} from "react";
import {Message} from "@Types/Message";
import {v4} from "uuid";
import {useUser} from "@Hooks/firebase";
import {DateTime} from "luxon";
import {Button} from "primereact/button";
import styled from "styled-components";
import {firestore} from "@Utils/config/firebase";
import {uuidToTag} from "@Pages/Home/Forum/utils/messages.utils";
import _ from "lodash";
import {InputTextarea} from "primereact/inputtextarea";
import {sanitizeString} from "@Utils/string.utils";
import {ForumNotification} from "@Types/Notification";
import {SessionContext} from "@Context/Session.context";

export type MessageInputBoxProps = {
    formation_id: string;
    messages: Message[];
    selectedMessage: Message | null;
    setSelectedMessage: (message: Message | null) => void;
    banList: string[];
}
export const MessageInputBox: React.FC<MessageInputBoxProps> = (props) => {

    const {meta} = useUser(true);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [mentions, setMentions] = useState<{username: string, user_id: string}[]>([]);
    const {session} = useContext(SessionContext);

    const [content, setContent] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const onSendMessage = async () => {
        setLoading(true);
        setError("");

        if (content.trim().length === 0) {
            setLoading(false);
            return;
        }

        const words = content.split(/\s|\n/);

        // check if message contains banned words
        const bannedWords = words.filter(w => props.banList.includes(w));
        if (bannedWords.length > 0) {
            setLoading(false);
            setError("Votre message contient des mots interdits");
            return;
        }

        const message: Message = {
            message_id: v4(),
            content: content,
            created_at: DateTime.now().setZone("Europe/Paris").toISO() || "",
            user_id: meta.user_id,
            updated_at: DateTime.now().setZone("Europe/Paris").toISO() || "",
            formation_id: props.formation_id,
            username: `${meta.firstname}.${meta.lastname.toUpperCase().charAt(0)}`,
            likes: [],
            mentions,
            replyTo: props.selectedMessage?.message_id || null
        };

        const notification: ForumNotification = {
            notification_id: v4(),
            message_id: message.message_id,
            created_at: message.created_at,
            status: "Non traité",
            user_id: meta.user_id,
            formation_id: props.formation_id,
            username: message.username,
            content: message.content,
            title: session?.formation.title || "",
            type: "message"
        };

        try {
            await firestore.collection(`formations/${props.formation_id}/messages`).set(message.message_id, message);
            await firestore.collection('notifications').set(notification.notification_id, notification);
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "38px";
        }
        setContent("");
        setMentions([]);
        props.setSelectedMessage(null);
    }

    const onEdit = (str: string) => {
        let content = str || '';
        setContent(content);
    }
    const onClickMention = (mention: {username: string, user_id: string}) => {
        if (mentions.findIndex(m => m.user_id === mention.user_id) !== -1) {
            setMentions(mentions.filter(m => m.user_id !== mention.user_id));
        } else {
            setMentions([...mentions, mention]);
        }
        const str = sanitizeString(content);

        // remove mention
        const index = str.lastIndexOf("@");
        const nextSpace = str.indexOf(" ", index);
        const term = str.slice(index+1, nextSpace === -1 ? str.length : nextSpace);
        const newContent = content.replace(`@${term} `, "");

        setContent(newContent);
    }

    const autoGrow: React.FormEventHandler<HTMLTextAreaElement> = (e) => {
        e.currentTarget.style.height = "38px";
        e.currentTarget.style.height = e.currentTarget.scrollHeight > 42 ? (e.currentTarget.scrollHeight + 4 + "px") : "38px";
    }

    const userList = _.uniqBy(props.messages.map(m => ({username: `${m.username}#${uuidToTag(m.user_id)}`, user_id: m.user_id})), "username");

    const showSuggestions = content.includes("@");
    const searchTerm = useMemo(() => {
        const str = sanitizeString(content);
        const index = str.lastIndexOf("@");
        const nextSpace = str.indexOf(" ", index);
        const term = str.slice(index+1, nextSpace === -1 ? str.length : nextSpace);
        return term;
    }, [content]);

    const suggestions = useMemo(() => {
        if (showSuggestions) {
            if (searchTerm.length === 0)
                return userList
                    .filter(m => m.user_id !== meta.user_id)
                    .filter(u => mentions.findIndex(m => m.user_id === u.user_id) === -1);
            return userList
                .filter(m => m.user_id !== meta.user_id)
                .filter(u => mentions.findIndex(m => m.user_id === u.user_id) === -1)
                .filter(u => u.username.toLowerCase().normalize().includes(searchTerm.toLowerCase()));
        } return [];
    }, [showSuggestions, userList, searchTerm, mentions, meta.user_id])

    return (
        <>
            {props.selectedMessage && <div className="w-full bg-primary-100 p-2 color-white flex justify-content-between">
                <div className="he-paragraph--regular flex gap-2 align-items-center">
                    <i className="pi pi-reply" style={{fontSize: 12}}/> Répondre à <span className="font-bold" style={{color: "#fff7a9"}}>{props.selectedMessage.username+"#"+uuidToTag(props.selectedMessage.user_id)}</span>
                </div>
                <i className="pi pi-times-circle cursor-pointer" onClick={() => props.setSelectedMessage(null)}/>
            </div>}
            <div className="flex flex-column justify-content-center gap-2 p-3 border-gray-200">
                {(showSuggestions || mentions.length > 0) && <div className="w-full flex gap-2 overflow-auto align-items-center">
                    {(showSuggestions || mentions.length > 0) &&
                        <span className="he-paragraph--regular gray-500">Mentions :</span>}
                    {mentions.length > 0 && mentions.map((m, i) => <CustomTag key={i} selected
                                                                              onClick={() => onClickMention(m)}><i
                        className="pi pi-at" style={{fontSize: 12}}/> {m.username}</CustomTag>)}
                    {showSuggestions && suggestions.map((m, i) => <CustomTag key={i}
                                                                             onClick={() => onClickMention(m)}><i
                        className="pi pi-at" style={{fontSize: 12}}/> {m.username}</CustomTag>)}
                </div>}
                {error && <div className="he-paragraph--small color-red">{error}</div>}
                <div className="flex gap-2 align-items-center">
                    <CustomTextArea
                        ref={textAreaRef}
                        onInput={autoGrow}
                        onChange={e => onEdit(e.target.value)}
                        tabIndex={0}
                        onKeyDown={e => {
                            if (e.key === "Enter" && e.ctrlKey) {
                                e.preventDefault();
                                onSendMessage();
                            }
                        }}
                        placeholder="Ecrivez votre message..."
                        value={content}
                        className={error ? "error" : ""}
                    />
                    <Button className="he-button--secondary-variant--md--rounded"
                            tabIndex={1}
                            onClick={onSendMessage}
                            style={{width: 40}}
                            loading={loading}
                            disabled={loading || content.length === 0}
                    >
                        <i className="pi pi-send"/>
                    </Button>
                </div>
            </div>
        </>
    )
}

const CustomTag = styled.div<{ selected?: boolean }>`
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 10px;
    padding: 6px 8px;
    border-radius: 14px;
    font-family: "roboto", "sans-serif";
    color: #383838;
    cursor: pointer;
    width: max-content;
    white-space: nowrap;


    &:hover {
        background: ${props => props.selected ? "#b1e1f3" : "#b1f3c5"};
    }

    background: ${props => props.selected ? "#dbf7ff" : "#dbffe6"};
`

const CustomTextArea = styled(InputTextarea)`
    &:empty:before {
        content: attr(placeholder);
        color: #ccc;
    }

    &.error {
        border: 1px solid #e85d32;

        &:focus,&:hover {
            outline: 1px solid #e85d32;
            border: 1px solid #e85d32;
        }
    }

    resize: none;
    cursor: text;
    outline: none;
    font-family: "roboto", "sans-serif";
    font-size: 16px;
    color: #383838;

    &:focus {
        outline: 1px solid #CEE3F8;
    }

    padding: 8px 16px;
    flex: 1;
    border: 1px solid #ececec;
    min-height: 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
`
