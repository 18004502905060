import {ApiClient} from "../../../models/Helpers";
import {httpsCallable} from "firebase/functions";
import {useMemo} from "react";
import {functions} from "@Utils/config/firebase";

export const useApi = () => {

	const api = useMemo(() => {
		return new Proxy<ApiClient>({} as any, {
			get: (_target, property: string) => {
				if (!functions) throw new Error('functions not initialized');

				const [service, type, method] = property.split("_");

				if (type === "call") {
					return (body: any) => httpsCallable(functions, `${service.toLowerCase()}_${type}`, {
						timeout: 540_000,
					})({
						...body,
						"internal.context.path": method
					}).then(res => res.data).catch(err => {
						console.table({
							...err,
							method,
							service,
							type
						});
						throw err})
				} else {
					return (body: any) => httpsCallable(functions, property.toLowerCase(), {
						timeout: 540_000
					})(body).then(res => res.data).catch(err => {
						console.table(err);
						throw err})
				}

			}
		})
	}, [functions]);

	return api

}
