import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {SessionExtended} from "@Types/Session";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";
import {Button} from "primereact/button";
import {CustomInput} from "@Components/CustomInput";
import {useUser} from "@Hooks/firebase";
import {SessionContext} from "@Context/Session.context";
import {useApi} from "@Hooks/api";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {PdfViewer} from "@Pages/Home/components/SignModule/PdfViewer";
import {Line} from "@Components/Line";
import {debounce} from "lodash";
import {usePromise} from "@Hooks/promise";
import {Unit} from "@Types/Unit";
import {EndpointBody} from "@Types/../Helpers";
import {useBreakpoint} from "@Utils/responsive.utils";
import {classNames} from "primereact/utils";
import placeholder from "@Assets/images/sign-placeholder.gif";
import {CustomDialog} from "@Components/CustomDialog";


const CanvasContainer = styled.div`
	border: 1px solid #D0D5DD;
	width: min-content;
	border-radius: 8px;
	height: min-content;
`;

export type SignModuleProps = {
	isRelance?: number;
	session: SessionExtended<false>;
	unit: Unit;
	onCancel?: () => void;
	onSigned?: () => void;
}
export const SignModule: React.FC<SignModuleProps> = (props) => {

	const ref = useRef<SignatureCanvas>(null);

	const {meta: user} = useUser(true);
	const {session} = useContext(SessionContext);
	const [place, setPlace] = useState<string>("");
	const [canvaDataUrl, setCanvaDataUrl] = useState<string>();
	const [isDrawing, setIsDrawing] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [isEmpty, setIsEmpty] = useState<boolean>(true);
	const [pdfContent, setPdfContent] = useState<Uint8Array>();

	const onClear = () => {
		ref.current?.clear();
		setCanvaDataUrl(undefined);
	}

	const payload = useMemo<undefined | EndpointBody<"pdf", "generateAttestation">["payload"]>(() => {
		if (!session || !user) return undefined;

		return {
			user,
			session_id: session.session_id,
			place,
			unit_id: props.unit.unit_id,
			img_url: canvaDataUrl || "",
		}
	}, [user, session, place, canvaDataUrl, isEmpty, props.unit]);

	const api = useApi();
	const [generatePdf, loading] = usePromise(async (payload) => {
		if (payload) {
			setIsDrawing(false);
			const res = await api.pdf_call_generateAttestation({
				payload
			})

			if (res.result === "ok") {
				const data = new Uint8Array(Object.values(res.pdf));
				setIsDrawing(false);
				setPdfContent(data);
			}
		}
	})
	useEffect(() => {
		const interval = setInterval(() => {
			if (ref.current) {
				const empty = ref.current.isEmpty();
				setIsEmpty(empty);
			}
		}, 300);
		return () => clearInterval(interval);
	}, [ref]);

	const debounced = useMemo(
		() => debounce(generatePdf, 400)
		, [payload]);

	useEffect(() => {
		debounced(payload);
		return () => debounced.cancel();
	}, [debounced]);
	const [onSign, signLoading] = usePromise(async (pdfContent: Uint8Array, canvaDataUrl: string, session: SessionExtended<false>, place: string) => {

		if (!place) {
			setError("Veuillez renseigner un lieu");
			return;
		}

		setError(null);

		const res = await api.pdf_call_sign({
			unit_id: props.unit.unit_id,
			session_id: session.session_id,
			pdf: pdfContent,
			signatureData: canvaDataUrl,
			place: place
		});

		if (res.result === "ok") {
			setSuccess(true);
		}

	}, {
		pending: "Signature en cours veuillez patienter...",
		success: "Attestation signée !"
	});

	const responsive = useBreakpoint();

	const [success, setSuccess] = useState<boolean>(false);

	const onGoBack = () => {
		setSuccess(false);
		props.onSigned?.()
	}

	return (
		<div className="SignModule overflow-hidden">
			<div className="he-header--h3 mb-4 flex align-items-center">
				Attestation sur l'honneur {props.isRelance !== undefined && `partie ${props.isRelance + 1}`} {loading && <i className="pi pi-spin pi-spinner ml-3"/>}
			</div>
			{success && <CustomDialog visible onHide={() => setSuccess(false)}>
				<div className="he-header--h3 mb-4 flex align-items-center justify-content-center w-full">
					Félicitations !
				</div>
				<div className="he-paragraph--regular">
					Votre attestation sur l'honneur a bien été signée.
				</div>
				<div className="w-full flex justify-content-center align-items-center mt-4">
					<Button className="he-button--success--xs"
							onClick={() => onGoBack()}>
						Continuer
					</Button>
				</div>
			</CustomDialog>}

			<div className="mb-3 color-red p-2" style={{background: "#ff000018", border: "1px solid red", borderRadius: 8}}>
				<i className="pi pi-exclamation-triangle"/> La signature <strong>digitale</strong> de ce document est obligatoire.
			</div>

			<div className={classNames("w-full flex justify-content-between", responsive.isDesktop || responsive.small ? "" : "flex-column")}>
				{<PdfViewer pdf={pdfContent} loading={loading}/>}
				<div className={classNames("flex-column", responsive.isDesktop || responsive.small ? "ml-3" : "mt-2")}>
					<div className={"mb-3"}>
						<div className="flex flex-column justify-content-center mb-2 gap-2">
							<div className="he-paragraph--regular gray-500">
								Signature
							</div>
							<div className="he-paragraph--small text-red-500">
								(veuillez maintenir le doigt sur le clic gauche de votre souris pour signer)
							</div>
						</div>
						<CanvasContainer className={classNames(responsive.isDesktop || responsive.small ? "relative" : "relative w-full")}>
							{!isEmpty &&
								<Button icon="pi pi-times"
										className="absolute top-0 right-0 he-button--primary-nfb--md ml-auto"
										label="effacer" onClick={onClear}/>}

							{isEmpty && <div className="absolute w-full h-full flex justify-content-center align-items-center" style={{opacity: "15%", pointerEvents: "none"}}>
								<img className="select-none" src={placeholder} width="300"/>
							</div>}
							<SignatureCanvas canvasProps={{width: (responsive.isDesktop || responsive.small) ? 400 : (responsive.width - 56), height: 130, className: 'sign'}} ref={ref}
											 onBegin={() => setIsDrawing(true)}
											 onEnd={() => {
												 if (ref.current) {
													 const data = ref.current.getTrimmedCanvas().toDataURL();
													 setCanvaDataUrl(data);
												 }
											 }}/>
						</CanvasContainer>
					</div>
					<CustomInput
						label="Fait à"
						value={place}
						onChange={value => setPlace(value)}
						placeholder="ex. Paris"
						small={"(veuillez compléter le lieu)"}
						error={error}
					/>
				</div>
			</div>

			<Line height={1} className="bg-gray-200 my-3"/>
			<div className="w-full flex justify-content-end align-items-center">
				{
					props.isRelance === undefined &&
					<Button className="he-button--secondary-variant-nfb--xs mr-auto" label="Annuler"
							onClick={() => props.onCancel?.()}/>
				}
				<Button className="he-button--primary--md" loading={signLoading || isDrawing || loading} disabled={!(place.trim()) || isEmpty || signLoading || isDrawing || loading} onClick={() => pdfContent && canvaDataUrl && session && onSign(pdfContent, canvaDataUrl, session, place)}>
			        <span className="material-symbols-outlined mr-2">
				        check_circle
			        </span>
					<div>
						Signer le document
					</div>
				</Button>
			</div>

		</div>
	)
}
