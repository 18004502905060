import React from "react";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {useToast} from "@Hooks/toast";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {CustomInput} from "@Components/CustomInput";
import {forgottenPasswordSchema} from "@Schemas/auth.schema";
import {useApi} from "@Hooks/api";
import {usePromise} from "@Hooks/promise";
import logo from "@Assets/images/logo-blue.svg";
import {classNames} from "primereact/utils";
import {useBreakpoint} from "@Utils/responsive.utils";

export type ForgottenPasswordProps = Record<string, never>
export const ForgottenPassword: React.FC<ForgottenPasswordProps> = () => {

	const {} = useToast();
	const navigate = useNavigate();

	const api = useApi();
	const [resetPassword, loading] = usePromise(async (values: {email: string}) => {
		try {
			const res = await api.user_call_reset({
				email: values.email
			});
			if (res.result !== "ok") throw new Error(res.result);
			navigate('/login');
		} catch (e: unknown) {
			console.table(e);
			throw e;
		}
	}, {
		pending: "Envoi du lien de réinitialisation en cours...",
		success: "Demande envoyée !",
	});

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: forgottenPasswordSchema,
		onSubmit: resetPassword
	});

	const {phone, isTablet} = useBreakpoint();

	function onGoBack() {
		navigate('/login');
	}

	return (
		<div className={classNames(`Login flex h-full w-full flex-column align-items-center overflow-auto p-2`, (phone || isTablet) ? "justify-content-between bg-white" : "justify-content-center")}>
			<div className="my-6">
				<img src={logo} height={phone ? 35 : 50}/>
			</div>
			<FormWrapper className="bg-white">
				<div className="he-header--h1 primary-900 mb-3 text-center">
					Mot de passe oublié
				</div>
				<div className="he-paragraph--regular gray-600 text-center mb-6">
					Indiquez votre adresse e-mail pour recevoir un e-mail vous permettant de réinitialiser votre mot de passe
				</div>
				<CustomInput
					field={"email"}
					formik={formik}
					label={"Adresse e-mail"}
					placeholder={"Adresse e-mail"}
					icon={"pi pi-at"}
					iconPos={"right"}
					className={"mb-5"}
				/>
				<Button
					className="he-button--primary--md w-full mb-3"
					onClick={() => {
						console.error(formik.errors);
						formik.submitForm();
					}}
					loading={loading}
					disabled={loading}
				>
					<i className={"pi pi-refresh"}/> Réinitialiser mon mot de passe
				</Button>
				<Button
					className="he-button--secondary-variant-nfb--xs"
					onClick={onGoBack}
					disabled={loading}
				>
					<i className={"pi pi-arrow-left"}/> Retour à la connexion
				</Button>
			</FormWrapper>
			<div className="my-2 p-3 bg-yellow-10" style={{width: "100%", maxWidth: 450}}>
				<div className="he-paragraph--regular-16 gray-500 flex flex-column gap-2 align-items-center">
					<span className="yellow-100 uppercase he-paragraph--regular-16--bold">Besoin d’aide ?</span>
					<span className="yellow-100">Nous sommes disponibles <span className={"font-bold"}>7J/7</span> de <span className={"font-bold"}>9h à 21h</span> au&nbsp;</span>
					<a href="tel:0176431249" className="yellow-100 no-underline" style={{fontSize: "16px", fontWeight: "bold"}}>
						<i className={"pi pi-phone mr-2"} style={{fontSize: 14}}/>01 76 43 12 49
					</a>
				</div>
			</div>
		</div>
	)
}

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
  height: auto;
  padding: 28px;
`;
