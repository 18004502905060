import { classNames } from 'primereact/utils';
import React, { useState } from 'react';
import styled from 'styled-components';

interface Props {
    label: string;
    className?: string;
}

export const CustomPanel: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const [toggle, settoggle] = useState<boolean>(false);

    return (
        <div className={props.className}>
            <div className="w-full">
                <CustomLabel className="w-max" onClick={() => settoggle((prev) => !prev)}>
                    {props.label} <i className={classNames('ml-1', 'pi', !toggle ? 'pi-angle-left' : 'pi-angle-up')} />
                </CustomLabel>
            </div>
            <div className={classNames('w-full overflow-hidden')} style={{ display: toggle ? 'flex' : 'none' }}>
                {props.children}
            </div>
        </div>
    );
};

const CustomLabel = styled.div`
    color: var(--secondary-100, #0b5599);
    text-align: center;
    font-size: 12px;
    font-family: roboto;
    font-weight: 500;
    line-height: 150%;
    cursor: pointer;
    i {
        font-size: 12px;
    }
`;
