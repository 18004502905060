import React, { useEffect, useMemo, useState } from 'react';
import { Session, SessionExtended, SessionParticipant } from '@Types/Session';
import { usePromise } from '@Hooks/promise';
import { isLess } from '@Utils/date.utils';
import { useApi } from '@Hooks/api';
import { useNavigate, useParams } from 'react-router-dom';
import { Regexes } from '@Utils/regex.utils';
import { UnitConfig } from '@Types/Unit';
import { DateTime } from 'luxon';
import { collection, doc, getDocs, getFirestore, onSnapshot } from 'firebase/firestore';
import { appFirebase } from '@Utils/config/firebase';
import { useUser } from '@Hooks/firebase';
import { Module } from '@Types/Module';
import { Theme } from '@Types/Theme';

export interface SessionContextI {
    sessions: SessionExtended<false>[];
    session?: SessionExtended<false>;
    modules: Module[];
    participant?: SessionParticipant | null;
    refresh: () => void;
    current_unit: (UnitConfig & { index: number }) | null;
    loading: boolean;
    refreshModules: (session?: Session | SessionExtended) => Promise<Module[] | undefined>;
    loadingModules: boolean;
    sessionThemes: Theme[];
}

export const SessionContext = React.createContext<SessionContextI>({
    refresh: () => {},
    modules: [],
    loading: false,
    sessions: [],
    session: undefined,
    current_unit: null,
    refreshModules: async () => undefined,
    loadingModules: false,
    sessionThemes: [],
});

export const SessionProvider: React.FC<React.PropsWithChildren> = (props) => {
    const [sessions, setSessions] = useState<SessionExtended<false>[]>([]);

    const { meta } = useUser(true);
    const reactParams = useParams<{ '*': string }>();

    const params = useMemo(() => {
        return Regexes.url.exec(reactParams['*'] || '')?.groups || {};
    }, [reactParams]);
    const api = useApi();

    const [modules, setModules] = useState<Module[]>([]);
    const [sessionThemes, setSessionThemes] = useState<Theme[]>([]);

    const [refreshSessions, loadingSessions] = usePromise(async () => {
        const res = await api.session_call_mine({});
        if (res.result !== 'ok') throw new Error(res.result);
        const sorted = [...res.sessions].sort((cs, ns) => (isLess(cs.start_date, ns.start_date) ? -1 : 1));
        setSessions(sorted);
    });

    const [refreshModules, loadingModules] = usePromise(async (session?: Session | SessionExtended) => {
        if (!session) return undefined;
        const res = await api.formation_call_modules({
            formation_id: session.formation_id,
        });

        if (res.result !== 'ok') throw new Error(res.result);

        let resModules = res.modules;

        setModules(resModules);
        return res.modules;
    });

    const session = useMemo(() => {
        const { session_id } = Regexes.url.exec(reactParams['*'] || '')?.groups || {};
        return sessions.find((s) => s.session_id === session_id);
    }, [reactParams, sessions]);

    useEffect(() => {
        if (session) {
            const themeRef = collection(getFirestore(appFirebase), 'formations', session.formation_id, 'themes');
            getDocs(themeRef).then((snapshot) => {
                const themes = snapshot.docs.map((doc) => doc.data() as Theme);
                setSessionThemes(themes);
            });
        }
    }, [session]);

    const navigate = useNavigate();

    const [loadingParticipant, setloadingParticipant] = useState<boolean>(false);
    const [participant, setParticipant] = useState<SessionParticipant | null>(null);

    useEffect(() => {
        if (session) {
            const colRef = doc(
                getFirestore(appFirebase),
                `/formations/${session.formation_id}/sessions/${session.session_id}/participants/${meta.user_id}`
            );
            setloadingParticipant(true);
            return onSnapshot(
                colRef,
                (data) => {
                    setParticipant(data.data() as SessionParticipant);
                    if ((data.data() as SessionParticipant).blocked) {
                        navigate('/home');
                    }
                    setloadingParticipant(false);
                },
                (err) => {
                    console.error(err);
                    setloadingParticipant(false);
                }
            );
        } else {
            return;
        }
    }, [session, meta.user_id]);

    const currentUnitMeta = useMemo<(UnitConfig & { index: number }) | null>(() => {
        if (!session) return null;
        const units = session.unitsConfig;
        let i = 0;
        let currentUnit: UnitConfig | null = null;
        for (const unit of units) {
            if (!unit.start_date) {
                i++;
                continue;
            }
            const currentStartDiff = DateTime.now().diff(DateTime.fromISO(unit.start_date));
            const nextUnit = units[i + 1];
            if (i + 1 === units.length) {
                if (currentStartDiff.as('seconds') >= 0) {
                    currentUnit = unit;
                }
            } else {
                if (nextUnit?.start_date) {
                    const nextStartDiff = DateTime.now().diff(DateTime.fromISO(nextUnit.start_date));
                    if (currentStartDiff.as('seconds') >= 0 && nextStartDiff.as('seconds') <= 0) {
                        currentUnit = unit;
                    }
                }
            }
            i++;
        }
        return currentUnit
            ? {
                  ...currentUnit,
                  index: units.findIndex((u) => u.unit_id === currentUnit?.unit_id) + 1,
              }
            : null;
    }, [session]);

    useEffect(() => {
        refreshSessions();
    }, []);

    useEffect(() => {
        if (session && params.session_id) refreshModules(session);
    }, [params.module_id, params.session_id, session]);

    return (
        <SessionContext.Provider
            value={{
                sessions,
                refresh: refreshSessions,
                session,
                loading: loadingSessions || loadingParticipant,
                modules,
                refreshModules,
                loadingModules,
                participant,
                current_unit: currentUnitMeta,
                sessionThemes,
            }}
        >
            {props.children}
        </SessionContext.Provider>
    );
};
