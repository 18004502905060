import {Activity} from "@Types/Activity";

export const iconForActivity = (type: Activity['type']) => {
	switch (type) {
		case "mcq":
		case "tcs":
		case "ucq":
		case "freetext":
			return "pi pi-question-circle";
		case "course":
			return "pi pi-file-pdf";
		case "video":
			return "pi pi-video";
	}
}
