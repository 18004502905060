import {useContext} from "react";
import {User as FirebaseUser} from "firebase/auth";
import {User, UserProgressItem} from "@Types/User";
import {FirebaseContext} from "../context/FirebaseContext";

export function useUser(authenticated?: false): {meta?: User, firebase?: FirebaseUser, progress?: UserProgressItem[], isConnected: boolean} | undefined;
export function useUser(authenticated: true): {meta: User, firebase: FirebaseUser, progress?: UserProgressItem[], isConnected: boolean};
export function useUser(authenticated?: boolean): {meta?: User, firebase?: FirebaseUser, isConnected: boolean} | undefined {

    const context = useContext(FirebaseContext);
    if (authenticated) {
        return {...context!.user!, isConnected: Boolean(context!.user!.meta && context!.user!.firebase)};
    }
    return context && context.user ? {...context.user, isConnected: Boolean(context.user.meta && context.user.firebase)} : undefined;
}
