import yup from "@Utils/config/yup";
import {Regexes} from "@Utils/regex.utils";

// update password regex to match PasswordCheck

export const loginSchema = yup.object().shape({
	email: yup.string().email().required(),
	password: yup.string().required()
});

export const forgottenPasswordSchema = yup.object().shape({
	email: yup.string().email().required()
})

export const resetPasswordSchema = yup.object().shape({
	password: yup.string().min(8).required().matches(Regexes.password),
	confirm: yup.string().oneOf([yup.ref('password')], "Les mots de passes ne correspondent pas").required(),
})

export const confirmAccountSchema = yup.object().shape({
	password: yup.string().min(8).required().matches(Regexes.password)
})
