import React, {useCallback, useEffect, useState} from "react";
import {BreadItemModel} from "@Components/CustomBreadCrumb";
import produce from "immer";
import {useNavigate, useParams} from "react-router-dom";

export interface NavigationContextI {
	navigation_tree: BreadItemModel[];
	appendToTree: (item: BreadItemModel) => void;
	removeById: (id: string) => void;
	removeItemAt: (position: number) => void;
	updateTree: (cb: (prev: BreadItemModel[]) => BreadItemModel[]) => void;
	insertInTree: (position: number, item: BreadItemModel) => void;
}
export const NavigationContext = React.createContext<NavigationContextI>({
	navigation_tree: [],
	appendToTree: () => {},
	removeById: () => {},
	updateTree: () => {},
	removeItemAt: () => {},
	insertInTree: () => {}
});

export const NavigationProvider: React.FC<React.PropsWithChildren> = (props) => {

	const navigate = useNavigate();
	const [navigationTree, setRealNavigationTree] = useState<BreadItemModel[]>([]);
	const [hydrated, setHydrated] = useState<boolean>(false);

	const params = useParams();

	const onInit = () => {
		const stored = localStorage.getItem("Navigation");
		console.log(params)
		if (stored) {
			const storedNavigationTree: BreadItemModel[] = JSON.parse(stored);
			setRealNavigationTree(storedNavigationTree);
			const lastItem = storedNavigationTree[storedNavigationTree.length - 1];
			if (lastItem?.url && params["*"] === "")
				navigate(lastItem.url);
		}
		setHydrated(true);
	}

	const setNavigationTree: typeof setRealNavigationTree = (cbOrValue) => {
		if (typeof cbOrValue === "function") {
			setRealNavigationTree((prev) => {
				const newNavigationTree = cbOrValue(prev);
				localStorage.setItem("Navigation", JSON.stringify(newNavigationTree));
				return newNavigationTree;
			});
		} else {
			const newNavigationTree = cbOrValue;
			localStorage.setItem("Navigation", JSON.stringify(newNavigationTree));
			setRealNavigationTree(newNavigationTree);
		}
	}

	useEffect(() => {
		if (!hydrated) {
			onInit();
		}
	}, [params, hydrated]);

	const alreadyInTree = (item: BreadItemModel, tree: BreadItemModel[]) => item.id !== undefined && tree.findIndex(i => i.id === item.id) !== -1;

	const appendToTree = useCallback((item: BreadItemModel) => {
		setNavigationTree(prev => {
			return !alreadyInTree(item, prev) ? [...prev, item] : prev
		});
	}, [navigationTree]);

	const removeById = useCallback((id: string) => {
		setNavigationTree(prev => produce(prev, draft => {
			return draft.filter(i => i.id !== id);
		}));
	}, [navigationTree]);

	const updateTree = useCallback((cb: (cb: BreadItemModel[]) => BreadItemModel[]) => {
		setNavigationTree(cb);
	}, [navigationTree]);

	const insertInTree = useCallback((position: number, item: BreadItemModel) => {
		setNavigationTree(produce(tree => {
			tree.splice(position, 0, item);
			return tree;
		}));
	}, [navigationTree])

	const removeItemAt = useCallback((position: number) => {
		setNavigationTree(produce(tree => {
			tree.splice(position, 1);
			return tree;
		}))
	}, [navigationTree])

	return <NavigationContext.Provider value={{
		navigation_tree: navigationTree,
		insertInTree,
		updateTree,
		removeById,
		removeItemAt,
		appendToTree
	}}>
		{hydrated && props.children}
	</NavigationContext.Provider>
}
