import React, {useContext, useEffect, useState} from "react";
import {SessionExtended} from "@Types/Session";
import {Unit} from "@Types/Unit";
import {useApi} from "@Hooks/api";
import {useLocation} from "react-use";
import {Navigate} from "react-router-dom";
import { SessionContext } from "@Context/Session.context";
import {canByPass} from "@Utils/participant.utils";

type Props = {
    session: SessionExtended<false>;
    unit_id: string;
    onFail: () => void;
    children: React.ReactElement;
}
export const CheckUnit: React.FC<Props> = (props) => {

    const api = useApi();
    const [isValid, setIsValid] = useState<boolean>(false);

    const [checking, setChecking] = useState(true);

    const {participant} = useContext(SessionContext);
    const onFail = () => {
        if (location.href) {
            const url = new URL(location.href);
            url.searchParams.forEach((_value, key) => {
                url.searchParams.delete(key);
            });
            window.location.href = url.toString();
            props.onFail();
        }
    }

    async function check(session_id: string, unit: Unit) {
        setChecking(true);

        if (participant && canByPass(props.session, participant)) {
            setIsValid(true);
            setChecking(false);
            return;
        }

        const res = await api.session_call_checkUnit({
            session_id: session_id,
            unit_id: unit.unit_id
        });

        if (res.result === "ok") {
            setIsValid(true);
            setChecking(false);
        } else {
            setChecking(false);
            onFail();
        }
    }

    const location = useLocation();

    useEffect(() => {
        const unit = props.session.formation.units.find(u => u.unit_id === props.unit_id)
        if (unit) {
            check(props.session.session_id, unit)
        } else {
            onFail();
        }
    }, [])

    return (
        checking ? <div className='w-full h-full py-4 flex flex-column gap-4 justify-content-center align-items-center'>
                <div className='he-header--h1 text-center'>
                    Vérification en cours
                </div>
                <div className={"w-min"}>
                    <i className='pi pi-spin pi-spinner' style={{ fontSize: '4rem' }} />
                </div>
                <div className='he-paragraph--regular'>
                    Veuillez patienter...
                </div>
            </div> :
            (isValid && props.children) ? props.children : <Navigate to={"/"} />
    )
}
