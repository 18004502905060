export const yupLocale = {
	mixed: {
		required: "Ce champ est obligatoire.",
	},
	string: {
		email: "L' adresse e-mail renseignée est invalide.",
		default: "La valeur saisie est incorrecte.",
		min: "La valeur doit être d'au moins ${min} caractères."
	}
}
