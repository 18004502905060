import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import './SessionSkeleton.scss';
export type SessionSkeletonProps = Record<string, never>;
export const SessionSkeleton: React.FC<SessionSkeletonProps> = () => {
    return (
        <div className="SessionSkeleton w-full h-full">
            <Skeleton className="w-full" height="100px" />
            <div className="skeleton-content h-full m-auto relative">
                <Skeleton height="228px" width="364px" className="skeleton-card shadow-1" />
                <Skeleton width="655px" height="60px" className="mt-6" />
                <Skeleton width="400px" height="30px" className="mt-3" />
                <Skeleton width="655px" height="400px" className="mt-4" />
            </div>
        </div>
    );
};
