import { useApi } from '@Hooks/api';
import { useUser } from '@Hooks/firebase';
import { usePromise } from '@Hooks/promise';
import { useToast } from '@Hooks/toast';
import { TcsScale } from '@Pages/Home/Session/components/TcsScale';
import { Activity, ActivityHistory, TCSActivity as TCSActivityModel, TCSAnswer } from '@Types/Activity';
import { Resource } from '@Types/Resource';
import { getUrlForResource } from '@Utils/resource.utils';
import { useBreakpoint } from '@Utils/responsive.utils';
import produce from 'immer';
import { Button } from 'primereact/button';
import { Galleria } from 'primereact/galleria';
import { Image } from 'primereact/image';
import { RadioButton } from 'primereact/radiobutton';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {toast} from "react-toastify";
import { ModuleContext } from '@Context/Module.context';
import { SessionTrackerContext } from '@Context/SessionTracker.context';
import { Module } from '@Types/Module';
import { Duration } from 'luxon';
import { DurationUnit } from '@Types/Time';
import { CustomImage } from '@Components/CustomImage';
import ReactPlayer from "react-player";

const DescriptionContainer = styled.div`
    background: #f9fafb;
    border-radius: 8px;
    padding: 20px;
`;

const QuizzContainer = styled.div`
    border: 1px solid #eaecf0;
    border-radius: 8px;
    padding: 20px;
`;

const HeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 180px 180px repeat(5, minmax(0, 1fr));
    grid-template-rows: auto 1px auto;
    column-gap: 8px;
    row-gap: 8px;
    grid-template-areas:
        'hypothesis information info info info info info'
        'line line line line line line line'
        '. . l0 l1 l2 l3 l4';
`;

const HypothesisContainer = styled.div`
    display: grid;
    grid-template-columns: 180px 180px repeat(5, minmax(0, 1fr));
    grid-template-rows: minmax(30px, auto);
    column-gap: 8px;
    grid-auto-rows: minmax(30px, auto);
`;

const HypothesisContainerResponsive = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    padding: 16px;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    width: 100%;
`;

const ScoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px solid #eaecf0;
    border-radius: 8px;
`;

const defaults = ['Fortement négatif', 'Négatif', 'Neutre', 'Positif', 'Fortement positif'];

const colors = ['#EA2828', '#EAA828', '#EAE828', '#A3CC2E', '#2ECC71'];

const DisplayScore = styled.span`
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
`;

const Percent = styled.span`
    font-weight: 700;
    font-size: 10px;
    line-height: 130%;
    color: #667085;
`;

const TCSButton = styled.div<{ selected: boolean; done: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 12px;
    ${(props) =>
        props.selected
            ? props.done
                ? `
  background: white;
  border: 1px solid #3C90E3;
	`
                : `
  background: #F1F7FD;
  border: 1px solid #3C90E3;
	`
            : `
  background: #FFFFFF;
  border: 1px solid #EAECF0;
	`}
    border-radius: 12px;
`;

export type TCSActivityProps = {
    activity: Activity & TCSActivityModel;
    index: number;
    next: any;
};

export const TCSActivity: React.FC<TCSActivityProps> = (props) => {
    const { progress } = useUser(true);

    const [selection, setSelection] = useState<{ [k: number]: number }>({});

    const {module: currentModule} = useContext(ModuleContext);
    const { modulesProgress, moduleTime } = useContext(SessionTrackerContext);

    const topRef = React.useRef<HTMLDivElement>(null);
    const [images, setImages] = useState<(null | Resource | undefined)[]>([]);
    const [videos, setVideos] = useState<Array<null | Resource | undefined>>([]);
    const remainingTime = useMemo(() => {
        if (currentModule?.minimal_duration == null && currentModule?.minimal_duration_unit == undefined) {
            return true;
        }

        const moduleProgress = modulesProgress[currentModule.module_id];

        if (moduleTime == null && moduleProgress === true) {
            return true;
        }

        if (
            moduleTime == null ||
            currentModule?.minimal_duration == null ||
            currentModule?.minimal_duration_unit == null
        ) {
            return false;
        }

        const durationToMillis = (qty: number, unit: DurationUnit) => {
            switch (unit) {
                case 'h':
                    return qty * 60 * 60 * 1000;
                case 's':
                    return qty * 7 * 24 * 60 * 60 * 1000;
                case 'm':
                    return qty * 60 * 1000;
                case 'j':
                    return qty * 24 * 60 * 60 * 1000;
                default:
                    return 0;
            }
        };

        const minimumTimeInSeconds = durationToMillis(
            currentModule.minimal_duration,
            currentModule.minimal_duration_unit
        );

        return minimumTimeInSeconds - moduleTime;
    }, [moduleTime, currentModule, modulesProgress]);


    const { session_id, module_id, activity_id } = useParams<{
        session_id: string;
        module_id: string;
        activity_id: string;
    }>();
    const api = useApi();
    const onSelectAnswer = (hindex: number, aindex: number) => {
        setSelection(
            produce((p) => {
                if (p[hindex] === aindex) {
                    delete p[hindex];
                } else {
                    p[hindex] = aindex;
                }
                return p;
            })
        );
    };

    const {error} = useToast(toast.POSITION.TOP_RIGHT, 30000);

    const activityHistory = useMemo<ActivityHistory | undefined>(() => {
        if (!(session_id && module_id && activity_id)) return undefined;
        return progress?.find(p=> p.session_id === session_id && p.module_id === module_id && p.activity_id === activity_id)?.activity;
    }, [progress, session_id, module_id, activity_id]);

    useEffect(() => {
        if (activity_id && session_id && module_id) {
            if (activityHistory?.done && activityHistory.answers) {
                setSelection(activityHistory.answers);
                props.next.setNextStatus(true);
            } else {
                props.next.setNextStatus(false);
            }
        }
    }, [activityHistory, activity_id, module_id, session_id]);

    const calculateHypothesisScore = (choice: number, answers: number[]) => {
        const max = Math.max(...answers);
        return answers[choice] / max;
    };

    const score = useMemo(() => {
        if (!activityHistory?.answers) return 0;

        return (
            (Object.entries(activityHistory.answers)
                .map(([hi, ai]) => {
                    return calculateHypothesisScore(ai, props.activity.hypothesis[+hi].answers);
                })
                .reduce((a, c) => a + c, 0) *
                100) /
            props.activity.hypothesis.length
        );
    }, [activityHistory]);

    const [onValidate, loading] = usePromise(
        async (selection: TCSAnswer, activity_id?: string, module_id?: string, session_id?: string, module?: Module,  remainingTimeProp?: number | boolean) => {
            if (!(session_id && module_id && activity_id)) return;

            const res = await api.session_call_checkAnswer({
                activity_id,
                module_id,
                session_id,
                answers: selection,
                others: {}
            });

            if (res.result !== 'ok') throw new Error(res.result);

            console.log(module, remainingTimeProp);

            if (module && remainingTimeProp) {
                console.log("last activity", "progress", remainingTimeProp);
                const activityIndex = module?.activities.findIndex((a) => a.activity_id === activity_id);
                if (activityIndex !== undefined
                    && activityIndex !== -1
                    && activityIndex === module.activities.length - 1
                    && typeof remainingTimeProp === "number" && remainingTimeProp > 0
                    ) {

                    error(`Vous devez attendre ${Duration.fromMillis(remainingTimeProp).toFormat("h'h 'm'm'")} pour valider votre formation dans son intégralité`);
                }
            }
        }
    );

    const color = useMemo(() => {
        const index = Math.floor((score / 100) * 5);
        return colors[index >= colors.length ? colors.length - 1 : index];
    }, [score]);

    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [topRef.current, activity_id]);
    useEffect(() => {
        if (activity_id && module_id && session_id) {
            api.session_call_startActivity({
                activity_id,
                module_id,
                session_id,
            }).catch((err) => {
                console.error(err);
            });
        }
        return () => {
            setSelection([]);
        };
    }, [activity_id, module_id, session_id]);

    const responsive = useBreakpoint();

    const itemTemplate = (item: Resource) => {
        return (
            <Image
                preview
                src={item?.resource_url}
                alt={item?.resource_name}
                width="auto"
                height={'200px'}
                className="pb-5"
            />
        );
    };

    const thumbnailTemplate = (item: Resource) => {
        return <img src={item.resource_url} alt={item.resource_name} style={{ width: 'auto', height: '70px' }} />;
    };


    useEffect(() => {
        if (props.activity.cover_image_url) {
            Promise.all(
                props.activity.cover_image_url.map(async (i?: string | null) => {
                    if (i) {
                        return getUrlForResource(i);
                    }
                    return null;
                })
            ).then((imgs) => {
                setImages(imgs.filter((e) => !!e));
            });
        }
        if (props.activity.cover_video_url) {
            Promise.all(props.activity.cover_video_url.map(async (i?: string | null) => {
                if (i) {
                    return getUrlForResource(i);
                }
                return null;
            })).then(vids => {
                console.log('new request')
                setVideos(vids.filter(Boolean));
            })
        }
    }, [props.activity]);

    const responsiveGalleriaOptions = [
        {
            breakpoint: '768px',
            numVisible: 2,
        },
        {
            breakpoint: '450px',
            numVisible: 1,
        },
    ];

    const resources = useMemo(() => {
        return videos.map(v => {
            if (v != null) {
                return <ReactPlayer
                    progressInterval={30_000}
                    config={{
                        file: {
                            attributes: {
                                controlsList: "nodownload"
                            }
                        }
                    }}
                    url={v.resource_url}
                    height={"150px"}
                    width={"auto"}
                    controls
                />
            }
            return null;
        })
    }, [videos])

    return (
        <div className="w-full h-full relative">
            <div className="he-header--h1 gray-900" ref={topRef}>{props.activity.title}</div>
            <div className="he-paragraph--small gray-500 text-justify mt-2">
                Les TCS évaluent votre capacité à prendre des décisions pertinentes en situation d’incertitude. Ces
                vignettes sont suivies par des propositions d’options ou d’hypothèses possibles. Pour chaque hypothèse,
                une nouvelle information est proposée : vous devez alors décider si elle augmente, diminue ou est sans
                conséquence sur la pertinence de cette hypothèse.
            </div>
            <DescriptionContainer className="mt-4 z-2 shadow-1">
                <div className="he-paragraph--medium gray-800">Cas clinique</div>
                <div className="he-paragraph--regular-16 gray-600 mt-3">{props.activity.description}</div>
                {
                    resources.length > 0 && <div>
                        <div className="he-paragraph--medium gray-800 mt-4 mb-3">Ressources vidéos</div>
                        <div className="flex gap-4 flex-wrap">
                            {resources}
                        </div>
                    </div>
                }
            </DescriptionContainer>
            {images.length > 0 && (
                <div className="bg-gray-600 pt-5 mt-3">
                    <Galleria
                        circular
                        responsiveOptions={responsiveGalleriaOptions}
                        value={images}
                        numVisible={5}
                        item={itemTemplate}
                        style={{ height: '100%' }}
                        thumbnail={thumbnailTemplate}
                    />
                </div>
            )}
            {(responsive.tablet || responsive.phone) && (
                <div className="mt-3">
                    {props.activity.hypothesis.map((h, hi) => {
                        return (
                            <HypothesisContainerResponsive className="w-full mt-3" key={hi}>
                                <div className="w-full he-paragraph--small gray-400">
                                    {props.activity.labels?.hypothesis || 'Si vous pensiez à...'}
                                </div>
                                <div
                                    className="he-paragraph--regular flex align-items-center gray-700 py-3 gap-3"
                                    style={{ wordBreak: 'break-word' }}
                                >
                                    {h.initial}
                                    {h.initial_image && <CustomImage urlPromise={getUrlForResource(h.initial_image).then(r => r?.resource_url)} width="80"/>}
                                </div>

                                <div className="he-paragraph--small gray-400">
                                    {props.activity.labels?.new_information || "Et que vous trouvez que/qu'..."}
                                </div>
                                <div
                                    className="he-paragraph--regular flex align-items-center  gray-700 py-3 h-full flex align-items-center gap-3"
                                    style={{ wordBreak: 'break-word' }}
                                >
                                    {h.information}
                                    {h.information_image && <CustomImage urlPromise={getUrlForResource(h.information_image).then(r => r?.resource_url)} width="80"/>}
                                </div>
                                <div className="he-paragraph--small gray-400">
                                    {'L’impact de cette information sur votre hypothèse est...'}
                                </div>
                                {h.answers.map((a, ai) => {
                                    const label =
                                        props.activity.labels && props.activity.labels[ai as 1 | 2 | 3 | 4 | 0];
                                    const selected = selection[hi] !== undefined && ai === selection[hi];
                                    const done = Boolean(activityHistory?.done);
                                    return (
                                        <TCSButton
                                            selected={selected}
                                            done={done}
                                            key={ai}
                                            className="h-full flex py-3 align-items-center justify-content-start mt-2"
                                            onClick={() => onSelectAnswer(hi, ai)}
                                        >
                                            <RadioButton checked={selected} disabled={activityHistory?.done} />
                                            <div
                                                className={
                                                    selected
                                                        ? 'primary-100 he-paragraph--regular--bold'
                                                        : 'gray-900 he-paragraph--regular'
                                                }
                                            >
                                                {label || defaults[ai]}
                                            </div>
                                            {done && (
                                                <div className="ml-auto he-paragraph--regular gray-400">
                                                    ({props.activity.hypothesis[hi].answers[ai]})
                                                </div>
                                            )}
                                        </TCSButton>
                                    );
                                })}
                            </HypothesisContainerResponsive>
                        );
                    })}
                </div>
            )}
            {(responsive.isDesktop || responsive.small) && (
                <QuizzContainer className="mt-3">
                    <HeaderContainer>
                        <div style={{ gridArea: 'hypothesis' }} className="w-full he-paragraph--small primary-700">
                            {props.activity.labels?.hypothesis || 'Si vous pensiez à...'}
                        </div>
                        <div style={{ gridArea: 'information' }} className="he-paragraph--small primary-700">
                            {props.activity.labels?.new_information || "Et que vous trouvez que/qu'..."}
                        </div>
                        <div style={{ gridArea: 'info' }} className="he-paragraph--small primary-700">
                            {'L’impact de cette information sur votre hypothèse est...'}
                        </div>
                        <div style={{ gridArea: 'line', borderBottom: '1px solid #EAECF0' }}></div>
                        {defaults.map((text, i) => {
                            const label = props.activity.labels && props.activity.labels[i as 1 | 2 | 3 | 4 | 0];
                            return (
                                <div
                                    key={i}
                                    style={{ gridArea: `l${i}`, wordBreak: 'break-word' }}
                                    className="he-paragraph--xs gray-500 text-center"
                                >
                                    {label || text}
                                </div>
                            );
                        })}
                    </HeaderContainer>
                    {props.activity.hypothesis.map((h, hi) => {
                        return (
                            <HypothesisContainer className="bg-gray-50 mt-2" key={hi}>
                                <div className="he-paragraph--regular flex flex-column justify-content-center gap-2 gray-700 py-3 pl-3 align-items-center" style={{wordBreak: "break-word"}}>
                                    {h.initial}
                                    {h.initial_image && <CustomImage urlPromise={getUrlForResource(h.initial_image).then(r => r?.resource_url)} width="80"/>}
                                </div>
                                <div className="he-paragraph--regular flex flex-column justify-content-center gap-2 gray-700 py-3 h-full align-items-center" style={{wordBreak: "break-word"}}>
                                    {h.information}
                                    {h.information_image && <CustomImage urlPromise={getUrlForResource(h.information_image).then(r => r?.resource_url)} width="80"/>}
                                </div>
                                {h.answers.map((a, ai) => {
                                    return (
                                        <div
                                            key={ai}
                                            className="h-full flex flex-column py-3 align-items-center justify-content-center"
                                        >
                                            <RadioButton
                                                onChange={() => onSelectAnswer(hi, ai)}
                                                checked={selection[hi] !== undefined && ai === selection[hi]}
                                                disabled={activityHistory?.done}
                                            />
                                            {activityHistory?.done && (
                                                <div className="mt-2 he-paragraph--small gray-600">
                                                    ({props.activity.hypothesis[hi].answers[ai]})
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </HypothesisContainer>
                        );
                    })}
                </QuizzContainer>
            )}
            {activityHistory?.done && (
                <ScoreContainer className="bg-white mt-3">
                    <div className="flex flex-column align-items-center mb-4">
                        <span className="he-paragraph--small gray-500">VOTRE SCORE :</span>
                        <div>
                            <DisplayScore style={{ color: color }} className="roboto">
                                {new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(score)} points
                            </DisplayScore>
                            <Percent className="roboto">/100</Percent>
                        </div>
                    </div>
                    <div className="w-full">
                        <TcsScale score={score} colors={colors} />
                    </div>
                </ScoreContainer>
            )}
            {!activityHistory?.done && (
                <Button
                    className="he-button--success--md mt-3"
                    loading={loading}
                    disabled={Object.keys(selection).length !== props.activity.hypothesis.length}
                    onClick={() =>
                        module_id &&
                        session_id &&
                        activity_id &&
                        onValidate(selection, activity_id, module_id, session_id, currentModule, remainingTime)
                    }
                >
                    <i className="pi pi-check" /> Valider la réponse
                </Button>
            )}
        </div>
    );
};
