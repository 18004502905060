import { Module } from "@Types/Module";
import { Session, SessionExtended, SessionParticipant, SessionTimeReport } from "@Types/Session";
import { DurationUnit } from "@Types/Time";
import { UserProgress, UserProgressItem } from "@Types/User";
import _ from "lodash";
import { DateTime } from "luxon";

export const isSessionStarted = (session: Session, progress?: UserProgressItem[]) => {
	const sessionProgress = progress?.find(sp => sp.session_id === session.session_id)
	return !sessionProgress;
}

export const isSessionFinished = (participant?: SessionParticipant | null) => {
	const customSessionEndDate = participant ? DateTime.fromISO(participant.end_date).setLocale("Europe/Paris").endOf("day").diffNow("seconds").get("seconds") : undefined;
	return customSessionEndDate ? customSessionEndDate <= 0 : true;
}


export const getRemainingModuleTime = (module_id: string, currentModule: Module, report: SessionTimeReport, timeSinceDownload: number) => {
	const module = report.modules.find(m => m.module_id === module_id);
	if (module) {

		if (currentModule?.minimal_duration == null || currentModule?.minimal_duration_unit == null) return null;
		const durationToMillis = (qty: number, unit: DurationUnit) => {
			switch (unit) {
				case "h":
					return qty * 60 * 60 * 1000;
				case "s":
					return qty * 7 * 24 * 60 * 60 * 1000;
				case "m":
					return qty * 60 * 1000;
				case "j":
					return qty * 24 * 60 * 60 * 1000;
				default:
					return 0;
			}
		}
		const minimumTimeInSeconds = durationToMillis(currentModule.minimal_duration, currentModule.minimal_duration_unit)

		const res = minimumTimeInSeconds - module.total;

		return res;
	} else
		return null
}


export const getLastActivity = (
	progressItems: UserProgressItem[],
	session: SessionExtended<false>,
	modules: Module[],
	participant: SessionParticipant,
	sessionReport: SessionTimeReport,
	timeSinceDownload: number,
	onError: (error: string, redirectUrl?: string) => void) => {

	const progress: UserProgress = _.mapValues(_.groupBy(progressItems, "session_id"), items => {
		return _.mapValues(_.groupBy(items, "module_id"), items => {
			return _.mapValues(_.groupBy(items, "activity_id"), items => {
				return items[0].activity;
			})
		})
	});

	const sessionProgressAll = progress[session.session_id];

	if (!sessionProgressAll) return undefined;

	const firstModule = session.formation.modules[0];
	const firstModuleActivity = modules.find(m => m.module_id === firstModule.module_id)?.activities[0];

	if (firstModuleActivity?.activity_id && sessionProgressAll[firstModule.module_id]?.[firstModuleActivity.activity_id]?.done === false) {
		return {
			module_id: firstModule.module_id,
			activity_id: firstModuleActivity.activity_id,
		}
	}

	// remove modules that have no done activities from sessionProgress
	const sessionProgressDone = Object.fromEntries(Object.entries(sessionProgressAll).filter(([moduleId, module]) => {
		if (!module) return false;
		return Object.values(module).some(a => a?.done);
	}));

	let lastModuleId = _.last(_.intersection(session.formation.modules.map(e => e.module_id), Object.keys(sessionProgressDone)));

	if (!lastModuleId) return undefined;

	// get the last module with done activities
	const lastModule = sessionProgressDone[lastModuleId];
	let lastModuleOriginalIndex = modules.findIndex(e => e.module_id === lastModuleId);
	// get module meta
	let lastModuleOriginal = modules[lastModuleOriginalIndex];

	let lastModuleUnit = session.formation.units.find(u => u.modules_ids.includes(lastModuleOriginal.module_id));
	let lastModuleUnitIndex = session.formation.units.findIndex(u => u.modules_ids.includes(lastModuleOriginal.module_id));

	const signedPreviousUnits = Boolean(participant.unitsMeta?.every((u, i) => {
		const unitSessionMeta = session.unitsConfig.find(us => us.unit_id === u.unit_id);
		return i < lastModuleUnitIndex ? (unitSessionMeta?.required_signature ? Boolean(u.signature_id) : true) : true;
	}))
	if (!signedPreviousUnits) {
		const index = participant.unitsMeta?.findIndex(u => !u.signature_id)
		if (index !== undefined && index !== -1) {
			onError(
				`Vous devez signer l'attestation sur l'honneur de la partie ${index + 1} avant de continuer la formation`,
				`/session/${session.session_id}?u=${index}`
			);
			return undefined;
		}
	}

	if (!lastModule || !lastModuleOriginal) return undefined;

	let moduleActivities = lastModuleOriginal.activities;
	const lastModuleActivities = Object.values(lastModule).filter(a => a?.done);

	let lastDoneActivityIndex = moduleActivities
		.map(a => lastModuleActivities.findIndex(la => la?.activity_id === a.activity_id))
		.reduce((a, c) => Math.max(a, c), 0);

	// Changement de module
	if (lastDoneActivityIndex + 1 >= moduleActivities.length) {
		console.log("changing module")
		const oldModule = lastModuleOriginal;

		lastModuleOriginalIndex++;
		lastModuleOriginal = modules[lastModuleOriginalIndex];

		// Si module suivant existe
		if (lastModuleOriginal) {
			const newUnit = session.formation.units.find(u => u.modules_ids.includes(lastModuleOriginal.module_id));
			// Si changement d'unité
			if (lastModuleUnit && newUnit && lastModuleUnit.unit_id !== newUnit.unit_id) {

				const newUnitConfig = session.unitsConfig.find(u => u.unit_id === newUnit.unit_id);
				const lastUnitConfig = session.unitsConfig.find(u => u.unit_id === lastModuleUnit!.unit_id)
				const lastUnitMeta = participant.unitsMeta?.find(u => u.unit_id === lastModuleUnit!.unit_id);
				const newModuleUnitIndex = session.formation.units.findIndex(u => u.unit_id === newUnit.unit_id);

				if (!lastUnitMeta) return undefined;

				// Si signature requise unité précédente et pas de signature
				if (!lastUnitMeta.signature_id && lastUnitConfig?.required_signature === true) {
					onError(
						"Vous devez signer l'attestation sur l'honneur de la partie précédente avant de continuer la formation",
						`/session/${session.session_id}?u=${newModuleUnitIndex - 1}`
					);
					return undefined;
				}

				if (!newUnitConfig) return undefined;
				// si date de début de la partie suivante configurée et pas encore ouverte
				if (newUnitConfig.start_date &&
					DateTime.now().setZone("Europe/Paris").diff(DateTime.fromISO(newUnitConfig.start_date).setZone("Europe/Paris").startOf("day"), "seconds").get("seconds") < 0) {
					onError(
						"Vous pourrez continuer la formation une fois la partie suivant ouverte.",
						`/session/${session.session_id}`
					);
					return undefined;
				}

				const lastModuleTime = getRemainingModuleTime(oldModule.module_id, oldModule, sessionReport, timeSinceDownload);

				// si temps restant trouvé
				if (lastModuleTime !== null) {
					// si temps restant > 0
					if (lastModuleTime > 0) {
						return {
							module_id: oldModule.module_id,
							activity_id: _.last(oldModule.activities.map(a => a.activity_id)),
						}
					}
					// si temps effectué
					else {
						return {
							module_id: newUnit.modules_ids[0],
							activity_id: modules.find(m => m.module_id === newUnit.modules_ids[0])?.activities[0]?.activity_id,
						}
					}

				}

			}

			const lastModuleTime = getRemainingModuleTime(oldModule.module_id, oldModule, sessionReport, timeSinceDownload);
			if (lastModuleTime !== null && lastModuleTime > 0) {
				return {
					module_id: oldModule.module_id,
					activity_id: _.last(oldModule.activities.map(a => a.activity_id)),
				}
			}

			if (!lastModuleOriginal) return undefined;
			lastModuleId = lastModuleOriginal.module_id;

			moduleActivities = lastModuleOriginal.activities;
			lastDoneActivityIndex = -1;

		} else {
			return {
				module_id: lastModuleId,
				activity_id: moduleActivities[lastDoneActivityIndex].activity_id,
				finished: true,
			};
		}
	}

	const lastActivity = moduleActivities[lastDoneActivityIndex + 1];
	if (!lastActivity) return undefined;

	//      module_id      activity_id
	return { module_id: lastModuleId, activity_id: lastActivity.activity_id }

}

