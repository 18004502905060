import Popup from '@Components/Popup';
import { ModuleProvider } from '@Context/Module.context';
import { Forum } from '@Pages/Home/Forum';
import { Landing } from '@Pages/Home/Landing';
import { Profile } from '@Pages/Home/Profile';
import { Session } from '@Pages/Home/Session';
import { Module } from '@Pages/Home/Session/pages/Module';
import { Activity } from '@Pages/Home/Session/pages/Module/pages/Activity';
import { VirtualClass } from '@Pages/Home/Session/pages/VirtualClass';
import { Satisfaction } from '@Pages/Home/Session/Satisfaction';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

export type ContentPageProps = Record<string, never>;

export const ContentPage: React.FC<ContentPageProps> = () => {
    return (
        <div className="ContentPage relative w-full flex-1 overflow-auto">
            <Routes>
                <Route path="/running" element={<Landing />} />
                <Route path="/finished" element={<Landing finished />} />
                <Route path="/" element={<Landing />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/forum/:formation_id" element={<Forum />} />
                <Route
                    path="/:session_id/*"
                    element={
                        <Popup>
                            <Routes>
                                <Route path="/satisfaction" element={<Satisfaction />} />
                                <Route path="/virtualroom/:virtualclass_id" element={<VirtualClass />} />
                                <Route
                                    path="/module/:module_id/*"
                                    element={
                                        <ModuleProvider>
                                            <Routes>
                                                <Route path="/" element={<Module />} />
                                                <Route path="/activity/:activity_id" element={<Activity />} />
                                            </Routes>
                                        </ModuleProvider>
                                    }
                                />
                                <Route path="/" element={<Session />} />
                            </Routes>
                        </Popup>
                    }
                />
                <Route path="/*" element={<Navigate to={'/home'} />} />
            </Routes>
        </div>
    );
};
