import React, {PropsWithChildren, useEffect, useState} from "react";
import {CustomDialog} from "@Components/CustomDialog";
import {version} from "../config/version";
import {doc, onSnapshot, Timestamp} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import {DateTime} from "luxon";
import {Button} from "primereact/button";
import {useBreakpoint} from "@Utils/responsive.utils";

export const Version: React.FC<PropsWithChildren> = (props) => {

	const [lastVersion, setLastVersion] = useState<string | null>(null);

	useEffect(() => {
		const docRef = doc(firestore.db, "version", "last");

		return onSnapshot(docRef, snap => {
			const d = snap.data() as { v: string, release: Timestamp };
			console.log(d);
			if (d) {
				console.log(`Found version ${d.v} of ${DateTime.fromMillis(d.release.toMillis()).toISO()}`)
				setLastVersion(d.v);
			}
		})

	}, []);

	const responsive = useBreakpoint();

	const onUpdate = () => {
		window.location.reload();
	}

	return (
		<>
			{version !== lastVersion && lastVersion !== null && <CustomDialog zIndex={9999} visible onHide={() => {
			}} width={400}>
                <div className="flex flex-column align-items-center p-2">
                    <div className="he-header--h3 gray-900">
                        Nouvelle mise à jour
                    </div>

                    <div className="he-paragraph--regular mt-3 text-center">
                        La nouvelle version de l'application est disponible.
                    </div>
                    <div className="he-paragraph--regular mt-2 text-center">
                        Pour continuer votre formation cliquez sur le bouton suivant.
                    </div>
                    <div className="mt-4 flex justify-content-center align-items-center w-full">
                        <Button onClick={onUpdate} className="he-button--primary--md">Mettre à jour <i
                            className="pi pi-sync"/></Button>
                    </div>
                </div>
            </CustomDialog>}
			{props.children}
			{(responsive.isDesktop) && <div className={"absolute z-10 bottom-0 right-0 mx-5 mb-3 he-paragraph--regular gray-500"}>
				 <div className="px-2 py-1 bg-gray-200 border-round">
					 {version}
				 </div>
			</div>}
		</>
	)
}
