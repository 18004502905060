import { ScrollContainer } from '@Pages/Home/layouts/components/ScrollContainer';
import { CourseActivity } from '@Pages/Home/Session/pages/Module/pages/Activity/CourseActivity';
import { QuizzActivity } from '@Pages/Home/Session/pages/Module/pages/Activity/QuizzActivity';
import { TCSActivity } from '@Pages/Home/Session/pages/Module/pages/Activity/TCSActivity';
import { VideoActivity } from '@Pages/Home/Session/pages/Module/pages/Activity/VideoActivity';
import { ActivityLayout } from '@Pages/Home/Session/pages/Module/pages/layouts/ActivityLayout';
import {
    Activity as ActivityModel,
    QuizzActivity as QuizzActivityModel,
    TCSActivity as TCSActivityModel,
    VideoActivity as VideoActivityModel,
    FreeTextActivity as FreeTextActivityModel
} from '@Types/Activity';
import React from 'react';
import {FreeTextActivity} from "@Pages/Home/Session/pages/Module/pages/Activity/FreeTextActivity";

export type ActivityProps = Record<string, never>;
export const Activity: React.FC<ActivityProps> = () => {
    return (
        <ScrollContainer scrollTop>
            <ActivityLayout>
                {(activity, index, progress, next) => {
                    switch (activity.type) {
                        case 'course':
                            return (
                                <CourseActivity activity={activity} index={index} next={next} key={activity.activity_id} />
                            );
                        case 'video':
                            return (
                                <VideoActivity
                                    activity={activity as ActivityModel & VideoActivityModel}
                                    next={next}
                                    key={activity.activity_id}
                                    index={index}
                                />
                            );
                        case 'mcq':
                        case 'ucq':
                            return (
                                <QuizzActivity
                                    activity={activity as ActivityModel & QuizzActivityModel}
                                    progress={progress}
                                    next={next}
                                    key={activity.activity_id}
                                    index={index}
                                />
                            );
                        case "freetext":
                            return (
                                <FreeTextActivity
                                    progress={progress}
                                    activity={activity as ActivityModel & FreeTextActivityModel}
                                    next={next}
                                    key={activity.activity_id}
                                    index={index}
                                />
                            );
                        case 'tcs':
                            return (
                                <TCSActivity
                                    activity={activity as ActivityModel & TCSActivityModel}
                                    next={next}
                                    key={activity.activity_id}
                                    index={index}
                                />
                            );
                        default:
                            return <div>{activity.type}</div>;
                    }
                }}
            </ActivityLayout>
        </ScrollContainer>
    );
};
