import React from "react";
import {BreadItem} from "@Components/../pages/Home/components/CustomBreadCrumb/BreadItem";
import {classNames} from "primereact/utils";

export type BreadItemModel = {
	id?: string;
	icon?: string;
	label?: string;
	url?: string;
}

export type CustomBreadCrumbProps = {
	home: BreadItemModel;
	items: BreadItemModel[];
	className?: string;
}

export const CustomBreadCrumb: React.FC<CustomBreadCrumbProps> = (props) => {

	return (
		<div className={classNames("flex justify-content-start align-items-center", props.className)}>
			<BreadItem item={props.home}/>
			{
				props.items.map((item, i) => {
					return <React.Fragment key={item.id || i}>
						<i className="pi pi-angle-right text-white mx-3" style={{fontSize: '13px'}}/>
						<BreadItem item={item} isLast={i === props.items.length - 1} shouldReduce={props.items.length >= 3 && i <= props.items.length - 3}/>
					</React.Fragment>
				})
			}
		</div>
	)
}
