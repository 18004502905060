import { useEffect, useState } from 'react';
import { Image } from 'primereact/image';
import { classNames } from 'primereact/utils';

type Props = {
    urlPromise: Promise<string | null | undefined>;
    placeholder?: string;
    width?: string;
    height?: string;
    className?: string;
}
export const CustomImage: React.FC<Props> = props => {

    const [resolvedUrl, setResolvedUrl] = useState<string | null | undefined>(null);


    useEffect(() => {
        props.urlPromise.then(setResolvedUrl);
    }, [props.urlPromise]);

    return (
        !resolvedUrl ?
            <div className={classNames(
                "flex relative justify-content-center align-items-center bg-gray-200",
                props.className
            )} style={{
                width: props.width+"px",
                height: (props.height || props.width) + "px",
                overflow: "hidden"
            }}>
                { props.placeholder && <img src={props.placeholder} width={props.width} height={props.height} className="absolute z-0"/>}
                <i className="pi pi-spin pi-spinner z-2" />
            </div>
            :
            <Image preview src={resolvedUrl} width={props.width} height={props.height} />
    );
};
