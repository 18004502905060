import React, { useContext } from 'react';
import { Module } from '@Types/Module';
import { ProgressBar } from 'primereact/progressbar';
import { useBreakpoint } from '@Utils/responsive.utils';
import { classNames } from 'primereact/utils';
import { SessionContext } from '@Context/Session.context';
import { DateTime, Duration } from 'luxon';
import { mapDurationUnit } from '@Utils/date.utils';
import {Button} from "primereact/button";

export type ModuleTopBarProps = {
    module: Module;
    progress: number;
    style?: React.CSSProperties;
    remainingTime?: number | boolean;
    fullscreen?: boolean;
    onFullscreen?: () => void;
};
export const ModuleTopBar: React.FC<ModuleTopBarProps> = ({ remainingTime, module, progress, style , fullscreen, onFullscreen}) => {
    const responsive = useBreakpoint();
    const { current_unit } = useContext(SessionContext);
    return (
        <div
            className={classNames(
                responsive.isDesktop || responsive.small
                    ? 'flex justify-content-start align-items-center px-6 gap-4 py-2'
                    : 'flex flex-column px-3 py-4',
                'bg-white'
            )}
            style={{
                gap: 8,
                ...style,
            }}
        >
            <div className={classNames("flex flex-column align-items-start justify-content-center", fullscreen === undefined && "mr-auto")}>
                <div className="he-header--h3 gray-900">{module.title}</div>
            </div>
            {
                fullscreen !== undefined && onFullscreen !== undefined && responsive.isDesktop &&
                <Button onClick={onFullscreen} className={classNames('mr-auto', fullscreen ? "he-button--warning--xs" : "he-button--secondary--xs")}>
                    {fullscreen ?
                        <i className={"pi pi-window-minimize"}/> :
                        <i className={"pi pi-window-maximize"}/> } {
                    fullscreen ? "Réduire" : "Plein écran"
                }
                </Button>
            }
            <div className="flex flex-column">
                <div className={`w-full flex ${responsive.phone || responsive.tablet ? 'flex-column' : ''}`}>
                    <div className="he-paragraph--regular gray-400 mr-4">
                        <i className="pi pi-clock mr-2" />
                        Durée : {module.estimated_duration} {mapDurationUnit(module.estimated_duration_unit)}
                    </div>
                    {typeof remainingTime === 'number' && remainingTime > 0 && (
                        <div className="he-paragraph--regular gray-400 mr-4">
                            Temps restant: {Duration.fromMillis(remainingTime).toFormat('hh:mm:ss')}
                        </div>
                    )}
                    <div
                        className={classNames(
                            responsive.isDesktop || responsive.small ? 'align-items-center' : 'align-items-center mt-2',
                            'flex he-paragraph--regular gray-400'
                        )}
                        style={{ gap: 8 }}
                    >
                        Progression
                        <div className="flex justify-content-center align-items-center ml-auto">
                            <ProgressBar
                                style={{
                                    height: 6,
                                    width: responsive.phone || responsive.tablet ? 'calc(100vw - 200px)' : 180,
                                }}
                                showValue={false}
                                value={progress}
                            />
                            <div className="he-paragraph--small ml-2 gray-400">
                                {new Intl.NumberFormat('fr-FR', {
                                    maximumFractionDigits: 1,
                                    minimumFractionDigits: 1,
                                }).format(progress)}&nbsp;%
                            </div>
                        </div>
                    </div>
                </div>
                {current_unit?.end_date && (
                    <div className="he-paragraph--regular gray-500 mt-2">
                        Partie {current_unit.index} à terminer avant le{' '}
                        {DateTime.fromISO(current_unit.end_date).toFormat('dd/MM/yyyy')}
                    </div>
                )}
            </div>
        </div>
    );
};
