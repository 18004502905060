import { useApi } from "@Hooks/api";
import { useUser } from "@Hooks/firebase";
import { Activity, ActivityHistory, VideoActivity as VideoActivityModel } from "@Types/Activity";
import { useBreakpoint } from "@Utils/responsive.utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";

export type VideoActivityProps = {
	activity: Activity & VideoActivityModel;
	index: number;
	next: any;
};
export const VideoActivity: React.FC<VideoActivityProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(true);
	const {session_id, module_id, activity_id} = useParams<{
		session_id: string;
		module_id: string;
		activity_id: string;
	}>();
	const api = useApi();

	useEffect(() => {
		console.log(activity_id, session_id, module_id)
		if (activity_id && session_id && module_id) {
			api
				.session_call_startActivity({
					activity_id,
					module_id,
					session_id,
				})
				.then((res) => {
					if (res.result !== "ok") throw new Error(res.result);
					props.next.onNext(async () => {
						await api.session_call_validateActivity({
							activity_id,
							module_id,
							session_id,
						});
					});
				})
				.finally(() => {
					props.next.setNextStatus(true);
				});
		}
	}, [session_id, activity_id, module_id]);


	const registerVideoProgress = async (time: number) => {
		console.log("register video progress")
		if (activity_id && session_id && module_id) {
			api.session_call_registerVideoTime({
				activity_id,
				module_id,
				session_id,
				time
			});
		}
	}

	const { progress } = useUser(true);

	const ac = useMemo<ActivityHistory | undefined>(() => {
			if (!(session_id && module_id && activity_id)) return undefined;
			return progress?.find(p => p.session_id === session_id && p.module_id === module_id && p.activity_id === activity_id)?.activity;
		}, [progress, session_id, module_id, activity_id])
	;

	const ref = useRef<ReactPlayer>(null);

	const responsive = useBreakpoint();

	return (
		<div className="w-full h-full">
			<div className="he-header--h1 gray-900">{props.activity.title}</div>

			{loading && (
				<div
					className="flex justify-content-center align-items-center mt-5"
					style={{height: 360}}
				>
					<i
						className="pi pi-spin pi-spinner gray-300"
						style={{fontSize: "3rem"}}
					/>
				</div>
			)}
			{ props.activity.video_link && <ReactPlayer
				ref={ref}
				onProgress={event => registerVideoProgress(event.playedSeconds)}
				progressInterval={30_000}
				url={props.activity.video_link}
				onReady={(a) => {
					if (ac?.video_timestamp)
						a.seekTo(ac?.video_timestamp)
					setLoading(false)
				}}
				width={responsive.isDesktop ? "700px" : "100%"}
				style={{opacity: loading ? 0 : 1}}
				className="mt-5"
				controls
			/> }
		</div>
	);
};
