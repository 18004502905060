import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Line } from '@Components/Line';
import { SessionExtended } from '@Types/Session';
import { ModuleContext } from '@Context/Module.context';
import { Unit, UnitConfig } from '@Types/Unit';
import { useApi } from '@Hooks/api';
import { FirebaseContext } from '@Context/FirebaseContext';
import {SessionContext} from "@Context/Session.context";
import {canByPass} from "@Utils/participant.utils";

export type UnitEndDialogProps = {
    session: SessionExtended<false>; onSign: (unit: Unit) => void;
}
export const UnitEndDialog: React.FC<UnitEndDialogProps> = ({ session, onSign }) => {

    const { unit } = useContext(ModuleContext);
    const navigate = useNavigate();
    const isLastUnit = useMemo(() => {
        if (!session) return false;
        const units = session.formation.units;

        const unitIndex = units.findIndex(u => unit?.unit_id === u.unit_id) ?? -1;

        console.log(unit, units, unitIndex);

        return unitIndex === units.length - 1;

    }, [unit, session]);

    const [nextUnit, index] = useMemo<[UnitConfig | undefined, number]>(() => {
        if (!session) return [undefined, -1];
        const units = session.formation.units;

        const unitIndex = units.findIndex(u => unit?.unit_id === u.unit_id);

        console.log(isLastUnit);
        if (isLastUnit) return [undefined, -1];


        const nextUnit = units[unitIndex + 1];
        return [session.unitsConfig.find(u => u.unit_id === nextUnit.unit_id), unitIndex];

    }, [session, unit, isLastUnit]);


    const [isValid, setIsValid] = useState<boolean>(false);

    const api = useApi();

    const fbCtx = useContext(FirebaseContext);
    const { participant } = useContext(SessionContext);

    const [checking, setChecking] = useState(true);
    async function check(session_id: string, unit: Unit) {
        setChecking(true);

        // BYPASS
        if (participant && canByPass(session, participant)) {
            setIsValid(true);
            setChecking(false);
            return;
        }

        const res = await api.session_call_checkUnit({
            session_id: session.session_id,
            unit_id: unit.unit_id
        });

        if (res.result === "ok") {
            setIsValid(true);
            setChecking(false);
        } else {
            fbCtx?.logEvent("FailedSignatureCheck", {
                session_id,
                unit,
                errors: res.steps
            })
            console.error(res.steps);
            setChecking(false)
        }
    }

    const onRefresh = () => {
        window.location.reload();
    }

    useEffect(() => {
        if (session && unit) {
            check(session.session_id, unit)
        }
    }, []);

    return (<div className='w-full h-full'>

        {checking ? <div className='w-full h-full py-4 flex flex-column gap-4 justify-content-center align-items-center'>
            <div className='he-header--h1 text-center'>
                Vérification en cours
            </div>
            <div className={"w-min"}>
                <i className='pi pi-spin pi-spinner' style={{ fontSize: '4rem' }} />
            </div>
            <div className='he-paragraph--regular'>
                Veuillez patienter...
            </div>
        </div> : isValid ? <>
            <div className='he-header--h1 text-center'>
                Bravo ! 🏆
            </div>
            <div className='he-paragraph--text text-center mt-4'>
                Vous venez de terminer la
                partie {session.formation.units.findIndex(u => u.unit_id === unit?.unit_id) + 1}
            </div>
            <div className='he-paragraph--text text-center mt-3'>
                Vous devez à présent signer l'attestation sur l'honneur.
            </div>
            <div className='he-paragraph--text text-center mt-1'>
                {!isLastUnit && nextUnit && (nextUnit.start_date ? `La partie ${index + 2} commencera le ${DateTime.fromISO(nextUnit.start_date).toFormat('dd/MM/yyyy')}` : `La partie ${index + 2} sera disponible après signature de l'attestation sur l'honneur`)}
            </div>
            <Button
                className='he-button--primary--lg mt-4 w-full'
                onClick={() => unit && onSign(unit)}
            >
				<span className='material-symbols-outlined'>
					draw
				</span>
                <div>
                    Signer l'attestation sur l'honneur
                </div>
            </Button>
            <Line height={1} className='bg-gray-200 my-4' />
            <div className='flex justify-content-end'>
                <Button label="Retour à l'accueil" className='he-button--secondary-variant-nfb--md'
                        onClick={() => navigate(`/session/${session.session_id}`)} />
            </div>
        </> : <div className={"w-full h-full gap-4 flex flex-column justify-content-center align-items-center"}>
            <div className='he-header--h2'>
                Une erreur a été rencontrée
            </div>
            <div className='he-paragraph--regular-16 text-center'>
                Un problème est survenu lors de la vérification de votre session, <br /> veuillez rafraîchir la page.
            </div>
            <div className='flex gap-4'>
                <Button className="he-button--primary--md" onClick={onRefresh}>
                    Rafraîchir la page <i className={"pi pi-refresh"}/>
                </Button>
            </div>
        </div>}


    </div>);
};
