import React, {useCallback, useEffect, useRef, useState} from 'react';

import {CustomDialog} from "@Components/CustomDialog";
import {v4} from "uuid";
import {Button} from "primereact/button";
import {useNavigate} from 'react-router-dom';
import {collection, deleteDoc, doc, onSnapshot, setDoc, writeBatch} from "firebase/firestore";
import {firestore} from "@Utils/config/firebase";
import {useUser} from "@Hooks/firebase";
import _ from "lodash";

export interface BrowserMeta {
    id: string;
    "user-agent": string;
    since: number;
}

const Popup: React.FC<React.PropsWithChildren> = (props) => {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [tabId] = useState<string>(v4());
    const navigate = useNavigate()
    const [tabs, initTabs] = useState<BrowserMeta[]>([])
    const [mounted, setMounted] = useState<boolean>(false);

    const addTab = (newTab: BrowserMeta) => {
        return setDoc(doc(firestore.db, `users/${meta.user_id}/browsers`, tabId), newTab);
    }

    const removeTab = (tabId: string) => {
        return deleteDoc(doc(firestore.db, `users/${meta.user_id}/browsers`, tabId));
    }

    const handleUnload = useCallback(() => {
        if (tabs && tabId) {
            removeTab(tabId);
        }
    }, [tabs, tabId]);

    const {meta} = useUser(true);
    const [loadingSessions, setLoadingSessions] = useState<boolean>(false);

    useEffect(() => {
        setLoadingSessions(true);
        return onSnapshot(collection(firestore.db, `users/${meta.user_id}/browsers`), snap => {
            initTabs(snap.docs.map(d => d.data() as BrowserMeta));
            setLoadingSessions(false);
        }, error => {
            console.error(error);
            setLoadingSessions(false);
        })
    }, [meta.user_id]);

    useEffect(() => {

        const _tabs = tabs || []

        if (_tabs !== undefined) {
            if (!_tabs.find(t => t.id === tabId)) {
                addTab({
                    id: tabId,
                    since: Date.now(),
                    "user-agent": navigator.userAgent
                }).finally(() => {
                    setMounted(true)
                });
            }
        }
        window.addEventListener("beforeunload", handleUnload);
        return () => {
            window.removeEventListener("beforeunload", handleUnload);
        };
    }, [tabs, tabId, handleUnload]);

    useEffect(() => {
        if (tabs && tabId && mounted) {
            if (!tabs.find(t => t.id === tabId)) {
                handleUnload();
                navigate("/quit");
            }
        }

    }, [tabs, mounted, tabId])

    useEffect(() => {
        if (tabs && tabs.length > 1) {
            setShowPopup(true)
        } else if (tabs && tabs.length === 1) {
            setShowPopup(false)
        }
    }, [tabs]);

    const unmounting = useRef(false);

    useEffect(() => {
        return () => {
            unmounting.current = true;
        }
    }, []);

    useEffect(() => {
        return () => {
            if (tabs && unmounting.current && tabs.find(t => t.id === tabId)) {
                handleUnload();
            }
        }
    }, [tabs, handleUnload]);

    const onQuit = () => {
        handleUnload();
        navigate("/quit");
    }

    const onStay = async () => {

        const deletetabs = tabs.filter(t => t.id !== tabId)

        const chunked = _.chunk(deletetabs, 500);
        for (const chunk of chunked) {
            const batch = writeBatch(firestore.db);
            for (const tab of chunk) {
                batch.delete(doc(firestore.db, "users", meta.user_id, "browsers", tab.id));
            }
            await batch.commit();
        }
    }

    return (
        <>
            {loadingSessions ? <div className={"w-full h-full justify-content-center align-items-center"}><i
                className={"pi pi-spin pi-spinner"} style={{fontSize: "3rem"}}/></div> : props.children}
            {showPopup && <CustomDialog
                visible
                onHide={() => setShowPopup(false)}
            >
                <div className={"flex justify-content-between flex-column gap-2"}>
                    <div className="he-header--h3 text-center">
                        Attention
                    </div>
                    <span
                        className="my-3">Vous avez déjà {tabs?.length - 1} autre{tabs?.length > 2 ? "s" : ""} page{tabs?.length > 2 ? "s" : ""} ouverte{tabs?.length > 2 ? "s" : ""}</span>
                    <Button label={"Rester sur cette page"} className={"he-button--primary--xs w-full"}
                            onClick={onStay}/>
                    <Button label={"Quitter"} className={"he-button--secondary-variant--xs w-full"} onClick={onQuit}/>
                </div>
            </CustomDialog>}
        </>
    );
};

export default Popup;
