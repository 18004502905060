import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import styled from "styled-components";
import {useUser} from "@Hooks/firebase";
import {Home} from "@Pages/Home";
import {LoginForm} from "@Pages/login/LoginForm";
import {ForgottenPassword} from "@Pages/login/ForgottenPassword";
import {ResetPassword} from "@Pages/login/ResetPassword";
import {RegisterForm} from "@Pages/login/RegisterForm";
import {SignatureData} from "@Pages/SignatureData";
import {Version} from "@Components/Version";
import {Settings} from "luxon";
import {ClosePage} from "@Pages/ClosePage";

const MobileApp = styled.div`
  width: 100%;
  height: 100%;
`;

Settings.defaultZone = "Europe/Paris";
export const App : React.FC = () => {

    const user = useUser();

    return (
		<Version>
			<MobileApp className="">
				{
					!user?.isConnected ?
						<Routes>
							<Route path="/login" element={<LoginForm />}/>
							<Route path="/forgotten-password" element={<ForgottenPassword />} />
							<Route path="/reset/:code" element={<ResetPassword />} />
							<Route path="/register" element={<RegisterForm />} />
							<Route path="/*" element={<Navigate to={"/login"} />} />
						</Routes>
						:
						<Routes>
							<Route path="/home/*" element={<Home />}/>
							<Route path="/session/*" element={<Home />}/>
							<Route path="/signature/:user_id/:signature_id" element={<SignatureData />}/>
							<Route path="/quit" element={<ClosePage />}/>
							<Route path="/*" element={<Navigate to={"/home"} />} />
						</Routes>
				}
			</MobileApp>
		</Version>
    )
}
