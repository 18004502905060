import {onValue, connectDatabaseEmulator, getDatabase, set, child, get, ref, type Database, type DataSnapshot, push} from "firebase/database";
import {FirebaseApp} from "@firebase/app";
export class RealtimeDatabase {
	private readonly db : Database;

	private cache: Map<string, DataSnapshot> = new Map();

	constructor(
		private app: FirebaseApp,
		emulatorConfig ?: {
			host: string,
			port: number
		}
	) {
		this.db = getDatabase(this.app, !emulatorConfig ? "https://health-events-dev-default-rtdb.europe-west1.firebasedatabase.app" : undefined);

		if (emulatorConfig) {
			connectDatabaseEmulator(this.db, emulatorConfig.host, emulatorConfig.port);
		}
	}

	set<T>(path: string, data: T) {
		const r = ref(this.db, path);
		return set(r, data);
	}

	onValue(path: string, cb: (snapshot: DataSnapshot) => any) {
		const r = ref(this.db, path);
		onValue(r, snapshot => {
			this.cache.set(path, snapshot);
			cb(snapshot)
		})
	}

	push<T>(path: string, data: T) {
		const r = ref(this.db, path);
		return push(r, data);
	}

	get(path: string) {
		if (this.cache.has(path)) return this.cache.get(path);
		const r = ref(this.db);
		return get(child(r, path));
	}

}

