import React from "react";


export type ClosePageProps = Record<string, never>
export const ClosePage: React.FC<ClosePageProps> = () => {

    return (
        <div className="w-full h-full gray-200 flex justify-content-center align-items-center">
            <div className={"he-header--h1 text-center gray-500"}>
                <p className={"mb-3"}>
                    Pour continuer votre formation, veuillez fermer cette page et
                </p>
                <p>vous rendre sur l'onglet Health Events déjà ouvert</p>
            </div>
        </div>
    )
}
