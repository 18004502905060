import React, {useEffect, useMemo} from "react";
import './PasswordCheck.scss';
export type PasswordCheckProps = {
	password: string;
	onChange?: (isValid: boolean) => void;
}
export const PasswordCheck: React.FC<PasswordCheckProps> = (props) => {

	const valid = useMemo(() => {
		return {
			min: /^.{8}/.test(props.password),
			low: /[a-z]/.test(props.password),
			upp: /[A-Z]/.test(props.password),
			num: /\d/.test(props.password),
			spe: /[!@#$%^&*]/.test(props.password),
			space: !/\s/.test(props.password),
			accent: !/[À-ÖØ-öø-ÿ]/.test(props.password),
			unauthorized: !/[^A-Za-z\d!@#$%^&*]/.test(props.password)
		}
	}, [props.password]);

	useEffect(() => {
		props.onChange?.(Object.values(valid).every(s => s));
	}, [valid])

    return (
        <div className="PasswordCheck he-paragraph--regular mb-3 gray-600">
	        <p className="mb-1"><i className="pi pi-check-circle"/> Votre mot de passe <strong>doit</strong> comporter :</p>
	        <p className={valid.min ? 'valid' : 'invalid'}>&bull; Au moins 8 caractères </p>
	        <p className={(valid.upp && valid.low) ? 'valid' : 'invalid'}> &bull; Au moins une majuscule et une minuscule</p>
	        <p className={valid.num ? 'valid' : 'invalid'}> &bull; Au moins un chiffre </p>
	        <p className={valid.spe ? 'valid' : 'invalid'}> &bull; Au moins un caractère spécial parmi : ! @ # $ % ^ & *</p>
	        <p className="mt-2 mb-1"><i className="pi pi-times-circle"/> Votre mot de passe <strong>ne doit pas</strong> comporter :</p>
	        <p className={valid.space ? 'valid' : 'invalid'}>&bull; D'espace ou d'alinéa/tabulation </p>
	        <p className={valid.accent ? 'valid' : 'invalid'}>&bull; D'accents ex: é è à ... </p>
	        <p className={valid.unauthorized ? 'valid' : 'invalid'}>&bull; De caractères spéciaux non autorisés ex: _ § µ ...</p>
        </div>
    )
}
