import React from "react";
import styled from "styled-components";

const ScaleContainer = styled.div`
	display: grid;
  	grid-template-columns: repeat(5, minmax(0, 1fr));
  	grid-auto-rows: auto;
  	grid-gap: 4px;
`

const ScaleBar = styled.div`
	height: 6px;
  	border-radius: 16px;
  	background: ${props => props.color};
`
const ScaleText = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 130%;
  color: #667085;
`
const ScaleCursor = styled.div<{score: number}>`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 64px;
  background: #FFFFFF;
  box-shadow: 0 0 8px #98A2B3;
  transform: translateX(-50%) translateY(-50%);
  top: 2px;
  left: ${props => props.score}%;
`
export type TcsScaleProps = {
	score: number,
	colors: string[]
}

export const TcsScale: React.FC<TcsScaleProps> = props => {

    return (
        <ScaleContainer className="w-full relative">
	        <ScaleCursor score={props.score} className=" flex justify-content-center align-items-center gray-900">
		        <i className="pi pi-user" style={{fontSize: "10px"}}/>
	        </ScaleCursor>
	        {
				props.colors.map((color, i) => {
					return <div key={i}>
						<ScaleBar color={color} />
						<ScaleText className="roboto mt-2">
							{i * 20} points
						</ScaleText>
					</div>
				})
	        }
        </ScaleContainer>
    )
}
