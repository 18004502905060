import styled from 'styled-components';
import logo from '@Assets/images/logo.svg';
import { Avatar } from 'primereact/avatar';
import { useUser } from '@Hooks/firebase';
import { CustomBreadCrumb } from '@Components/CustomBreadCrumb';
import { CustomDropdown } from '@Pages/Home/layouts/components/CustomDropdown';
import { useContext, useMemo, useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { auth } from '@Utils/config/firebase';
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from '@Context/Navigation.context';
import { SessionTrackerContext } from '@Context/SessionTracker.context';
import { Duration } from 'luxon';
import { useBread } from '@Hooks/breadcrumbs';
import { ResponsiveProps, useBreakpoint } from '@Utils/responsive.utils';
import { Sidebar } from 'primereact/sidebar';
import { Line } from '@Components/Line';
import { version } from '../../../config/version';

export type HeaderProps = Record<string, never>;

const HeaderWrapper = styled.div<ResponsiveProps>`
    height: ${(props) => (props.responsive.phone || props.responsive.isTablet ? '100px' : '150px')};
`;

const HeaderTop = styled.div<ResponsiveProps>`
    height: ${(props) => (props.responsive.phone || props.responsive.isTablet ? '68px' : '100px')};
    padding: 0 ${(props) => (props.responsive.phone || props.responsive.isTablet ? '20px' : '50px')};
`;

const HeaderBreadcrumbs = styled.div<ResponsiveProps>`
    height: ${(props) => (props.responsive.phone || props.responsive.isTablet ? '32px' : '50px')};
`;

const Logo = styled.img<ResponsiveProps>`
    width: ${(props) => (props.responsive.phone || props.responsive.isTablet ? '146px' : '190px')};
    height: auto;
`;

export const Header: React.FC<HeaderProps> = () => {
    const { meta } = useUser(true);

    const ddRef = useRef<OverlayPanel>(null);

    const { time, modulesProgress, params } = useContext(SessionTrackerContext);
    const navigate = useNavigate();

    const home = {
        label: 'Accueil',
        url: '/home',
    };

    const formattedTime = useMemo(() => {
        return time === null ? '--:--:--' : Duration.fromMillis(time).toFormat('hh:mm:ss');
    }, [time]);

    const { navigation_tree } = useContext(NavigationContext);

    const bread = useBread();
    const responsive = useBreakpoint();

    const logout = () => {
        bread.updateTree((_) => []);
        auth.signOut();
    };

    const menuItems = [
        {
            icon: 'pi pi-user',
            label: 'Mon profil',
            action: () => {
                bread.updateTree((_) => [
                    {
                        id: 'profile',
                        label: 'Profil',
                        url: '/home/profile',
                    },
                ]);
                navigate('/home/profile');
            },
        },
        {
            icon: 'pi pi-sign-out',
            label: 'Se déconnecter',
            action: logout,
        },
    ];

    const [visible, setVisible] = useState<boolean>(false);

    const iconTemplate = () => (
        <div className="p-2" onClick={() => setVisible(false)}>
            <i className="pi pi-times color-white" />
        </div>
    );

    const onMailto = () => {
        window.location.href = 'mailto:contact@healthevents.fr';
    };

    const onTelto = () => {
        window.location.href = 'tel:0141478112';
    };

    const onNavigate = (url: string) => {
        navigate(url);
        setVisible(false);
    };

    const SideBarContent = () => (
        <div className="flex flex-column h-full justify-content-start">
            <div className="he-header--h1-small color-white my-4" onClick={() => onNavigate('/home/running')}>
                Mes formations en cours
            </div>
            <div className="he-header--h1-small color-white my-4" onClick={() => onNavigate('/home/finished')}>
                Mes formations terminées
            </div>
            <div className="he-header--h1-small color-white my-4" onClick={() => onNavigate('/home/profile')}>
                Mon profil
            </div>
            <Line height={1} className="my-4" style={{ background: '#4C6880' }} />
            <div className="he-paragraph--regular primary-25 mt-2 py-3" onClick={onMailto}>
                <i className={'pi pi-envelope mr-2'} />
                Contacter l'animateur
            </div>
            <div className="he-paragraph--regular primary-25 my-2 py-3" onClick={onTelto}>
                <i className={'pi pi-phone mr-2'} />
                01 76 43 12 49
            </div>
            <div className="he-paragraph--regular primary-25 mt-2 py-3" onClick={logout}>
                <i className={'pi pi-sign-out mr-2'} />
                Se déconnecter
            </div>
            <div className="he-paragraph--regular mt-auto w-full text-center text-primary-100">{version}</div>
        </div>
    );

    return (
        <HeaderWrapper responsive={responsive} className={'w-full flex flex-column'}>
            <Sidebar
                onHide={() => setVisible(false)}
                visible={visible}
                position="right"
                className="w-full bg-primary-900"
                icons={iconTemplate}
                showCloseIcon={false}
            >
                <SideBarContent />
            </Sidebar>
            <CustomDropdown ref={ddRef} items={menuItems} />
            <HeaderTop
                responsive={responsive}
                className={`w-full bg-primary-900 flex justify-content-between align-items-center`}
            >
                <div className="w-auto h-100 flex align-items-center">
                    <Logo
                        responsive={responsive}
                        src={logo}
                        className="cursor-pointer"
                        alt="Health events"
                        onClick={() => navigate('/home')}
                    />
                    {responsive.isDesktop && (
                        <div className="flex align-items-center ml-3">
                            <CustomButton
                                className="block he-paragraph--small secondary-100 no-underline w-max"
                                target="_blank"
                                href="mailto:contact@healthevents.fr"
                            >
                                <i className="pi pi-envelope mr-2" />
                                Contacter l'animateur
                            </CustomButton>

                            <CustomButton
                                className="block he-paragraph--small secondary-100 no-underline w-max ml-3"
                                target="_blank"
                                href="tel:0176431249"
                            >
                                <i className="pi pi-phone mr-2" />
                                01 76 43 12 49
                            </CustomButton>
                        </div>
                    )}
                </div>

                {responsive.isDesktop && (
                    <div
                        className="flex justify-content-center align-items-center cursor-pointer"
                        onClick={(e) => ddRef.current?.toggle(e)}
                    >
                        <Avatar
                            className="bg-color-yellow color-yellowDark he-header--h2"
                            label={`${meta.firstname[0].toUpperCase() + meta.firstname[1].toUpperCase()}`}
                            size={'large'}
                            shape={'circle'}
                        />
                        <div className="flex flex-column mx-3">
                            <span className="he-header--h3 text-white">
                                {meta.firstname} {meta.lastname}
                            </span>
                            <span className="he-paragraph--regular gray-200">{meta.email}</span>
                        </div>
                        <i className="pi pi-angle-down text-white" />
                    </div>
                )}
                {!responsive.isDesktop && (
                    <div className="p-2" onClick={() => setVisible(true)}>
                        <i className={'pi pi-bars color-white'} />
                    </div>
                )}
            </HeaderTop>
            <HeaderBreadcrumbs
                responsive={responsive}
                className={`w-full flex ${
                    !(responsive.phone || responsive.tablet)
                        ? 'bg-primary-600 justify-content-start'
                        : 'bg-primary-75 justify-content-center'
                }`}
            >
                {responsive.isDesktop && (
                    <CustomBreadCrumb home={home} items={navigation_tree} className={'h-full pl-4 py-2'} />
                )}
                {navigation_tree.length > 0 &&
                    !['profile', 'forum'].some(s => navigation_tree.some(nt => nt.id === s)) &&
                    (params?.module_id
                        ? modulesProgress[params.module_id] === false || modulesProgress[params.module_id] === undefined
                        : true) && (
                        <div
                            className={`${
                                responsive.phone || responsive.tablet ? '' : 'ml-auto pr-4 py-2'
                            } he-paragraph--regular color-white h-full flex align-items-center`}
                        >
                            Minuteur de la formation : {formattedTime}
                        </div>
                    )}
            </HeaderBreadcrumbs>
        </HeaderWrapper>
    );
};

const CustomButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    background: #ebf4fc;
    border: 1px solid #cee3f8;
    border-radius: 8px;
`;
