import qa from '@Assets/images/questionnaire-satisfaction.jpg';
import {CustomDialog} from '@Components/CustomDialog';
import {SessionContext} from '@Context/Session.context';
import {SessionTrackerContext} from '@Context/SessionTracker.context';
import {useBread} from '@Hooks/breadcrumbs';
import {useUser} from '@Hooks/firebase';
import {CustomCard} from '@Pages/Home/Session/components/CustomCard';
import {ModuleCard} from '@Pages/Home/Session/components/ModuleCard';
import {SignModule} from '@Pages/Home/components/SignModule';
import {Module} from '@Types/Module';
import {SessionExtended, SessionParticipant} from '@Types/Session';
import {Unit} from '@Types/Unit';
import {getRemainingModuleTime} from '@Utils/progress.utils';
import {ResponsiveProps, useBreakpoint} from '@Utils/responsive.utils';
import _ from 'lodash';
import {DateTime, Duration} from 'luxon';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useLocation} from 'react-use';
import styled from 'styled-components';
import {CheckUnit} from "@Pages/Home/Session/components/CheckUnit";
import {canByPass} from "@Utils/participant.utils";

const ModulesContainer = styled.div<{ blurred?: boolean } & ResponsiveProps>`
    display: grid;
    grid-auto-rows: minmax(
        ${(props) => (props.responsive.isDesktop || props.responsive.small ? '180px' : '120px')},
        min-content
    );
    grid-template-columns: ${(props) => (props.responsive.isDesktop || props.responsive.small ? '1fr 1fr' : '1fr')};
    grid-template-rows: ${(props) => (props.responsive.isDesktop || props.responsive.small ? '180px' : '120px')};
    grid-gap: 14px;
    ${(props) => (props.blurred ? 'filter: blur(0.7rem);' : '')}
    ${(props) => (props.blurred ? 'opacity: 0.6;' : '')}
`;

const MessageContainer = styled.div`
    padding: 4px 8px;
    background: rgba(221, 226, 234, 0.71);
    border-radius: 8px;
`;

const SignedContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    color: #27ae60;
    background: #f3fcf7;
    border: 1px solid #27ae60;
    border-radius: 8px;
    margin-bottom: 16px;
`;

const ToSignContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    color: #e74c3c;
    background: #fdeeed;
    border: 1px solid #e74c3c;
    border-radius: 8px;
    margin-bottom: 16px;
`;

const InvalidUnitContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  color: #e77e3c;
  background: #fdf4ed;
  border: 1px solid #e77e3c;
  border-radius: 8px;
  margin-bottom: 16px;
`

const RemainingTimeContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    color: #f3b84c;
    background: #fffaf5;
    border: 1px solid #f3b84c;
    border-radius: 8px;
    margin-bottom: 16px;
`;

export type ModulesProps = {
    modules: Module[];
    showSatisfaction: boolean;
    unitIndex: number;
    unit: Unit;
};
export const Modules: React.FC<ModulesProps> = (props) => {
    const bread = useBread();
    const params = useParams();
    const { session, refresh, participant } = useContext(SessionContext);
    const { meta } = useUser(true);

    const navigate = useNavigate();
    const responsive = useBreakpoint();

    const { search } = useLocation();

    const query = useMemo(() => new URLSearchParams(search), [search]);
    const [signModuleVisible, setSignModuleVisible] = useState<boolean>(false);

    const config = useMemo(
        () => session?.unitsConfig.find((u) => u.unit_id === props.unit.unit_id),
        [session, props.unit]
    );

    const { report, timeSinceDownload } = useContext(SessionTrackerContext);

    const [finishedPrevious, signedPrevious] = useMemo(() => {
        if (!session || !config) return [false, false];

        const participants = session.participants as SessionParticipant<true>[];

        const me = participants.find((u) => u.participant_id === meta.user_id)!;

        const hasFinishedPreviousUnits = session.formation.units.every((u, i) =>
            i < props.unitIndex ? me.unitsMeta?.find((um) => um.unit_id === u.unit_id)?.access : true
        );

        let hasSignedAllBefore = true;

        if (config.required_signature) {
            hasSignedAllBefore = session.formation.units.every((u, i) => {
                if (i >= props.unitIndex) return true;

                const sessionConfig = session.unitsConfig.find((us) => us.unit_id === u.unit_id);

                if (sessionConfig && !sessionConfig.required_signature) return true;

                return me.unitsMeta?.find((um) => um.unit_id === u.unit_id)?.signature_id;
            });
        }

        return [hasFinishedPreviousUnits, hasSignedAllBefore];
    }, [session, props.unit, config]);

    const isDatePassed = useMemo<boolean>(() => {
        return (
            (config?.start_date &&
                DateTime.fromISO(config.start_date)
                    .set({
                        hour: 0,
                        minute: 1,
                    })
                    .diffNow()
                    .as('seconds') > 0) ||
            false
        );
    }, [config]);

    function onUnitSigned(unit: any, session: SessionExtended<false>): void {
        refresh();
        setSignModuleVisible(false);
    }

    const participantMeta = useMemo(() => {
        return participant?.unitsMeta?.find((u) => u.unit_id === props.unit.unit_id);
    }, [participant, props.unit]);
    const moduleTimeProgress = useMemo(() => {
        return Object.fromEntries<boolean | null | number>(props.modules.map((module) => {
            if (report === undefined ||
                timeSinceDownload === null)
                return [module.module_id, false];

            if (
                module.minimal_duration == null ||
                module.minimal_duration_unit == null
            )
                return [module.module_id, true];

            return [module.module_id, getRemainingModuleTime(module.module_id, module, report, timeSinceDownload)];
        }));
    }, [props.modules, timeSinceDownload, report])

    const moduleValid = useMemo(() => {
        return _.mapValues(moduleTimeProgress, (value) => {
            if (value === false || value === null) return false;
            return value === true || value <= 0;
        });
    }, [moduleTimeProgress]);


    const isModuleValid = (module: Module) => {
        const remainingTime = moduleTimeProgress[module.module_id];
        if (remainingTime === false || remainingTime === null) return false;
        return remainingTime === true || remainingTime <= 0;
    }


    const totalRemainingTime = props.modules.reduce<number | null>((a, m) => {
        const remainingTime = moduleTimeProgress[m.module_id];
        if (remainingTime === false || remainingTime === null) return a;
        if (typeof remainingTime === 'number') return (a ?? 0) + remainingTime;
        return a;
    }, null);

    console.log(totalRemainingTime);

    const isUnitValid = props.modules.every(isModuleValid);
    useEffect(() => {
        if (
            participantMeta &&
            finishedPrevious &&
            signedPrevious &&
            config?.required_signature &&
            participantMeta.access &&
            query.has('sign') &&
            !participantMeta.signature_id &&
            isUnitValid
        ) {
            setSignModuleVisible(true);
        }
    }, [query, participantMeta, finishedPrevious, signedPrevious, config, isUnitValid]);

    const onContinue = () => {

        const unit = props.unit;
        const lastModuleId = _.last(unit.modules_ids);
        const lastModule = props.modules.find((m) => m.module_id === lastModuleId);
        if (lastModule) {
            const lastActivity =_.last(lastModule.activities);
            if (lastActivity) {
                navigate('/session/' + session?.session_id + '/module/' + lastModuleId + '/activity/' + lastActivity.activity_id);
                bread.updateTree((prev) => [
                    {
                        id: params.session_id,
                        url: `/session/${params.session_id}`,
                        label: session?.formation.title,
                    },
                    {
                        id: 'module',
                        url: `/session/${params.session_id}/module/${lastModuleId}`,
                        label: lastModule.title,
                    },
                ]);
            }
        }
    }

    const bypass = session && participant && canByPass(session, participant);

    return (
        <div className="w-full blur relative">
            {(!finishedPrevious || !signedPrevious || isDatePassed) && (
                <div className="absolute w-full h-full z-1 flex flex-column align-items-center justify-content-center">
                    {config?.start_date &&
                        DateTime.fromISO(config.start_date)
                            .set({
                                hour: 0,
                                minute: 1,
                            })
                            .diffNow()
                            .as('seconds') > 0 && (
                            <MessageContainer className="he-paragraph--regular primary-900">
                                <i className="pi pi-info-circle mr-2" />
                                Partie {props.unitIndex + 1} disponible le{' '}
                                {DateTime.fromISO(config.start_date).toFormat('DDD')}
                            </MessageContainer>
                        )}
                    {!finishedPrevious && (
                        <MessageContainer className="he-paragraph--regular primary-900 mt-2">
                            <i className="pi pi-info-circle mr-2" />
                            Vous devez terminer la partie précédente pour accéder à cette partie.
                        </MessageContainer>
                    )}
                    {!signedPrevious && (
                        <MessageContainer className="he-paragraph--regular primary-900 mt-2">
                            <i className="pi pi-info-circle mr-2" />
                            Vous devez signer l'attestation sur l'honneur de la partie précédente.
                        </MessageContainer>
                    )}
                </div>
            )}
            {finishedPrevious &&
                signedPrevious &&
                config?.required_signature &&
                participantMeta &&
                participantMeta.access && (
                    <div>
                        {participantMeta.signature_id ? (
                            <SignedContainer className="he-paragraph--regular">
                                <span className="material-symbols-outlined mr-2">check_circle</span>
                                Attestation sur l'honneur signée.
                            </SignedContainer>
                        ) : (
                            report == null || timeSinceDownload == null ? (
                                <i className={'pi pi-spin pi-spinner'} />
                            ) : (isUnitValid || bypass) &&
                            participantMeta.access ? (
                                <ToSignContainer className="he-paragraph--regular">
                                    <span className="material-symbols-outlined mr-2">draw</span>
                                    Attestation sur l'honneur non signée.
                                    <div
                                        className="ml-2 cursor-pointer underline"
                                        onClick={() => setSignModuleVisible(true)}
                                    >
                                        Signer maintenant
                                    </div>
                                </ToSignContainer>
                            ) : (
                                totalRemainingTime !== null && totalRemainingTime > 0 ?
                                    <RemainingTimeContainer className="he-paragraph--regular">
                                        <span className="material-symbols-outlined mr-2">timer</span>
                                        Vous pourrez signer l’attestation sur l’honneur et finaliser cette partie une fois
                                        le temps minimum de formation réalisé. Temps restant :{' '}
                                        {Duration.fromMillis(totalRemainingTime).toFormat('hh:mm:ss')}
                                        <span className="cursor-pointer color-blue underline ml-2" onClick={onContinue}>M'y&nbsp;rendre</span>
                                    </RemainingTimeContainer>
                                    :
                                    <InvalidUnitContainer>
                                        <span className="material-symbols-outlined mr-2">warning</span>
                                        Un problème a été rencontré lors de la vérification de votre unité
                                    </InvalidUnitContainer>
                            )
                        )}
                    </div>
                )}
            {session && props.unit && (
                <CustomDialog
                    visible={signModuleVisible}
                    onHide={() => setSignModuleVisible(false)}
                    width={responsive.isDesktop || responsive.small ? 950 : 320}
                >
                    <CheckUnit unit_id={props.unit.unit_id} session={session} onFail={() => setSignModuleVisible(false)}>
                        <SignModule
                            session={session}
                            onCancel={() => {
                                setSignModuleVisible(false);
                                // Remove search params from url
                                navigate('.', { replace: true });
                            }}
                            unit={props.unit}
                            onSigned={() => onUnitSigned(props.unit, session)}
                        />
                    </CheckUnit>
                </CustomDialog>
            )}
            <ModulesContainer responsive={responsive} blurred={!(finishedPrevious && signedPrevious) || isDatePassed}>
                {props.modules.map((module, i, t) => {
                    return <ModuleCard module={module} key={module.module_id} modules={props.modules} modulesValid={moduleValid}/>;
                })}
                {session && props.showSatisfaction && (
                    <CustomCard
                        title="Questionnaire de satisfaction"
                        image={qa}
                        content="Vous pouvez répondre au questionnaire de satisfaction"
                        onClick={() => {
                            bread.updateTree((prev) => [
                                {
                                    id: params.session_id,
                                    url: `/session/${params.session_id}`,
                                    label: session.formation.title,
                                },
                                {
                                    id: 'satisfaction',
                                    url: `/session/${params.session_id}/satisfaction`,
                                    label: 'Questionnaire de satisfaction',
                                },
                            ]);
                            navigate(`/session/${session.session_id}/satisfaction`);
                        }}
                    />
                )}
            </ModulesContainer>
        </div>
    );
};
