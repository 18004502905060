import { CustomDialog } from '@Components/CustomDialog';
import { Line } from '@Components/Line';
import { SessionContext } from '@Context/Session.context';
import { useUser } from '@Hooks/firebase';
import { Activity, ActivityHistory, FreeTextActivity, QuizzActivity, QuizzAnswer } from '@Types/Activity';
import { Module } from '@Types/Module';
import { Theme } from '@Types/Theme';
import { getTextColor } from '@Utils/color.utils';
import { ResponsiveProps, useBreakpoint } from '@Utils/responsive.utils';
import { sanitizeString } from '@Utils/string.utils';
import { mapValues, sortBy } from 'lodash';
import { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TcsScale } from '../../components/TcsScale';
import { ActivityPreview } from '../components/ActivityPreview';
import { ResultChart } from './components/ResultChart';

const colors = ['#EA2828', '#EAA828', '#dedc15', '#A3CC2E', '#2ECC71'];

type Props = {
    modules: Module[];
};
export default function FreeTextSection(props: Props) {
    const { sessionThemes } = useContext(SessionContext);
    const { progress } = useUser(true);
    const responsive = useBreakpoint();
    const { session_id } = useParams<{
        session_id: string;
    }>();

    const scoreByTheme = useCallback(() => {
        return mapValues(
            props.modules
                .map((module) => {
                    const scores: Record<string, number> = {};
                    const themesForModule = sessionThemes.map((t) => {
                        return {
                            ...t,
                            activity_ids: t.activity_ids.filter((aId) =>
                                module.activities.find((a) => a.activity_id === aId)
                            ),
                        };
                    });
                    for (const theme of themesForModule) {
                        scores[theme.theme_id] =
                            (100 *
                                theme.activity_ids.reduce((acc, aId) => {
                                    const rawActivity = module.activities.find(
                                        (a) => a.activity_id === aId
                                    ) as Activity & (QuizzActivity | FreeTextActivity);
                                    if (!rawActivity) return acc;

                                    if (rawActivity.type === 'mcq' || rawActivity.type === 'ucq') {
                                        const activity = rawActivity as Activity & QuizzActivity;
                                        const totalGoodAnswers = activity.answers.filter((a) => a.is_response).length;

                                        if (!progress) return acc;
                                        const activityProgress = progress.find(
                                            (p) =>
                                                p.session_id === session_id &&
                                                p.module_id === module.module_id &&
                                                p.activity_id === activity.activity_id
                                        );

                                        let score = 0;

                                        if (!activityProgress) return acc;

                                        const userAnswers = activityProgress.activity.answers as QuizzAnswer;

                                        for (const userAnswer of userAnswers) {
                                            if (activity.answers[userAnswer].is_response) score++;
                                        }

                                        return acc + score / totalGoodAnswers;
                                    } else if (rawActivity.type === 'freetext') {
                                        const activity = rawActivity as Activity & FreeTextActivity;

                                        if (!progress) return acc;
                                        const activityProgress = progress.find(
                                            (p) =>
                                                p.session_id === session_id &&
                                                p.module_id === module.module_id &&
                                                p.activity_id === activity.activity_id
                                        );

                                        if (!activityProgress) return acc;

                                        const keywordsFound = activity.keywords.filter((k) => {
                                            return sanitizeString(
                                                activityProgress.activity.answer!.toLowerCase()
                                            ).includes(sanitizeString(k.toLowerCase()));
                                        });
                                        const score = keywordsFound.length / activity.keywords.length;
                                        return score + acc;
                                    } else return acc;
                                }, 0)) /
                            theme.activity_ids.length;
                    }

                    return scores;
                })
                .reduce<{ [k: string]: number[] }>((acc, scores) => {
                    for (const [theme_id, score] of Object.entries(scores)) {
                        acc[theme_id] = [...(acc[theme_id] ?? []), score];
                    }
                    return acc;
                }, {}),
            (themeScores) => themeScores.reduce((acc, score) => acc + score, 0) / themeScores.length
        );
    }, [progress, props.modules, sessionThemes, session_id]);

    const [selection, setSelection] = useState<{
        activity: Activity & (QuizzActivity | FreeTextActivity);
        history: ActivityHistory;
    } | null>(null);

    const getColor = (score: number) => {
        const index = Math.floor(score * 5);
        return colors[index >= colors.length ? colors.length - 1 : index];
    };

    const getThemeSummaryDetails = useCallback(() => {
        return sortBy(
            Object.entries(scoreByTheme())
                .map(([theme_id, score]) => {
                    const theme = sessionThemes.find((t) => t.theme_id === theme_id);
                    if (!theme) return null;
                    return [theme, score];
                })
                .filter(Boolean) as [Theme, number][],
            ([theme]) => theme.label
        ).map(([theme, score]) => {
            const comments = theme.scale.toSorted((a, b) => a.threshold - b.threshold);
            const comment = comments.find((c) => score <= c.threshold);

            return (
                <div key={theme.theme_id} className="flex gap-4 align-items-center">
                    <div className="he-paragraph--regular" style={{ minWidth: 120, maxWidth: 120 }}>
                        <ThemeTag background={theme.color}>{theme.label}</ThemeTag>
                    </div>
                    <TextAreaDisplay>{comment?.text ?? 'Aucun commentaire trouvé'}</TextAreaDisplay>
                </div>
            );
        });
    }, [scoreByTheme, sessionThemes]);

    const generateActivityReport = (module: Module) => {
        if (!progress) return;

        let totalScore = 0,
            total = 0;

        return (
            <>
                {module.activities
                    .filter((a) => a.type === 'ucq' || a.type === 'mcq' || a.type === 'freetext')
                    .map((a) => a as Activity & (QuizzActivity | FreeTextActivity))
                    .map((activity, i, tab) => {
                        let totalGoodAnswers = 1;
                        let score = 0;

                        const activityTheme = sessionThemes.find((t) => t.activity_ids.includes(activity.activity_id!));

                        const activityProgress = progress.find(
                            (p) =>
                                p.session_id === session_id &&
                                p.module_id === module.module_id &&
                                p.activity_id === activity.activity_id
                        );

                        if (activity.type === 'freetext') {
                            const a = activity as Activity & FreeTextActivity;
                            totalGoodAnswers = a.keywords.length;
                            total += totalGoodAnswers;

                            if (!activityProgress) return null;

                            const keywordsFound = a.keywords.filter((k) => {
                                return sanitizeString(activityProgress.activity.answer!.toLowerCase()).includes(
                                    sanitizeString(k.toLowerCase())
                                );
                            });
                            score = keywordsFound.length;
                            totalScore += score;
                        } else {
                            const a = activity as Activity & QuizzActivity;
                            totalGoodAnswers = a.answers.filter((a) => a.is_response).length;
                            total += totalGoodAnswers;
                            if (!activityProgress) return null;

                            const userAnswers = activityProgress.activity.answers as QuizzAnswer;

                            for (const userAnswer of userAnswers) {
                                if (a.answers[userAnswer].is_response) score++;
                            }
                            totalScore += score;
                        }

                        return (
                            <>
                                <ActivityContainer
                                    key={activity.activity_id}
                                    className="activity-container w-full flex gray-700 align-items-center gap-2"
                                >
                                    <i className="pi pi-question-circle" style={{ fontSize: 12 }} />
                                    <div className="he-paragraph--regular">{activity.title}</div>
                                    <div
                                        className="he-paragraph--small primary-100 cursor-pointer"
                                        onClick={() => {
                                            setSelection({
                                                activity,
                                                history: activityProgress.activity,
                                            });
                                        }}
                                    >
                                        Voir le détail
                                    </div>
                                    {activityTheme && (
                                        <ThemeTag size={10} background={activityTheme.color}>
                                            {activityTheme.label}
                                        </ThemeTag>
                                    )}
                                    <div className={'ml-auto'}>
                                        <span
                                            className="he-paragraph--medium"
                                            style={{ color: getColor(score / totalGoodAnswers) }}
                                        >
                                            {score}
                                        </span>{' '}
                                        <span className="he-paragraph--small gray-400">/ {totalGoodAnswers}</span>
                                    </div>
                                </ActivityContainer>
                                {i < tab.length - 1 && <Line height={1} className="bg-gray-200" />}
                            </>
                        );
                    })
                    .filter(Boolean)}
                <Line height={1} className="bg-gray-300" />
                <div className="w-full flex justify-content-end align-items-center p-2">
                    <span className="he-paragraph--xs gray-500 mr-2">TOTAL</span>
                    <span
                        className="he-paragraph--regular"
                        style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                        }}
                    >
                        {totalScore} <span className="he-paragraph--small font-bold">/ {total}</span>
                    </span>
                </div>
                <ScoreContainer className="bg-white mt-3">
                    <div className="flex flex-column align-items-center mb-4">
                        <span className="he-paragraph--small gray-500 mb-1">VOTRE SCORE :</span>
                        <div>
                            <DisplayScore style={{ color: getColor(totalScore / total) }} className="roboto">
                                {((100 * totalScore) / total).toFixed()} points
                            </DisplayScore>
                            <Percent className="roboto"> / 100</Percent>
                        </div>
                    </div>
                    <div className="w-full">
                        <TcsScale score={100 * (totalScore / total)} colors={colors} />
                    </div>
                </ScoreContainer>
            </>
        );
    };

    return (
        <>
            {selection && (
                <CustomDialog
                    visible
                    onHide={() => setSelection(null)}
                    closable
                    width={responsive.isDesktop ? 769 : responsive.isTablet ? 600 : '100%'}
                >
                    <div className="w-full bg-white flex align-items-center justify-content-end mb-4">
                        <i onClick={() => setSelection(null)} className="pi pi-times cursor-pointer" />
                    </div>
                    <ActivityPreview {...selection} />
                </CustomDialog>
            )}
            {props.modules.map((m) => {
                const moduleAnswers = progress?.filter((p) => p.module_id === m.module_id) ?? [];

                return (
                    <Section responsive={responsive} className="mb-4" key={'results-' + m.module_id}>
                        {m && moduleAnswers.length > 0 && (
                            <Section responsive={responsive} className="p-0">
                                <div className="he-header--h3 mb-4 gray-600 text-center">Score pour {m.title}</div>
                                {generateActivityReport(m)}
                            </Section>
                        )}
                    </Section>
                );
            })}
            <Section responsive={responsive}>
                <div className="he-header--h3 gray-600 mb-4 text-center">Répartition par thématique</div>
                <ResultChart themes={sessionThemes} results={[scoreByTheme()]} />
                <div className="flex flex-column gap-4">{getThemeSummaryDetails()}</div>
            </Section>
        </>
    );
}

const Section = styled.div<ResponsiveProps>`
    background: #fff;
    border-radius: 10px;
    padding: 24px;
    max-width: 751px;
`;

const ThemeTag = styled.div<{
    size?: number;
    background?: string;
    color?: string;
}>`
    text-align: center;
    padding: 6px 8px;
    border-radius: 6px;
    background: ${(props) => props.background || '#c8c8c8'};
    color: ${(props) => props.color || getTextColor(props.background || '#c8c8c8')};
    font-size: ${(props) => props.size || 14}px;
    line-height: ${(props) => (props.size ? props.size + 4 : 18)}px;
    font-family: 'roboto';
`;
const TextAreaDisplay = styled.div`
    line-height: 20px;
    font-size: 14px;
    font-family: 'roboto';
    font-weight: 400;
    white-space: pre-line;
`;

const ActivityContainer = styled.div`
    padding: 16px 8px;
`;
const DisplayScore = styled.span`
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
`;

const Percent = styled.span`
    font-weight: 700;
    font-size: 10px;
    line-height: 130%;
    color: #667085;
`;
const ScoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px solid #eaecf0;
    border-radius: 8px;
`;
