import {Question as QuestionModel} from "@Types/Satisfaction";
import {SelectButton} from "primereact/selectbutton";
import {CustomInput} from "@Components/CustomInput";

export type QuestionProps<T> = {
	question: QuestionModel<T>;
	value: T;
	mixedValue?: string;
	onChange: (value: T) => void;
	onMixedChanged?: (value: string) => void;
	placeholder?: string;
	className?: string;
}
export const Question = function <T>(props: QuestionProps<T>) {

	return (
		<div className={props.className}>
			<div className="he-paragraph--regular gray-600">{props.question.question}</div>
			{props.question.type === "choice" && <div className="mt-2">
                <SelectButton options={props.question.options} value={props.value}
                              onChange={e => props.onChange(e.value)}/>
            </div>}
			{props.question.type === "mixed" && <div className="mt-2">
                <SelectButton options={props.question.options} value={props.value}
                              onChange={e => props.onChange(e.value)}/>
				{
					props.value === props.question.mixedValue &&
                    <CustomInput value={props.mixedValue} onChange={e => props.onMixedChanged?.(e)} className="mt-2" placeholder={props.placeholder}/>
				}
            </div>}
			{
				props.question.type === "free" &&
                <CustomInput value={props.mixedValue} className="mt-2" onChange={e => props.onMixedChanged?.(e)} placeholder={props.placeholder}/>
			}
		</div>
	)
}
